import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import IconCircle from "../../../../../atoms/IconCircle";
import { getWorkDetailsById } from "../../peopleService";
import "./styles.scss";

import { setLoading } from "../../../../../../store/slices/loaderSlice";
import InputLayout from "../../../../../atoms/InputLayout";
import ArrowLeft from "../../../../../Icons/ArrowLeft";

const ViewPeopleWorkHistory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [workDetailsDataId, setWorkDetailsDataId] = useState();

  useEffect(() => {
    const getWorkDetailsDataById = async () => {
      try {
        dispatch(setLoading(true));
        const response = await getWorkDetailsById(id);
        dispatch(setLoading(false));
        setWorkDetailsDataId(response);
      } catch (exp) {
        console.log("Error", exp);
      }
    };
    getWorkDetailsDataById();
  }, [id]);

  return (
    <div className="add-view work-view">
      <div className="d-flex gap-3 align-items-center mb-3">
        <IconCircle
          color="#ffffff"
          border="1px solid #C0C4C9"
          size="40px"
          icon={
            <i className="d-flex">
              <ArrowLeft />
            </i>
          }
          handleClick={() => navigate(-1)}
          isClickable
        />
        <div className="h1-j">View Work History</div>
      </div>
      <div classes="p-4 mb-3">
        <div style={{ minHeight: "400px" }}>
          <div className="row g-3">
            <InputLayout>
              <label htmlFor="" className="mb-xs">
                Company Name
              </label>
              <p className="view-muted p">
                {workDetailsDataId?.company_name || "--"}
              </p>
            </InputLayout>
            <InputLayout>
              <label htmlFor="" className="mb-xs">
                Title
              </label>
              <p className="view-muted p">{workDetailsDataId?.title || "--"}</p>
            </InputLayout>
            <InputLayout>
              <label htmlFor="" className="mb-xs">
                Status
              </label>
              <p className="view-muted p">
                {workDetailsDataId?.status ? "Active " : "Inactive" || "--"}
              </p>
            </InputLayout>
          </div>
          <div className="row g-3 mt-1">
            <InputLayout>
              <label htmlFor="" className="mb-xs">
                Email
              </label>
              <p className="view-muted p">{workDetailsDataId?.email || "--"}</p>
            </InputLayout>
            <InputLayout>
              <label htmlFor="" className="mb-xs">
                Start Date
              </label>
              <p className="view-muted p">
                {workDetailsDataId?.start_date
                  ? moment(workDetailsDataId?.start_date).format("YYYY-MM-DD")
                  : "--"}
              </p>
            </InputLayout>
            <InputLayout>
              <label htmlFor="" className="mb-xs">
                End Date
              </label>
              <p className="view-muted p ">
                {workDetailsDataId?.end_date
                  ? moment(workDetailsDataId?.end_date).format("YYYY-MM-DD")
                  : "--"}
              </p>
            </InputLayout>
          </div>
          <div className="row g-3 mt-1">
            <InputLayout>
              <label htmlFor="" className="mb-xs">
                {" "}
                Description
              </label>
              <p className="view-muted p ">
                {workDetailsDataId?.description || "--"}
              </p>
            </InputLayout>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPeopleWorkHistory;
