import { useNavigate } from "react-router-dom";
import Heading from "../../atoms/Heading";
import PencilIcon from "./PencilIcon";
import "./styles.scss";
const EditBlock = ({ name = "Edit" }) => {
  const navigate = useNavigate();
  return (
    <div className="block-edit-container">
      <span className="edit-block-icon">
        <PencilIcon />
      </span>
      <div className="block-edit-content">
        <Heading
          title={`${name} Not Supported On Small Screens`}
          classes="h2-i edit-container-heading"
        ></Heading>
        <p className="edit-container-desciption">
          {`The ${name} Feature Is Not Supported for Small Screens View. Please Use
          Desktop for ${name} Functionality.`}
        </p>
      </div>
      <button
        className="btn btn-outline-dark edit-container-btn"
        onClick={() => navigate(-1)}
      >
        Back
      </button>
    </div>
  );
};
export default EditBlock;
