import { useEffect, useState } from "react";
import "./blog_filter.scss";

const BlogFilters = ({ data, onFilterSelect, alreadySelected }: any) => {
  const [selected, setSelected] = useState("All");

  const handleFilterClick = (name: string) => {
    setSelected(name);
    onFilterSelect(name); // Emit the selected value to the parent component
  };

  useEffect(() => {
    if (alreadySelected) {
      setSelected(alreadySelected);
      onFilterSelect(alreadySelected);
    }
  }, [alreadySelected]);

  return (
    <div className="filter-item-container hor-scroll-none">
      <div
        className={`filter-item ${selected === "All" ? "active" : ""}`}
        onClick={() => handleFilterClick("All")}
      >
        All
      </div>
      {data?.map((item: any) => (
        <div
          key={item.value}
          className={`filter-item ${selected === item.label ? "active" : ""}`}
          onClick={() => handleFilterClick(item.label)}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};

export default BlogFilters;
