const Copy = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
    >
      <path
        d="M9.83268 16.6667C10.9373 16.6654 11.9964 16.2259 12.7775 15.4448C13.5586 14.6637 13.998 13.6047 13.9993 12.5V5.20251C14.0006 4.76448 13.915 4.33055 13.7473 3.92586C13.5797 3.52118 13.3334 3.1538 13.0227 2.84501L11.1544 0.976681C10.8456 0.665993 10.4782 0.419683 10.0735 0.252031C9.66882 0.0843791 9.23488 -0.00128163 8.79685 1.44913e-05H4.83268C3.72802 0.00133771 2.66898 0.440749 1.88787 1.22186C1.10675 2.00298 0.667339 3.06202 0.666016 4.16668V12.5C0.667339 13.6047 1.10675 14.6637 1.88787 15.4448C2.66898 16.2259 3.72802 16.6654 4.83268 16.6667H9.83268ZM2.33268 12.5V4.16668C2.33268 3.50364 2.59607 2.86776 3.06492 2.39891C3.53376 1.93007 4.16964 1.66668 4.83268 1.66668C4.83268 1.66668 8.93185 1.67835 8.99935 1.68668V3.33335C8.99935 3.77538 9.17494 4.1993 9.4875 4.51186C9.80007 4.82442 10.224 5.00001 10.666 5.00001H12.3127C12.321 5.06751 12.3327 12.5 12.3327 12.5C12.3327 13.1631 12.0693 13.7989 11.6005 14.2678C11.1316 14.7366 10.4957 15 9.83268 15H4.83268C4.16964 15 3.53376 14.7366 3.06492 14.2678C2.59607 13.7989 2.33268 13.1631 2.33268 12.5ZM17.3327 6.66668V15.8333C17.3314 16.938 16.8919 17.997 16.1108 18.7782C15.3297 19.5593 14.2707 19.9987 13.166 20H5.66602C5.445 20 5.23304 19.9122 5.07676 19.7559C4.92048 19.5997 4.83268 19.3877 4.83268 19.1667C4.83268 18.9457 4.92048 18.7337 5.07676 18.5774C5.23304 18.4211 5.445 18.3333 5.66602 18.3333H13.166C13.8291 18.3333 14.4649 18.07 14.9338 17.6011C15.4026 17.1323 15.666 16.4964 15.666 15.8333V6.66668C15.666 6.44567 15.7538 6.23371 15.9101 6.07743C16.0664 5.92114 16.2783 5.83335 16.4993 5.83335C16.7204 5.83335 16.9323 5.92114 17.0886 6.07743C17.2449 6.23371 17.3327 6.44567 17.3327 6.66668Z"
        fill="#1A1D1F"
      />
    </svg>
  );
};

export default Copy;
