import { IconButton } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDelete } from "../../../../../../hooks/useDelete";
import { getData } from "../../../../../../services";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import Add from "../../../../../Icons/Add";
import DeleteRed from "../../../../../Icons/DeleteRed";
import EditBlue from "../../../../../Icons/EditBlue";
import Investement from "../../../../../Icons/Investment";
import View from "../../../../../Icons/View";
import CustomButton from "../../../../../atoms/CustomeButton";
import TypeDiv from "../../../../../atoms/TypeDiv";
import CustomDatagrid from "../../../../../molecules/CustomDatagrid";
import DeleteModal from "../../../../../molecules/Modal/DeleteModal";
import NoContentCard from "../../../../../molecules/NoContentCard";
import Pagination from "../../../../../molecules/Pagination";

const Product = ({ type }) => {
  const [cookies] = useCookies(["t", "cid"]);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Initial items per page
  const navigate = useNavigate();
  const [productData, setProductData] = useState();

  const [modalState, setModalState] = useState({
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
  });

  const fetchProductData = async () => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "Product/getCompanyProducts",
      params: { company_id: cookies.cid },
      token: cookies.t,
    });
    dispatch(setLoading(false));
    if (res) {
      setProductData(res);
    }
  };

  useEffect(() => {
    fetchProductData();
  }, []);

  const handleView = (data) => {
    navigate(`/dashboard/product/${data.product_id}`);
  };

  const deleteProduct = useDelete();

  const handleDelete = (data) => {
    // console.log("data", data);
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.product_id,
      name: data.name,
    }));
  };

  const handleDeletion = async () => {
    try {
      await deleteProduct("Product/deleteProduct", "Product", {
        product_id: modalState.modalId,
        user_role: "Customer",
      });
      fetchProductData();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (data) => {
    navigate(`/dashboard/product/edit/${data.product_id}`);
  };

  const productColumns = [
    {
      field: "productDetails",
      headerName: "Product Details",
      flex: 1.1,
      renderCell: (params) => {
        return (
          <div className="d-flex flex-column">
            <span
              style={{ color: "#1A1D1F", fontWeight: 800, fontSize: "14px" }}
            >
              {params.row.name}
            </span>
          </div>
        );
      },
    },
    {
      field: "category_name",
      headerName: "Product Category",
      flex: 1,
    },
    {
      field: "type_name",
      headerName: "Product Type",
      flex: 0.8,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <TypeDiv
            data={params.row.status}
            color={params.row.status ? "#B5E4CA" : "#FFB7B7"}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <>
            <IconButton type="button" onClick={() => handleView(params.row)}>
              <i className="actions-icon-custom">
                <View />
              </i>
            </IconButton>
            <IconButton type="button" onClick={() => handleEdit(params.row)}>
              <i className="actions-icon-custom">
                <EditBlue className="edit-blue-icon" />
              </i>
            </IconButton>

            <IconButton type="button" onClick={() => handleDelete(params.row)}>
              <i className="actions-icon-custom">
                <DeleteRed className="edit-blue-icon" />
              </i>
            </IconButton>
          </>
        );
      },
    },
  ];

  // PAgination MaNAGEMENT
  const totalItems = productData?.total_records;

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  return (
    <>
      {productData?.data?.length > 0 ? (
        <>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="fw-bolder">Products</h4>
            {type !== "view" && (
              <CustomButton
                text="Add Product"
                icon={<Add fill="white" />}
                handleClick={() =>
                  navigate(`/dashboard/product/add/${cookies.cid}`)
                }
                type="btn-primary"
                // disabled={!name.trim()}
              />
            )}
          </div>
          <CustomDatagrid
            rows={productData?.data}
            getRowId={(row) => row.product_id}
            columns={productColumns}
            tHeight="300px"
            checkRequired={false}
          />

          {productData?.data?.length > 10 && (
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          )}
        </>
      ) : type === "view" ? (
        <NoContentCard
          small={true}
          title="No Products Added Yet"
          icon={<Investement />}
          color="white"
        />
      ) : (
        <NoContentCard
          small={true}
          title="No Products Added Yet"
          subtitle="To add products, tap the add Product button."
          icon={<Investement />}
          handleClick={() => {
            navigate(`/dashboard/product/add/${cookies.cid}`);
          }}
          tag="Add Product"
          //   color = 'white'
        />
      )}
      <DeleteModal
        name={modalState.name}
        id={modalState.modalId}
        isActive={modalState.isDeleteModalOpen}
        onClose={() =>
          setModalState((prevState) => ({
            ...prevState,
            isDeleteModalOpen: false,
          }))
        }
        title="Product"
        onClick={handleDeletion}
      />
    </>
  );
};

export default Product;
