import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
  baseURL: `${baseURL}`, // Your base URL
  headers: {
    "Content-Type": "application/json",
    "User-Role": "Customer",
  },
});

export const vimeoAcessToken = "f897882adbd5d5821558f15402d3b9d2";

export default instance;
