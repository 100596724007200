import moment from "moment";
import { useState } from "react";
import useScreenWidth from "../../../../../hooks/useScreenwidth";
import LinkIcon from "../../../../Icons/linkIcon";
import "./component.scss";

const InsightsNews = ({ data }: any) => {
  const insights = data?.insights_news;
  const screenWidth = useScreenWidth();

  const slicingNumber = screenWidth < 700 ? 200 : screenWidth < 480 ? 100 : 300;

  // State to track expanded state for each item
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const toggleExpand = (id: string) => {
    setExpandedItems((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((itemId) => itemId !== id);
      } else {
        return [...prevIds, id];
      }
    });
  };

  const handleClick = (data: any) => {
    window.open(data.url, "_blank");
  };

  return (
    <>
      <h3 className="h3-j mb-m">Insights and News</h3>

      <div className="insights-container">
        {insights?.map((item: any) => (
          <div
            key={item.id}
            className="insight-card"
            // onClick={() => handleClick(item)}
          >
            {item.thumbnail ? (
              <img
                src={item.thumbnail}
                alt=""
                className="thumbnail-image cursor-pointer"
                onClick={() => handleClick(item)}
              />
            ) : (
              <div
                className="thumbnail-card cursor-pointer"
                onClick={() => handleClick(item)}
              >
                <i className="d-flex align-items-center justify-content-center">
                  <LinkIcon className="insight-link-icon-container" />
                </i>
              </div>
            )}
            <div className="insight-col">
              <div
                className="h4-j mb-2 cursor-pointer"
                onClick={() => handleClick(item)}
              >
                {item.name}
              </div>
              <p className="p mb-2">
                {moment.utc(item?.created_date).format("MMM D, YYYY")}
              </p>
              <p className="p-dark light">
                {expandedItems.includes(item.id) ||
                item?.description?.length <= slicingNumber
                  ? item.description
                  : `${item?.description?.slice(0, slicingNumber)}`}
                {!expandedItems.includes(item.id) &&
                  item?.description?.length > slicingNumber &&
                  "..."}
                {item?.description?.length > slicingNumber && (
                  <div
                    className="view-more"
                    onClick={() => toggleExpand(item.id)}
                  >
                    {expandedItems.includes(item.id)
                      ? "Read Less"
                      : "Read More"}
                  </div>
                )}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default InsightsNews;
