import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useDelete } from "../../../../../../hooks/useDelete";
import { getData } from "../../../../../../services";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import Add from "../../../../../Icons/Add";
import DeleteRed from "../../../../../Icons/DeleteRed";
import EditBlue from "../../../../../Icons/EditBlue";
import KeyLinks from "../../../../../Icons/KeyLinks";
import LinkIcon from "../../../../../Icons/linkIcon";
import CustomButton from "../../../../../atoms/CustomeButton";
import IconCircle from "../../../../../atoms/IconCircle";
import DeleteModal from "../../../../../molecules/Modal/DeleteModal";
import Modal from "../../../../../molecules/Modal/Normal";
import NoContentCard from "../../../../../molecules/NoContentCard";
import AddInsightForm from "./AddInsight";
import "./styles.scss";

const Insight = ({ type }) => {
  const [cookies] = useCookies(["t", "cid"]);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Initial items per page
  const [insights, setInsights] = useState();
  const navigate = useNavigate();

  const [modalState, setModalState] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
    formData: null, // Set initial state to null
  });

  const openModal = (title, formData, id) => {
    const modalId = id !== undefined ? id : uuidv4();
    setModalState({
      modalId: modalId,
      modalTitle: title,
      isModalOpen: true,
      formData: formData,
    });
  };

  const closeModal = () => {
    setModalState((prev) => ({ ...prev, isModalOpen: false }));
  };

  const fetchInsightsData = async () => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "Company/getAllCompanyInsight",
      params: { company_id: cookies.cid, page: 1, page_limit: 20 },
      token: cookies.t,
    });
    dispatch(setLoading(false));
    if (res) {
      setInsights(res);
    }
  };

  useEffect(() => {
    fetchInsightsData();
  }, []);

  const handleView = () => {};

  const deleteInsight = useDelete();

  const handleDelete = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.id,
      name: data.name,
    }));
  };

  const handleDeletion = async () => {
    try {
      await deleteInsight("Company/deleteCompanyInsight", "Insight", {
        insight_id: modalState.modalId,
        user_role: "Customer",
      });
      fetchInsightsData();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (data) => {
    openModal("Edit Insight & News", data, data.id);
  };

  return (
    <>
      <div>
        {insights?.data.length > 0 ? (
          <>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4 className="fw-bolder">Insights</h4>
              {type !== "view" && (
                <CustomButton
                  text="Add Insight & News"
                  icon={<Add fill="white" />}
                  handleClick={() => openModal("Add Insight & News")}
                  type="btn-primary"
                />
              )}
            </div>

            {insights?.data.map((insight) => (
              <div className="insight-info-div" key={insight.id}>
                <div className="insight-info">
                  {insight?.thumbnail ? (
                    <div
                      className="insight-thumbnail"
                      style={{ backgroundImage: `url(${insight?.thumbnail})` }}
                    >
                      <img src={insight.thumbnail} alt="" />
                    </div>
                  ) : (
                    <IconCircle
                      color="#F4F4F4"
                      icon={
                        <i className="d-flex">
                          <LinkIcon />
                        </i>
                      }
                      size="48px"
                    />
                  )}

                  <div className="info ">
                    <span className="ms-3">{insight.name}</span>
                    <p className="p medium ms-3 mt-1">{insight.url}</p>
                  </div>
                </div>

                {type !== "view" && (
                  <div>
                    <IconButton
                      type="button"
                      onClick={() => handleEdit(insight)}
                    >
                      <i className="actions-icon-custom">
                        <EditBlue className="edit-blue-icon" />
                      </i>
                    </IconButton>

                    <IconButton
                      type="button"
                      onClick={() => handleDelete(insight)}
                      className="ms-2"
                    >
                      <i className="actions-icon-custom">
                        <DeleteRed className="edit-blue-icon" />
                      </i>
                    </IconButton>
                  </div>
                )}
              </div>
            ))}
          </>
        ) : type === "view" ? (
          <NoContentCard
            small={true}
            title="No Insight Added Yet"
            icon={<KeyLinks />}
          />
        ) : (
          <NoContentCard
            small={true}
            title="No Insight Added Yet"
            icon={<KeyLinks />}
            subtitle="To add Insight, tap the add Insight button."
            handleClick={() => openModal("Add Insight & News")}
            tag="Add Insight & News"
          />
        )}

        <DeleteModal
          name={modalState.name}
          id={modalState.modalId}
          isActive={modalState.isDeleteModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="Insight"
          onClick={handleDeletion}
        />
        {modalState.modalId && (
          <Modal
            modalId={modalState.modalId}
            isActive={modalState.isModalOpen}
            onClose={closeModal}
            title={modalState.modalTitle}
          >
            <AddInsightForm
              onClose={closeModal}
              id={modalState.modalId}
              initialData={modalState.formData}
              onDelete={() => {}}
              onUpdated={() => fetchInsightsData()}
            />
          </Modal>
        )}
      </div>
    </>
  );
};

export default Insight;
