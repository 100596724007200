// import { Country } from "country-state-city";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { toast } from "react-hot-toast";
// import { useDispatch } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { confimationStyles } from "../../../../assets/styles/toast";
import { isUUID } from "../../../../helpers/checkUuid";
import { RoleType, statusOptions } from "../../../../helpers/staticData";
import { getData } from "../../../../services";
import { setLoading } from "../../../../store/slices/loaderSlice";
import {
  addOrDraftPeople,
  createPeople,
  getComapnyData,
  getCountryData,
  getPeopleDetailsById,
  getStateData,
  getcityDataById,
  updatePeople,
} from "./peopleService";

const AddPeopleModal = () => {
  const dispatch = useDispatch();
  const { id, added_id, company_id } = useParams();
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const statusData = statusOptions;
  const RoleTypeData = RoleType;
  const [companyData, setCompanyData] = useState([]);
  const [contryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [fetchedCompanyData, setFetchedCompanyData] = useState([]);
  const [peopleCreated, setPeopleCreated] = useState(false);
  const [peopleDataById, setPeopleDataId] = useState([]);
  const [countries, setCountries] = useState([]);

  const handleSubmit = async (formData) => {
    stateData?.filter((item) => item?.value === formData?.state);
    // formData.mobile = formData.mobile;
    formData.is_draft = false;
    try {
      await postData(formData);
    } catch (exp) {
      console.log("error", exp);
    }
  };

  const postData = async (formData) => {
    try {
      if (id) {
        dispatch(setLoading(true));
        const response = await updatePeople(formData, id, cookies.t);
        if (response) {
          dispatch(setLoading(false));
          setPeopleCreated(true);
          toast.success("People Details Added Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate(navigate(-1));
        }
      } else {
        dispatch(setLoading(true));
        const response = await createPeople(formData);
        if (response) {
          dispatch(setLoading(false));
          setPeopleCreated(true);
          navigate(
            `/dashboard/people/add-people/${company_id}/${response?.people_id}`
          );
          // dispatch(setPeopleData(response));
          toast.success("People Details Added Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
        }
      }
    } catch (exp) {
      console.log("error", exp);
    }
  };

  const handleAdd = async (value, id, token) => {
    const data = {
      people_id: id,
      is_draft: value,
    };

    try {
      dispatch(setLoading(true));

      const res = await addOrDraftPeople(data, token);
      if (res.status) {
        dispatch(setLoading(false));
        toast.success("Person Added Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        if (isUUID(company_id)) {
          navigate(-2);
        } else {
          navigate("/dashboard/people");
        }
        // dispatch(setPeopleData({}));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditDraft = async (status, data, people_id, token) => {
    const DraftData = {
      people_id: people_id,
      is_draft: status,
    };
    try {
      const res = await addOrDraftPeople(DraftData, token);
      if (res) {
        // dispatch(setPeopleData({}));
        dispatch(setLoading(true));
        const response = await updatePeople(data, people_id, token);
        if (response) {
          dispatch(setLoading(false));
          setPeopleCreated(true);
          // dispatch(setPeopleData(response));
          toast.success("People Details Added Successfully", {
            duration: 1000,
            style: confimationStyles,
          });

          navigate("/dashboard/people");
        }
      }
    } catch (exp) {
      console.log("Edit Draft People", exp);
    }
  };

  const getCountryListData = async () => {
    try {
      const result = await getCountryData();
      setCountryData(result);
    } catch (exp) {
      console.log("error", exp);
    }
  };

  const getPeopleDetailsDataById = async () => {
    try {
      dispatch(setLoading(true));
      const result = await getPeopleDetailsById(added_id, cookies.t);
      dispatch(setLoading(false));
      setPeopleDataId(result);
    } catch (exp) {
      console.log("Error", exp);
    }
  };

  const getPeopleDetailsDataByIdEdit = async () => {
    try {
      dispatch(setLoading(true));
      const result = await getPeopleDetailsById(id, cookies.t);
      dispatch(setLoading(false));
      setPeopleDataId(result);
      // dispatch(setPeopleData(result));
    } catch (exp) {
      console.log("Error", exp);
    }
  };

  // useEffect(() => {
  //   const allCountries = Country.getAllCountries();
  //   setCountries(allCountries);
  // }, []);

  const formattedCountries = countries.map((country) => {
    return {
      value: country.phonecode,
      label: country.phonecode,
    };
  });

  const cityDataList = cityData?.map((item) => {
    return {
      value: item?.value,
      label: item?.label,
    };
  });

  const fetchRequireData = async (peopleDataById) => {
    const companyData = await getComapnyData([peopleDataById?.company_type]);
    setCompanyData(companyData);
    const stateData = await getStateData(peopleDataById?.country);
    setStateData(stateData);
    const cityData = await getcityDataById(peopleDataById?.state_region);
    setCityData(cityData);
  };

  useEffect(() => {
    if (added_id) {
      getPeopleDetailsDataById();
    }
  }, [added_id]);

  useEffect(() => {
    if (id) {
      getPeopleDetailsDataByIdEdit();
    }
  }, [id]);

  useEffect(() => {
    getCountryListData();
  }, []);

  useEffect(() => {
    if (peopleDataById) {
      fetchRequireData(peopleDataById);
    }
  }, [peopleDataById]);

  const fetchCompanyData = async () => {
    dispatch(setLoading(true));
    const data = await getData(
      "Company/getCompanyDetails",
      { company_id: company_id },
      cookies.t
    );
    dispatch(setLoading(false));
    const company_data = data?.[0];
    setFetchedCompanyData(company_data);
  };
  useEffect(() => {
    if (isUUID(company_id) && !added_id) {
      fetchCompanyData();
    }
  }, []);

  return {
    statusData,
    companyData,
    stateData,
    contryData,
    fetchedCompanyData,
    RoleTypeData,
    cityDataList,
    handleSubmit,
    peopleDataById,
    setStateData,
    setCityData,
    handleAdd,
    peopleCreated,
    formattedCountries,
    setCompanyData,
    handleEditDraft,
  };
};
export default AddPeopleModal;
