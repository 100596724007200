import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import { deleteData } from "../../../../services";
import Loader from "../../../atoms/Loader";
import TabStrip from "../../../molecules/TabStrip";
import SavedCompanies from "./components/SavedCompanies";
import SavedPeople from "./components/SavedPeople";
import SavedProduct from "./components/SavedProduct";

import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../../../../services";
import { loaderData } from "../../../../store/slices/loaderSlice";
import Search from "../../../atoms/Search";
import Pagination from "../../../molecules/Pagination";
import ShimmerSaved from "./Shimmer/shimmer";
import "./saved_list.scss";

const SavedList = () => {
  const screenWidth = useScreenWidth();
  const [cookies] = useCookies(["t"]);
  const dispatch = useDispatch();
  const loadingDataValue = useSelector(loaderData);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Company");
  const [totalActiveItem, setTotalActiveItem] = useState<any>();
  const [savedData, setSavedData] = useState();
  const [isSearchedLib, setIsSearchedLib] = useState(false);
  const [searchedValueLib, setSearchedValueLib] = useState<string>("");
  const [removeIcon, setRemoveIcon] = useState(false);
  const [loadShimmer, setLoadShimmer] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  // Handle page change
  const handlePageChange = useCallback((page: any) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage: any) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);
  // const getSavedData = async () => {
  //   try {
  //     const res = await getData({
  //       endpoint: "SavedList/getAllSavedList",
  //       params: {
  //         search_query: searchedValueLib,
  //       },
  //       token: cookies.t,
  //     });
  //     setSavedData(res);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    setTimeout(() => {
      setLoadShimmer(false);
    }, 1000);
  }, []);

  const getSavedData = useCallback(async () => {
    try {
      setLoadShimmer(true);
      const res = await getData({
        endpoint: "SavedList/getAllSavedList",
        params: {
          search_query: searchedValueLib,
          type: activeTab,
          page: currentPage,
          page_limit: itemsPerPage,
        },
        token: cookies.t,
      });

      setLoadShimmer(false);
      setSavedData(res?.data);
      setTotalActiveItem(res?.total_record);
    } catch (error) {
      console.error(error);
    }
  }, [searchedValueLib, cookies.t, activeTab, currentPage, itemsPerPage]);

  useEffect(() => {
    getSavedData();
  }, [getSavedData]);

  useEffect(() => {
    if (screenWidth < 765) {
      setRemoveIcon(true);
    } else {
      setRemoveIcon(false);
    }
  }, [screenWidth]);

  const handleRemove = async (id: any) => {
    const res = await deleteData({
      endpoint: "SavedList/removeFromList",
      params: {
        id: id,
      },
      token: cookies.t,
    });

    if (res) {
      getSavedData();
    }
  };

  const dataArray = [
    {
      id: 0,
      label: "Company",
      view: (
        <>
          <SavedCompanies
            removeIcon={removeIcon}
            data={savedData}
            handleDelete={(id: any) => handleRemove(id)}
            isSearched={searchedValueLib.length > 0}
          />
          {totalActiveItem >= 10 && (
            <div className="mt-4 mb-4">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={totalActiveItem}
                totalCountData={totalActiveItem}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            </div>
          )}
        </>
      ),
    },
    {
      id: 1,
      label: "People",
      view: (
        <>
          <SavedPeople
            removeIcon={removeIcon}
            data={savedData}
            handleDelete={(id: any) => handleRemove(id)}
            isSearched={searchedValueLib.length > 0}
          />
          {totalActiveItem >= 10 && (
            <div className="mt-4 mb-4">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={totalActiveItem}
                totalCountData={totalActiveItem}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            </div>
          )}
        </>
      ),
    },
    {
      id: 2,
      label: "Product",
      view: (
        <>
          <SavedProduct
            removeIcon={removeIcon}
            data={savedData}
            handleDelete={(id: any) => handleRemove(id)}
            isSearched={searchedValueLib.length > 0}
          />
          {totalActiveItem >= 10 && (
            <div className="mt-4 mb-4">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={totalActiveItem}
                totalCountData={totalActiveItem}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            </div>
          )}
        </>
      ),
    },
  ];

  const _getComponent = (tabs: any) => {
    return dataArray
      .filter((item) => item.label === tabs)
      .map((item) => item.view)[0];
  };

  const onTabChange = (event: any, value: any) => {
    setCurrentPage(1);
    setItemsPerPage(10);
    setActiveTab(value);
  };
  const handleSearchLibrary = (searchedValueLib: string) => {
    setIsSearchedLib(true);
    setSearchedValueLib(searchedValueLib);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="saved-list-container">
        <div className="saved-list-header">
          <div className="h1-i mb-2">Saved List</div>
          <Search
            onSearchEmit={handleSearchLibrary}
            placeholder="Search by name,company name,Type"
            style={{ maxWidth: "355px" }}
            phonestyle={{ minWidth: "92vw", maxWidth: "92vw" }}
          />
        </div>

        <TabStrip
          activeTab={activeTab}
          onTabChange={onTabChange}
          data={dataArray}
        />
        {loadShimmer ? (
          <ShimmerSaved />
        ) : (
          <Box sx={{ width: "100%" }}>
            <Box>{_getComponent(activeTab)}</Box>
          </Box>
        )}
      </div>
    </>
  );
};

export default SavedList;
