const BackArrowLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="12"
      viewBox="0 0 6 12"
      fill="none"
    >
      <path
        d="M5.73104 11.5917C5.80915 11.5142 5.87114 11.422 5.91345 11.3205C5.95576 11.2189 5.97754 11.11 5.97754 11C5.97754 10.89 5.95576 10.7811 5.91345 10.6795C5.87114 10.578 5.80915 10.4858 5.73104 10.4083L1.91438 6.59168C1.83627 6.51421 1.77428 6.42204 1.73197 6.32049C1.68967 6.21894 1.66788 6.11002 1.66788 6.00001C1.66788 5.89 1.68967 5.78108 1.73197 5.67953C1.77428 5.57798 1.83627 5.48581 1.91438 5.40834L5.73104 1.59168C5.80915 1.51421 5.87114 1.42204 5.91345 1.32049C5.95576 1.21894 5.97754 1.11002 5.97754 1.00001C5.97754 0.89 5.95576 0.781078 5.91345 0.679529C5.87114 0.577979 5.80915 0.485812 5.73104 0.408343C5.57491 0.253134 5.3637 0.166016 5.14354 0.166016C4.92339 0.166016 4.71218 0.253134 4.55604 0.408343L0.731051 4.23334C0.262883 4.70209 -8.12884e-05 5.33751 -8.13173e-05 6.00001C-8.13463e-05 6.66251 0.262883 7.29793 0.731051 7.76668L4.55604 11.5917C4.71218 11.7469 4.92339 11.834 5.14354 11.834C5.3637 11.834 5.57491 11.7469 5.73104 11.5917Z"
        fill="#1A1D1F"
      />
    </svg>
  );
};
export default BackArrowLeft;
