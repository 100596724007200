import "./database.scss";
const ShimmerDatabase = () => {
  return (
    <div className="dbsh-conatiner">
      <div style={{ border: "1px", background: "none" }}>
        {Array.from({ length: 8 }, (_, index) => (
          <>
            <div className="dbsh-cards-conatiner">
              <div className="dbsh-card-header">
                <div className="dbsh-card-img"></div>
                <div className="dbsh-card-details">
                  <div className="dbsh-card-name"></div>
                  <div className="dbsh-card-loaction"></div>
                  <div className="dbsh-card-detals"></div>
                </div>
              </div>
              <div style={{ background: "none", display: "flex", gap: "10px" }}>
                <div className="dbsh-card-btn"></div>
                <div className="dbsh-card-btn"></div>
              </div>
            </div>
            <div className="dbsh-border-line"></div>
          </>
        ))}
      </div>
    </div>
  );
};
export default ShimmerDatabase;
