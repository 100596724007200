import React from "react";
import "./renderFaq.scss";

interface FAQItem {
  id: string;
  question: string;
  answer: string;
}

interface RenderFaqProps {
  data?: FAQItem[]; // Make the data prop optional
  color?: string;
  type?: string;
}

const RenderFaq: React.FC<RenderFaqProps> = ({ data, color, type }) => {
  // Check if data is provided
  if (!data || data.length === 0) {
    return <div style={{ textAlign: "center" }}>No data available</div>;
  }

  // Get the first 5 items from the array
  const itemsToShow = type === "all" ? data : data.slice(0, 5);

  return (
    <div>
      <div className="accordion" id="accordionExample">
        {itemsToShow.map((item, index) => (
          <div className="accordian" key={item.id}>
            <div key={item.id} className="accordion-item">
              <h2 className="accordion-header" id={`heading${item.id}`}>
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${item.id}`}
                  aria-expanded="false"
                  aria-controls={`collapse${item.id}`}
                  style={{ backgroundColor: color }}
                >
                  {item.question}
                </button>
              </h2>
              <div
                id={`collapse${item.id}`}
                className="collapse"
                aria-labelledby={`heading${item.id}`}
                data-parent="#accordion"
              >
                <div
                  className="accordion-body"
                  style={{ backgroundColor: color }}
                >
                  <div>
                    <div className="shared_blog">
                      <p
                        className="card-body rich-text-editor-container control-image-accrordion"
                        dangerouslySetInnerHTML={{ __html: item.answer }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RenderFaq;
