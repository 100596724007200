import { useEffect } from "react";
import LoginForm from '../../organisms/forms/authForms/LoginForm';
import Heading from "../../atoms/Heading";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  // Back btn Handling ---------------------------------------------------------------------------------------------
  const onButtonEvent = (e : any) => {
    e.preventDefault();
    navigate(0);
  };

  useEffect(() => {
    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener("popstate", onButtonEvent);
    return () => {
      window.removeEventListener("popstate", onButtonEvent);
    };
  }, []);
  // --------------------------------------------------------------------------------------------------------------

  return (
    <>
      <div className="h1-i mb-4">Login to PrimeAlpha</div>
      <LoginForm />
    </>
  );
};

export default Login;
