import { InputAdornment, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "./datepicker.scss";

const datePickerStyle = {
  height: "52px", // Set your desired height
  backgroundColor: "lightblue", // Set your desired background color
  border: "2px solid darkblue", // Set your desired border
};

// ... (imports remain the same)

export function MonthYearSelector({
  onChange,
  placeholder = "Select",
  selectedDate,
  disableFuture = false,
  disablePast = false,
  minDateData,
  maxDateData,
  // IsOpen,
  // setIsOpen
}) {
  const handleDateChange = (date) => {
    if (onChange) {
      onChange(date.format("YYYY-MM"));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="calender-input-component" tabIndex={0}>
        <DatePicker
          disableFuture={disableFuture}
          disablePast={disablePast}
          openTo="month"
          views={["year", "month"]}
          style={datePickerStyle}
          // open={IsOpen}
          minDate={minDateData ? dayjs(minDateData) : undefined} // Use minDate if available
          maxDate={maxDateData ? dayjs(maxDateData) : undefined} // Use minDate if available
          onChange={handleDateChange}
          value={selectedDate ? dayjs(selectedDate) : null} // Set the initially selected date
          renderInput={(props) => (
            <TextField
              {...props}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span style={{ color: "red" }}>{placeholder}</span>
                  </InputAdornment>
                ),
              }}
            />
          )}
          slotProps={{
            textField: { placeholder: `${placeholder}` },
            field: {
              readOnly: true,
            },
          }}
          // sx={{
          //   "&. MuiTextField-root": {
          //     width: "100% !important",
          //   },
          //   "&. MuiFormControl-root-MuiTextField-root": {
          //     width: "100% !important",
          //   },
          // }}
          className="w-100"
        />
      </div>
    </LocalizationProvider>
  );
}

export function DateSelector({
  onChange,
  placeholder = "Select Date",
  disableFuture = false,
  disablePast = false,
  disabled = false,
  selectedDate,
  minDateData,
  maxDateData,
}) {
  const handleDateChange = (date) => {
    if (onChange) {
      // console.log("date", date);
      const isoDate = date.format("YYYY-MM-DDTHH:mm:ss"); // Convert to ISO format
      // console.log("iso", isoDate);
      onChange(isoDate + "Z");
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="calender-input-component" tabIndex={0}>
        <DatePicker
          disableFuture={disableFuture}
          disablePast={disablePast}
          style={datePickerStyle}
          slotProps={{
            textField: { placeholder: `${placeholder}` },
            field: {
              readOnly: true,
            },
          }}
          onChange={handleDateChange}
          disabled={disabled}
          value={selectedDate ? dayjs(selectedDate) : null}
          minDate={minDateData ? dayjs(minDateData) : undefined}
          maxDate={maxDateData ? dayjs(maxDateData) : undefined}
          className="w-100"
        />
      </div>
    </LocalizationProvider>
  );
}
