import { Box } from "@mui/material";
import debounce from "lodash/debounce";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Default from "../../../../../assets/images/default.png";
import Default1 from "../../../../../assets/images/default2.png";
import { getType } from "../../../../../services/editHelper";
import { getProductsData } from "../../../../../services/productsService";
import { setLoading } from "../../../../../store/slices/loaderSlice";
import ArrowDown from "../../../../Icons/ArrowDown";
import RoadShowIcon from "../../../../Icons/RoadShowIcon";
import GoogleMapCustom from "../../../../atoms/GoogleMap/index";
import IconCircle from "../../../../atoms/IconCircle/index";
import Search from "../../../../atoms/Search/index";
import TypeDiv from "../../../../atoms/TypeDiv";
import CloseBtnIcom from "../../../../molecules/Modal/CloseBtnIcon";
import Navbar from "../../../../molecules/Navbar/index";
import NoContentCard from "../../../../molecules/NoContentCard";
import Pagination from "../../../../molecules/Pagination";
import TabStrip from "../../../../molecules/TabStrip";
import MultiselectFilter from "../../../../organisms/MultiselectFilter";
import LocationFilter from "../Filter/LocationFilter";
import OverView from "./OverView";
import Product from "./Product";
import style from "./index.module.scss";

const CloseButton = ({ onClose, className, style }) => {
  return (
    <div className={className} onClick={onClose} style={style}>
      <i className="d-flex">{<CloseBtnIcom />}</i>
    </div>
  );
};

const RoadShow = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t"]);
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [InvestorList, setInvestoreList] = useState([]);
  const [searchvalue, setSearchvalue] = useState();
  const [investorAddressData, setInvestoreAddressData] = useState();
  const [investorCompanyId, setInvestoreCompanyId] = useState();
  const [markerlatlan, setmarkerlatlan] = useState();
  const [locationData, setLocationData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("overview");
  const [filterOpenlocation, setFilterOpenlocation] = useState(false);
  const [dropDownPostion, setDropdownPositions] = useState();
  const [productTypeFilter, setProductTypeFilter] = useState(false);
  const [categoryFilterData, setCategoryFilterData] = useState();
  const [categoryData, setCategoryData] = useState();

  const handleSearch = (e) => {
    setSearchvalue(e);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };
  const handleDebounceSearch = debounce(handleSearch, 1000);

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };

  let dataArray = [
    {
      id: 0,
      label: "overview",
      view: (
        <OverView
          details={investorAddressData?.[0]?.overview}
          company_id={investorAddressData?.[0]?.company_id}
        />
      ),
    },
    {
      id: 1,
      label: "Products",
      view: <Product company_id={investorAddressData?.[0]?.company_id} />,
    },
  ];

  const _getComponet = (tabs) => {
    return dataArray
      .filter((item) => item.label === tabs)
      .map((item) => item.view)[0];
  };

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  useEffect(() => {
    const getInvestorList = async () => {
      dispatch(setLoading(true));
      try {
        const res = await getProductsData({
          token: cookies.t,
          search: searchvalue,
          currentPage,
          itemsPerPage,
          locationData,
          productInterest: categoryFilterData,
        });
        dispatch(setLoading(false));
        setInvestoreList(res);
        setInvestoreAddressData(res?.data);
        if (res?.data?.length === 0) {
          setIsModalOpen(false);
        }
      } catch (exp) {
        console.log("InvestorList", exp);
      }
    };
    getInvestorList();
  }, [
    searchvalue,
    itemsPerPage,
    currentPage,
    locationData,
    categoryFilterData,
  ]);

  const fetchCategoryData = async () => {
    const res = await getType();
    if (res) {
      setCategoryData(res);
    }
  };
  useEffect(() => {
    fetchCategoryData();
  }, []);

  const filteredLocationData = InvestorList?.data?.map((item) => {
    if (locationData?.country !== (null || undefined)) {
      const address = item?.address?.filter(
        (data) =>
          data?.country === locationData?.country &&
          locationData?.state?.includes(data?.state_region) &&
          locationData?.city?.includes(data?.city)
      );
      return {
        ...item,
        address: address,
      };
    } else {
      return {
        ...item,
      };
    }
  });

  const handleClickAdress = (clickAddress) => {
    if (locationData?.country !== (null || undefined)) {
      clickAddress.address = clickAddress?.address?.filter(
        (item) => item?.country === locationData?.country
      );
      setInvestoreAddressData([clickAddress]);
    } else {
      setInvestoreAddressData([clickAddress]);
    }
    setIsModalOpen(true);
  };

  const onCloseButton = () => {
    setIsModalOpen(false);
  };

  // const tempdata = InvestorList?.data?.map((item) => {
  //   return item?.address?.filter(
  //     (item) => item?.country === locationData?.country
  //   );
  // });
  useEffect(() => {
    updatePositions();
  }, []);

  const updatePositions = () => {
    const productRoadShow = document.getElementById("locationFilterProduct");
    const productTypeReact = productRoadShow.getBoundingClientRect();
    const ProductRoadShowType = document.getElementById("productDropDown");
    const ProductRoadShowTypeReact =
      ProductRoadShowType.getBoundingClientRect();
    setDropdownPositions({
      product: {
        left: productTypeReact?.right,
      },
      productType: {
        left: ProductRoadShowTypeReact?.right,
      },
    });
  };

  return (
    <>
      <Navbar />
      <div>
        <div className={style.roadShowWrap}>
          <div className={style.iconLabelWrap}>
            <div
              className={style.labelStyle}
              onClick={() => {
                navigate("/dashboard/products/productdatabase");
              }}
            >
              <IconCircle
                color={"#ffffff"}
                icon={
                  <i className={``}>
                    <RoadShowIcon />
                  </i>
                }
                size="42px"
                border="1px solid #C0C4C9"
                iconSize="18px"
              />
              <label className={style.label}>Roadshow</label>
            </div>
          </div>

          <div className={style.RoadShowfilterWrap}>
            <LocationFilter
              handleFilter={(value) => setLocationData(value)}
              type="right"
              filterOpenlocation={filterOpenlocation}
              setFilterOpenlocation={setFilterOpenlocation}
              id="locationFilterProduct"
              dropdownPosition={dropDownPostion?.product}
            />
            <MultiselectFilter
              title="Product Type"
              icon={<ArrowDown />}
              data={categoryData}
              open={productTypeFilter}
              setOpen={setProductTypeFilter}
              id="productDropDown"
              handleFilter={(filter) => {
                setCategoryFilterData({
                  type: filter,
                });
              }}
              dropdownPosition={dropDownPostion?.productType}
            />
          </div>
        </div>
        <div className={style.InvestorListWrap}>
          <div className={style.leftSide}>
            <div className={style.searchWrap}>
              <Search
                className="searchWrap"
                placeholder="Search by email, company name"
                onSearchEmit={handleDebounceSearch}
                style={{ maxWidth: "100%" }}
              />
            </div>
            <div className={style.leftsideLists}>
              {InvestorList?.data?.length > 0 ? (
                <>
                  {filteredLocationData?.map((item) => {
                    return (
                      <div
                        className={
                          item?.company_id === investorCompanyId
                            ? style.InvestorListWrapClicked
                            : style.investorListWrap
                        }
                        onClick={() => {
                          handleClickAdress(item);
                          setInvestoreCompanyId(item?.company_id);
                        }}
                      >
                        <h6 className={style.companyName}>{item?.name}</h6>
                        <div className={style.companyType}>{item?.type}</div>
                        <div className={style.companyType}>{item?.email}</div>
                        {item?.address?.length > 0 && (
                          <div className={style.companyType}>
                            {locationData?.country !== (null || undefined) ? (
                              <>
                                {item?.address?.[0]?.city +
                                  ", " +
                                  item?.address?.[0]?.state_region +
                                  ", " +
                                  item?.address?.[0]?.country}
                              </>
                            ) : (
                              <>
                                {item?.address?.[0]?.city +
                                  ", " +
                                  item?.address?.[0]?.state_region +
                                  ", " +
                                  item?.address?.[0]?.country}
                              </>
                            )}
                          </div>
                        )}
                        <div className={style.ItemDatesInfo}>
                          <div className={style.formattedDate}>
                            Added on{" "}
                            {moment
                              .utc(item?.created_date)
                              .format("MMM D, YYYY")}
                          </div>
                          <div className="dot"></div>
                          <div className={style.formattedDate}>
                            Last updated on{" "}
                            {moment
                              .utc(item?.updated_date)
                              .format("MMM D, YYYY")}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <NoContentCard />
                </div>
              )}
              <div>
                {InvestorList?.total_record > 10 && (
                  <div className="mt-2">
                    <Pagination
                      itemsPerPage={itemsPerPage}
                      isShowResult={false}
                      totalCountData={InvestorList?.total_record}
                      // totalItems={InvestorList?.total_record}
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                      onItemsPerPageChange={handleItemsPerPageChange}
                    />
                  </div>
                )}
              </div>
            </div>

            {isModalOpen && (
              <>
                <div className={style.googleGlobalWrap}>
                  <div className={style.googleGlobalInnerWrap}>
                    <div className={style.imgcloseWrap}>
                      <img
                        src={investorAddressData?.[0]?.cover_photo || Default}
                        alt="company_image"
                      />

                      <CloseButton
                        className="close-icon-pop-up"
                        onClose={onCloseButton}
                        style={{ position: "absolute" }}
                      />

                      <img
                        className={style.LogoRoadMap}
                        src={
                          investorAddressData?.[0]?.profile_photo || Default1
                        }
                      ></img>

                      <div
                        className={style.viewCta}
                        onClick={() => {
                          window.open(
                            `/dashboard/company/${investorAddressData?.[0]?.company_id}`,
                            "_blank"
                          );
                        }}
                      >
                        View
                      </div>
                    </div>
                    <div style={{ paddingLeft: "10px", marginTop: "40px" }}>
                      <div className={style.companyHeaderRoad}>
                        <div className={style.companyNamepopup}>
                          {investorAddressData?.[0]?.name}
                        </div>
                        {/* <div
                          onClick={() => {
                            navigate(
                              `/dashboard/company/${investorAddressData?.[0]?.company_id}`
                            );
                          }}
                        >
                          <i
                            class="fi fi-rr-share-square"
                            style={{ display: "flex" }}
                          ></i>
                        </div> */}
                      </div>
                      <div className={style.companyHeaderRoad}>
                        {investorAddressData?.[0]?.address?.length > 0 && (
                          <div
                            className={style.companyType}
                            style={{ paddingBottom: "0" }}
                          >
                            {investorAddressData?.[0]?.address?.[0]
                              ?.state_region +
                              ", " +
                              investorAddressData?.[0]?.address?.[0]?.country}
                          </div>
                        )}
                        <TypeDiv data="Company" color="#CABDFF" />
                      </div>
                    </div>
                    <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                      <TabStrip
                        activeTab={activeTab}
                        onTabChange={onTabChange}
                        data={dataArray}
                        allowedTabId="0"
                        margin="1rem"
                        type="small"
                      />
                    </div>

                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ padding: "0 20px", pt: 2 }}>
                        {_getComponet(activeTab)}
                      </Box>
                    </Box>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={style.rightSide}>
            <GoogleMapCustom
              InvestorList={investorAddressData}
              markerlatlan={markerlatlan}
              setmarkerlatlan={setmarkerlatlan}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default RoadShow;
