import { useEffect, useRef, useState } from "react";
import { getCountries } from "../../../../../../../services/editHelper";

const LocationFilter = () => {
  const [country, setCountry] = useState([]);

  const isMounted = useRef(true);

  const fetchCountries = async () => {
    const res = await getCountries();
    if (res) {
      setCountry(res);
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      fetchCountries();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  return {
    country,
  };
};

export default LocationFilter;
