import "./viewDataShimer.scss";
const ViewDataShimmer = () => {
  return (
    <div className="view-data-container align-items-center justify-content-between d-flex">
      <div className="d-flex view-main-sr">
        <div className="view-sr-pic"></div>
        <div className="view-sr-content">
          <div className="contetnt-video-lr"></div>
          <div className="contetnt-video-md"></div>
          <div className="contetnt-video-lr"></div>
        </div>
      </div>
      <div className="view-sr-btn"></div>
    </div>
  );
};
export default ViewDataShimmer;
