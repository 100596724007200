import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { confimationStyles } from "../../../../../../assets/styles/toast";
import { getData, patchData } from "../../../../../../services";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import QuillEditor from "../../../../../molecules/QuillEditor/QuillEditor";

const Disclaimer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cookies] = useCookies(["t"]);
  const { id } = useParams();
  const [data, setData] = useState({ disclaimer: "" });
  const [disclaimerContent, setDisclaimerContent] = useState("");

  const fetchDisclaimer = async () => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "Product/getDisclaimer",
      params: { product_id: id },
      token: cookies.t,
    });

    if (res) {
      dispatch(setLoading(false));
      setData(res);
    }
  };

  useEffect(() => {
    fetchDisclaimer();
  }, []);

  const handleDisclaimerChange = (content: any) => {
    setDisclaimerContent(content);
  };

  const handleSubmit = async () => {
    try {
      dispatch(setLoading(true));
      const res = await patchData({
        endpoint: "Product/updateDisclaimer",
        data: {
          disclaimer: disclaimerContent,
          product_id: id,
          user_role: "Customer",
        },
        params: { product_id: id },
        token: cookies.t,
      });
      if (res) {
        dispatch(setLoading(false));
        toast.success("Disclaimer Added Succesfully", {
          duration: 1000,
          style: confimationStyles,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="h24 mb-sm text-left">Disclaimer</div>

      <QuillEditor
        placeholderText="Enter Disclaimer"
        height="300px"
        hp="80%"
        type=""
        classes=""
        onChange={handleDisclaimerChange}
        value={data?.disclaimer}
      />

      {/* <QuillEditor
  placeholderText='Enter Disclaimer'
  onChange={() => {}}
  value={''}
  }
/> */}

      <div style={{ marginTop: "auto" }}>
        <footer className="footer">
          <button
            className="btn btn-outline-dark"
            onClick={() => navigate(-1)}
            type="button"
          >
            Back
          </button>

          <button
            type="button"
            className="btn btn-primary mt-0"
            onClick={() => handleSubmit()}
          >
            Update
          </button>
        </footer>
      </div>
    </>
  );
};

export default Disclaimer;
