const VideoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7 0C3.13404 0 0 3.13404 0 7C0 10.866 3.13404 14 7 14C10.866 14 14 10.866 14 7C14 3.13404 10.866 0 7 0ZM5.09295 3.58413C5.23126 3.58101 5.37421 3.61512 5.51363 3.69071L10.0008 6.28765C10.245 6.42948 10.4103 6.68984 10.4103 6.99438C10.4103 7.29892 10.245 7.55926 10.0008 7.70111L5.51363 10.3037C4.95598 10.606 4.32187 10.2208 4.30769 9.58573V4.40305C4.31828 3.92673 4.67801 3.59348 5.09295 3.58413Z"
        fill="#6F767E"
      />
    </svg>
  );
};
export default VideoIcon;
