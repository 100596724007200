import { getData, patchData, postData } from "../../../../services";

export const addInvestorPreference = async (data, token) => {
  try {
    const response = await postData({
      endpoint: "Company/addCompanyInvestmentPrefrence",
      data: data.prefrences,
      params: { company_id: data.company_id, user_role: "Customer" },
      token: token,
    });
    return response;
  } catch (error) {
    console.log("addInvestmentPreferance", error);
  }
};

export const editInvestmentDetail = async (payload, token) => {
  try {
    const response = await patchData({
      endpoint: "Company/editCompanyInvestmentPrefrence",
      data: { ...payload, user_role: "Customer" },
      params: {},
      token: token,
    });
    return response;
  } catch (exp) {
    console.log("error", exp);
  }
};

export const getInvestmentDataById = (id, token) => {
  const params = {
    preference_id: id,
  };

  const response = getData({
    endpoint: "Company/getInvestmentPreferenceDetails",
    params: params,
    token: token,
  });
  return response;
};

export const addPeopleInvestorPreference = async (data, token) => {
  try {
    const response = await postData({
      endpoint: "People/addPeopleInvestmentPrefrence",
      data: data.prefrences,
      params: { people_id: data.people_id },
      token: token,
    });
    return response;
  } catch (error) {
    console.log("addInvestmentPreferance", error);
  }
};

export const editPeopleInvestmentDetail = async (payload, token) => {
  try {
    const response = await patchData({
      endpoint: "People/editPeopleInvestmentPrefrence",
      data: { ...payload },
      params: {},
      token: token,
    });
    return response;
  } catch (exp) {
    console.log("error", exp);
  }
};

export const getPeopleInvestmentDataById = (id, token) => {
  const params = {
    preference_id: id,
  };

  const response = getData({
    endpoint: "People/getInvestmentPreferenceDetails",
    params: params,
    token: token,
  });
  return response;
};
