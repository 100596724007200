import { useEffect, useState } from "react";
import ArrowDown from "../../../../../Icons/ArrowDown";
import Error from "../../../../../atoms/Error";
import HeadingDropDown from "../../../../../atoms/HeadingDropDown";
import style from "../index.module.scss";

const AumFilter = ({ handleAum, reset, id, dropdownPosition }) => {
  const [isAumOpen, setIsAumOpen] = useState(false);
  const [aum, setAum] = useState({ min: null, max: null });
  const [errors, setErrors] = useState({});
  const [confirmAum, setConfirmAum] = useState({ min: "", max: "" });
  const [isConfirmedAum, setIsAumConfirm] = useState(false);

  useEffect(() => {
    if (reset) {
      handleReset();
    }
  }, [reset]);

  const handleConfirm = (type) => {
    if (aum) {
      if (!aum.min) {
        setErrors((prev) => ({ ...prev, min: "Min is Required" }));
        return;
      } else if (!aum.max) {
        setErrors((prev) => ({ ...prev, max: "Max is Required" }));
        return;
      } else {
        // If both min and max are present, clear any previous errors
        setErrors({});
      }

      // If all validations pass, proceed with confirming AUM
      setConfirmAum({ ...aum });
      setIsAumConfirm(true);

      handleAum({ ...aum });
    }
    setIsAumOpen(false);
  };

  const handleReset = (type) => {
    if (aum) {
      setAum({ min: "", max: "" });
      setConfirmAum({ min: "", max: "" });
    }

    setIsAumOpen(false);
    setIsAumConfirm(false);
    handleAum({});
  };

  return (
    <div className={style.multi_select_filter_wrapper}>
      <HeadingDropDown
        title="AUM($mm)"
        icon={<ArrowDown />}
        IsOpen={isAumOpen}
        setIsOpen={setIsAumOpen}
        active={isConfirmedAum}
        id={id}
        dropdownPosition={dropdownPosition}
        type="small"
      >
        <div>
          <h2 className={style.InvestorFormHeader}>AUM ($mm)</h2>
          <form className={style.formConatiner}>
            <div className={style.formInput}>
              <label htmlFor="country">Min AUM</label>
              <input
                type="number"
                placeholder="Enter Min Aum"
                onChange={(e) => setAum({ ...aum, min: e.target.value })}
                value={aum.min}
              />
              {errors.min && <Error error={errors.min} />}
            </div>

            <div className={style.formInput}>
              <label htmlFor="city">Max AUM</label>
              <input
                type="number"
                placeholder="Enter Max Aum"
                onChange={(e) => setAum({ ...aum, max: e.target.value })}
                value={aum.max}
              />
              {errors.max && <Error error={errors.max} />}
            </div>
          </form>

          <div className={style.bottomContainer}>
            <span
              className="medium cursor-pointer"
              onClick={() => handleReset("aum")}
            >
              Reset
            </span>

            <button
              className={`btn btn-primary ${style.customBtn}`}
              // disabled={!aum.min || !aum.max || !(aum.min > aum.max)}
              onClick={() => handleConfirm("aum")}
              // disabled={!(aum.max > aum.min)}
            >
              Confirm
            </button>
          </div>
        </div>
      </HeadingDropDown>
    </div>
  );
};

export default AumFilter;
