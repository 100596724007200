import { useFormik } from "formik";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { confimationStyles } from "../../../../../../assets/styles/toast";
import { allPlatforms } from "../../../../../../helpers/staticData";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import SelectDropdown from "../../../../../atoms/Dropdown";
import Error from "../../../../../atoms/Error";
import { editSocialDetail } from "./socialHelper";

const EditChannel = ({ id, initialData, onClose, onUpdate }) => {
  const [cookies] = useCookies(["t"]);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    type: Yup.string().required("Channel is required"), // Add validation for type
    channel_link: Yup.string()
      .url("Invalid URL")
      .required("Channel link is required"),
  });

  const formik = useFormik({
    initialValues: {
      type: initialData ? initialData.type : "",
      channel_link: initialData ? initialData.link : "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const dataToSend = {
        ...values,
        id: initialData.id,
        user_role: "Admin",
      };

      if (initialData) {
        try {
          dispatch(setLoading(true));
          const res = await editSocialDetail(dataToSend, cookies.t);
          if (res.status) {
            dispatch(setLoading(false));
            toast.success("Social Updated Successfully", {
              duration: 1000,
              style: confimationStyles,
            });
            formik.resetForm();
            onClose();
            onUpdate();
          }
        } catch (error) {
          console.log(error);
        }
      }

      onClose();
      onUpdate();
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit} key={id}>
        <div className="form-group">
          <label htmlFor="type" className="required-field">
            Channel
          </label>
          <SelectDropdown
            name="type"
            data={allPlatforms}
            selectedValue={formik.values.type}
            onChange={(value) => formik.setFieldValue("type", value)}
            onBlur={formik.handleBlur}
            placeholder="Select Channel"
          />
          {formik.touched.type && formik.errors.type && (
            <Error error={formik.errors.type} />
          )}
        </div>

        <div className="form-group mt-3">
          <label htmlFor="channel_link" className="required-field">
            {formik.values.type} Account Link
          </label>
          <input
            type="text"
            id="channel_link"
            name="channel_link"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.channel_link}
            placeholder={`Enter Link`}
          />
          {formik.touched.channel_link && formik.errors.channel_link && (
            <Error error={formik.errors.channel_link} />
          )}
        </div>

        <div className="d-flex justify-content-end">
          <button
            type="submit"
            className="btn btn-primary mt-3"
            disabled={
              initialData ? !formik.isValid : !formik.isValid || !formik.dirty
            }
          >
            {initialData ? "Update" : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditChannel;
