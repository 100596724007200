export const htmlToText = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

export const htmlToTextWithSpaces = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");

  function addSpaces(node) {
    if (node.nodeType === Node.TEXT_NODE) {
      // Add space after the text content
      node.textContent += " ";
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      // Recursively process child nodes
      for (let i = 0; i < node.childNodes.length; i++) {
        addSpaces(node.childNodes[i]);
      }
      // Add space after the ending tag
      node.insertAdjacentText("afterend", " ");
    }
  }

  // Start adding spaces from the document body
  addSpaces(doc.body);

  return doc.body.textContent || "";
};
