import "./HeroSection.scss";
const HeroSection = ({
  ImagePic,
  Heading,
  Button,
  onClick,
  Button2,
  onClick2,
  isButton2Visible,
  HeadingStyle,
}) => {
  return (
    <div className="Investor-DataBase-Image-Container">
      <div
        className="Investor-DataBase-Pic"
        // style={{
        //   background: `url(${ImagePic}) lightgray 50% / cover no-repeat`,
        // }}
      >
        {ImagePic && (
          <img src={ImagePic} className="Investor-DataBase-Pic"></img>
        )}
        <div
          className={
            HeadingStyle
              ? "Investor-DataBase-Pic-Content-fund-directory"
              : "Investor-DataBase-Pic-Content"
          }
        >
          <h1
            className={
              HeadingStyle
                ? "Investor-DataBase-Pic-Content-fund-Heading"
                : "Investor-DataBase-Pic-Heading"
            }
          >
            {Heading}
          </h1>

          <div className="Investor-DataBase-Btns">
            <button className="Investor-DataBase-Pic-btn" onClick={onClick}>
              {Button}
            </button>
            {isButton2Visible && Button2 && (
              <button className="Investor-DataBase-Pic-btn" onClick={onClick2}>
                {Button2}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
