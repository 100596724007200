import Shimmer from "./Company/Shimmer/shimmer";

const None = () => {
  return (
    <>
      <Shimmer />
    </>
  );
};

export default None;
