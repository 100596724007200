import { useNavigate } from "react-router-dom";

const MyComponent = ({ selectedComponent }) => {
  const navigate = useNavigate();

  return (
    <div className="Breadcum-policy-container">
      <button
        style={{
          color:
            selectedComponent === "Policy" ? " rgb(61, 155, 233)" : "black",
        }}
        className="main-toggle-btn"
        onClick={() => navigate("/policy")}
      >
        Privacy Policy & Cyber Security Policy
      </button>
      {" | "}
      <button
        style={{
          color: selectedComponent === "Terms" ? " rgb(61, 155, 233)" : "black",
        }}
        className="main-toggle-btn"
        onClick={() => navigate("/terms")}
      >
        Terms of Use
      </button>
      {" | "}
      <button
        style={{
          color: selectedComponent === "EULA" ? " rgb(61, 155, 233)" : "black",
        }}
        className="main-toggle-btn"
        onClick={() => navigate("/eula")}
      >
        End User License Agreement
      </button>
    </div>
  );
};

export default MyComponent;
