import PropTypes from "prop-types";

const CustomButton = ({
  handleClick,
  icon,
  text = "Add",
  classes,
  type = "btn-outline-dark",
  buttonType = "button",
  style,
  disabled,
  forMobile,
}) => {
  return (
    <button
      onClick={handleClick}
      className={`btn ${type} px-4 mt-0 ${classes}`}
      type={buttonType}
      style={style}
      disabled={disabled}
    >
      {icon && (
        <>
          <i className={forMobile ? `d-flex` : `me-2 d-flex`}>{icon}</i>
        </>
      )}
      {text}
    </button>
  );
};

CustomButton.propTypes = {
  handleClick: PropTypes.func,
  icon: PropTypes.any,
  text: PropTypes.string.isRequired,
  classes: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  forMobile: PropTypes.bool,
};

export default CustomButton;
