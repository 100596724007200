import Footer from "../../molecules/Footer";
import Navbar from "../../molecules/Navbar";
import { Outlet } from "react-router-dom";

import styles from "./password_layout.module.scss";

const PasswordLayout = () => {
  return (
    <>
      <Navbar dataShow={false} />
      <div className={styles.pass_screen}>
        <div className={styles.pass_content}>
          <Outlet />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PasswordLayout;
