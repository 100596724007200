import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { getData } from "../../../../../../services";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import style from "./index.module.scss";

const InvestorPreferance = ({ company_id }) => {
  const [cookies] = useCookies(["t"]);
  const [investmentData, setInvestmentData] = useState();
  const dispatch = useDispatch();

  const fetchInvestmentData = async () => {
    const payload = {
      company_id: company_id,
      page: 1,
      page_limit: 1000,
    };
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "Company/getAllCompanyInvestmentPrefrence",
      params: payload,
      token: cookies.t,
    });

    if (res) {
      dispatch(setLoading(false));
      setInvestmentData(res);
    }
  };

  useEffect(() => {
    fetchInvestmentData();
  }, [company_id]);

  const mapProductStrategyNames = (investmentItem) => {
    const data =
      investmentItem?.product_startegy?.map((strategy) => strategy.name) || [];
    return data.join(", ");
  };

  const mapProductSectore = (investmentItem) => {
    const data =
      investmentItem?.product_sectors?.map((strategy) => strategy.name) || [];
    return data.join(", ");
  };

  const mapProductGeography = (investmentItem) => {
    const data =
      investmentItem?.product_geography?.map((strategy) => strategy.name) || [];
    return data.join(", ");
  };

  return (
    <div>
      {investmentData?.data?.map((item) => {
        return (
          <div className={style.investorWrap}>
            <div className="row">
              <div className="col-sm-6">
                <div className="p-light-small">Product Structure</div>
                <div className="p-dark-small">
                  {item?.product_structure?.name || "--"}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="p-light-small">Product Type</div>
                <div className="p-dark-small">
                  {item?.product_type?.name || "--"}
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-6">
                <div className="p-light-small">Product Strategy</div>
                <div className="p-dark-small">
                  {mapProductStrategyNames(item)}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="p-light-small">Product Sector</div>
                <div className="p-dark-small">{mapProductSectore(item)}</div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-6">
                <div className="p-light-small">Product Geography</div>
                <div className="p-dark-small">{mapProductGeography(item)}</div>
              </div>
              <div className="col-sm-6">
                <div className="p-light-small">AUM Threshold</div>
                <p className="p-dark-small mb-0">
                  {item?.min_aum && "$"}&nbsp;{item?.min_aum}&nbsp;
                  {item?.min_aum && item?.max_aum && "-"}&nbsp;
                  {item?.max_aum && "$"}&nbsp;{item?.max_aum}
                </p>
              </div>
            </div>

            {(item?.min_track_record || item?.max_track_record) && (
              <div className="row mt-2">
                <div className="col-sm-6">
                  <div className="p-light-small"> Track Length Record</div>
                  <p className="p-dark-small">
                    {item?.min_track_record}&nbsp;
                    {item?.min_track_record && item?.max_track_record && "-"}
                    &nbsp;
                    {item?.max_track_record} Years
                  </p>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default InvestorPreferance;
