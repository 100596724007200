const RightUpArrow = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9849 1C11.9849 0.447708 12.4327 0 12.9849 0H19C19.5523 0 20 0.447723 20 1V7.01505C20 7.56732 19.5523 8.015 19 8.015C18.4477 8.015 18 7.56732 18 7.01503V3.41422L9.70709 11.7071C9.31659 12.0976 8.68341 12.0976 8.29291 11.7071C7.90234 11.3166 7.90234 10.6834 8.29291 10.2929L16.5858 2H12.9849C12.4327 2 11.9849 1.55228 11.9849 1ZM3 5C2.44769 5 2 5.44772 2 6V17C2 17.5523 2.44769 18 3 18H14C14.5523 18 15 17.5523 15 17V11.2619C15 10.7096 15.4477 10.2619 16 10.2619C16.5523 10.2619 17 10.7096 17 11.2619V17C17 18.6569 15.6569 20 14 20H3C1.34314 20 0 18.6569 0 17V6C0 4.34315 1.34314 3 3 3H9.33331C9.88562 3 10.3333 3.44772 10.3333 4C10.3333 4.55228 9.88562 5 9.33331 5H3Z"
        fill="#6F767E"
      />
    </svg>
  );
};
export default RightUpArrow;
