import { useLocation } from "react-router-dom";
import Footer from "../../molecules/Footer";
import Navbar from "../../molecules/Navbar";

import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
// import "../../../assets/styles/rsuite.min.css";
import { getData } from "../../../services";
import BannerImage from "../../atoms/BannerImage";
import FormHeading from "../../atoms/FormHeading";
import TxtFileRenderer from "../../pages/dashboard/Library/TxtFileRenderer";
import ShimmerBlog from "../../pages/dashboard/Library/shimmer/shimmerBlog";
import "./sharedBlog.scss";
import "./styles.scss";

const SharedBlog = ({ module }) => {
  // Extracting Details from the url
  const [cookies] = useCookies(["t"]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const blog_id = params.get("id");
  const id = params.get("link_id");
  const [loadingDataValue, setLoadingDataValue] = useState(false);

  const [about, setAbout] = useState();
  const [disclaimer, setDisclaimer] = useState();

  const getDataContent = async (id) => {
    const res = await getData({
      endpoint: "Settings/getPage",
      params: { id: id },
      token: cookies.t,
    });
    return res; // Return only the text content
  };

  useEffect(() => {
    const fetchData = async () => {
      const aboutData = await getDataContent(4);
      // debugger;
      const disclaimerData = await getDataContent(5);
      // console.log('ab',aboutData)
      setAbout(aboutData[0]?.text);
      // console.log('dd', disclaimerData)
      setDisclaimer(disclaimerData[0]?.text);
      // debugger;
    };
    fetchData();
  }, [cookies.t]);

  //   handling States
  const [isExpired, setIsExpired] = useState(false);
  const [blogData, setBlogData] = useState();

  const getBlogDetail = async () => {
    setLoadingDataValue(true);
    const api =
      module === "Resource"
        ? "Blog/getResourcesBlogDetails"
        : "Blog/getLibraryDetails";

    const res = await getData({
      endpoint: api,
      params: { blog_id: blog_id },
      token: cookies.t,
    });

    // console.log("res", res);
    setLoadingDataValue(false);
    setBlogData(res);
  };

  const checkExpiry = async () => {
    const res = await getData({
      endpoint: "ContentSharing/checkLinkExpiry",
      params: {
        link_id: id,
      },
    });

    if (res.response === 200) {
      //   console.log("called");
      setIsExpired(false);
      getBlogDetail();
    } else {
      setIsExpired(true);
    }
  };

  useEffect(() => {
    checkExpiry();
  }, []);
  return (
    <>
      <Navbar />
      <div className="shared-blog">
        {isExpired ? (
          <div className="shared-expired">
            <FormHeading
              title="Link Expired"
              description="This link has already been expired or Revoked"
              type="center"
            />
          </div>
        ) : (
          <div className="shared-blog-container">
            <div className="blog-content" style={{ padding: "6% 10%" }}>
              {loadingDataValue ? (
                <ShimmerBlog isShared={true} />
              ) : (
                <>
                  <div>
                    <h1 className="h1-j">{blogData?.header}</h1>
                    <div className="d-flex align-items-center mb-large">
                      <p className="p16 mb-0 p-dark-light light">
                        By {blogData?.company_name || "PrimeAlpha"}
                      </p>

                      <div className="dot-dark"></div>
                      <p className="p16 mb-0 p-dark-light light">
                        {moment
                          .utc(blogData?.date || blogData?.modified_date)
                          .format("MMM D, YYYY")}
                      </p>
                    </div>
                  </div>

                  {blogData?.cover_photo && (
                    <BannerImage
                      imageUrl={blogData?.cover_photo}
                      altText="cover"
                      type="cover"
                    />
                  )}

                  <div className="content mt-large rich-text-editor-container">
                    <div className={`quill shared_blog`}>
                      {blogData?.text && (
                        <TxtFileRenderer
                          fileUrl={blogData?.text}
                          type="shared"
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="fixed-data">
              {about && (
                <>
                  <h3 className="h4-i">About PrimeAlpha </h3>
                  <div className="quill quill-change">
                    <div
                      className="ql-container ql-snow ql-no-border"
                      style={{ background: "white" }}
                    >
                      <div
                        className="ql-editor blog-content shared-no-border"
                        dangerouslySetInnerHTML={{ __html: about }}
                      ></div>
                    </div>
                  </div>
                </>
              )}

              {/* <p
                className="p-dark-light"
                dangerouslySetInnerHTML={{ __html: about }}
              ></p> */}
              {disclaimer && (
                <>
                  <h3 className="h4-i mt-4">​Important Disclaimers </h3>
                  <div className="quill quill-change">
                    <div
                      className="ql-container ql-snow ql-no-border"
                      style={{ background: "white" }}
                    >
                      <div
                        className="ql-editor blog-content shared-no-border"
                        dangerouslySetInnerHTML={{ __html: disclaimer }}
                        style={{ border: "none" }}
                      ></div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      <Footer />
    </>
  );
};

export default SharedBlog;
