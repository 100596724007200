import QuillEditor from "../../../../../molecules/QuillEditor/QuillEditor";

const InvestmentProcess = ({ setFieldValue, values }) => (
  <>
    <QuillEditor
      label="Idea Generation"
      placeholderText="Enter Idea Generation"
      onChange={(content) => {
        setFieldValue("add_investement_process.idea_generation", content);
      }}
      type=""
      hp="80%"
      height="300px"
      value={values.add_investement_process.idea_generation}
    />
    <QuillEditor
      label="Analysis"
      placeholderText="Enter Analysis"
      onChange={(content) => {
        setFieldValue("add_investement_process.analysis", content);
      }}
      type=""
      hp="80%"
      height="300px"
      value={values.add_investement_process.analysis}
    />
    <QuillEditor
      label="Portfolio Management"
      placeholderText="Enter Portfolio Management"
      onChange={(content) => {
        setFieldValue("add_investement_process.portfolio_management", content);
      }}
      type=""
      hp="80%"
      height="300px"
      value={values.add_investement_process.portfolio_management}
    />
    <QuillEditor
      label="Risk Management"
      placeholderText="Enter Risk Management"
      onChange={(content) => {
        setFieldValue("add_investement_process.risk_management", content);
      }}
      type=""
      hp="80%"
      height="300px"
      value={values.add_investement_process.risk_management}
    />
    <QuillEditor
      label="Key Main Clause"
      placeholderText="Enter Key Main Clause"
      onChange={(content) => {
        setFieldValue("add_investement_process.key_man_clause", content);
      }}
      type=""
      hp="80%"
      height="300px"
      value={values.add_investement_process.key_man_clause}
    />
    <QuillEditor
      label="Notes"
      placeholderText="Enter notes"
      onChange={(content) => {
        setFieldValue("add_investement_process.notes", content);
      }}
      type=""
      hp="80%"
      height="300px"
      value={values.add_investement_process.notes}
    />
  </>
);

export default InvestmentProcess;
