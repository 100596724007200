const RoadShowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="20"
      viewBox="0 0 10 20"
      fill="none"
    >
      <path
        d="M9.58763 19.1141C9.7183 18.9845 9.82201 18.8303 9.89278 18.6604C9.96356 18.4905 10 18.3083 10 18.1243C10 17.9402 9.96356 17.758 9.89278 17.5881C9.82201 17.4182 9.7183 17.2641 9.58763 17.1345L3.20272 10.7495C3.07205 10.6199 2.96834 10.4657 2.89756 10.2959C2.82679 10.126 2.79035 9.94376 2.79035 9.75973C2.79035 9.57569 2.82679 9.39348 2.89756 9.22359C2.96834 9.05371 3.07205 8.89952 3.20272 8.76992L9.58763 2.385C9.7183 2.2554 9.82201 2.10121 9.89279 1.93133C9.96356 1.76145 10 1.57923 10 1.39519C10 1.21116 9.96356 1.02894 9.89279 0.85906C9.82201 0.689177 9.7183 0.534989 9.58763 0.405391C9.32643 0.14574 8.9731 -4.48873e-08 8.6048 -6.09861e-08C8.2365 -7.70849e-08 7.88317 0.14574 7.62197 0.40539L1.22312 6.80426C0.439915 7.58844 -3.78166e-07 8.65142 -4.26611e-07 9.75973C-4.75057e-07 10.868 0.439915 11.931 1.22312 12.7152L7.62197 19.1141C7.88317 19.3737 8.2365 19.5195 8.6048 19.5195C8.9731 19.5195 9.32643 19.3737 9.58763 19.1141Z"
        fill="#1A1D1F"
      />
    </svg>
  );
};
export default RoadShowIcon;
