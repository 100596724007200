import { useEffect, useState } from "react";

function TxtFileRenderer({ fileUrl, type = "normal" }) {
  const [fileContent, setFileContent] = useState("");

  useEffect(() => {
    // Fetch the content of the .txt file from the URL
    fetch(fileUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((text) => {
        setFileContent(text);
      })
      .catch((error) => console.error("Error fetching file:", error));
  }, [fileUrl]);

  return (
    <>
      {type === "normal" ? (
        <div
          className="ql-container ql-snow ql-no-border"
          style={{ background: "white" }}
        >
          <div className="ql-editor blog-content shared_blog mb-3 p-0">
            <div dangerouslySetInnerHTML={{ __html: fileContent }} />
          </div>
        </div>
      ) : (
        <div
          className="ql-container ql-snow"
          style={{ background: "white", border: "none" }}
        >
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{ __html: fileContent }}
            style={{ padding: 0 }}
          ></div>
        </div>
      )}
    </>
  );
}

export default TxtFileRenderer;
