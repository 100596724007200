import { IconButton } from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useDelete } from "../../../../../../hooks/useDelete";
import { getData } from "../../../../../../services";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import Add from "../../../../../Icons/Add";
import Building from "../../../../../Icons/Building";
import EditBlue from "../../../../../Icons/EditBlue";
import View from "../../../../../Icons/View";
import WorkIcon from "../../../../../Icons/Work";
import IconCircle from "../../../../../atoms/IconCircle";
import DeleteModal from "../../../../../molecules/Modal/DeleteModal";
import NoContentCard from "../../../../../molecules/NoContentCard";
import Pagination from "../../../../../molecules/Pagination";
const Work = ({ token, type }) => {
  const dispatch = useDispatch();
  const { id, added_id, people_id } = useParams();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Initial items per page
  const [workHistory, setWorkHistory] = useState();
  const [modalState, setModalState] = useState({
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
  });

  const fetchWorkHistory = useCallback(async () => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "People/getAllPeopleWorkDetail",
      params: {
        page: currentPage,
        page_limit: itemsPerPage,
        people_id: id || added_id,
      },
      token: token,
    });
    dispatch(setLoading(false));
    setWorkHistory(res);
  }, [currentPage, itemsPerPage, id, token]);

  useEffect(() => {
    fetchWorkHistory();
  }, [fetchWorkHistory]);

  const deleteWork = useDelete();

  const handleDelete = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.id,
      name: data?.company_name || "",
    }));
  };

  const handleDeletion = async () => {
    try {
      await deleteWork("People/deletePeopleWorkDetail", "Work History", {
        work_id: modalState.modalId,
      });
      fetchWorkHistory();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  //  Pagination management
  const totalItems = workHistory?.total_record;
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  return (
    <>
      {modalState.isDeleteModalOpen && (
        <DeleteModal
          name={modalState.name}
          id={modalState.modalId}
          isActive={modalState.isDeleteModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="Work History"
          onClick={handleDeletion}
        />
      )}
      {workHistory?.data?.length ? (
        <>
          <div className="keyLinkWrap">
            <h4 className="mb-2 fw-bolder">Work History</h4>
            {type !== "view" && (
              <button
                className={`btn btn-primary px-4 mt-0 d-flex`}
                style={{ gap: "5px" }}
                onClick={() =>
                  navigate(
                    `/dashboard/people/edit/add-work-detail/${id || added_id}`
                  )
                }
              >
                <i className="d-flex">
                  <Add />
                </i>
                Add Work
              </button>
            )}
          </div>
          {workHistory?.data?.map((work, index) => (
            <>
              <div key={index} className="AddWork-info-div">
                <div className="work-building-wrap">
                  <IconCircle
                    color="#F4F4F4"
                    icon={
                      <i className="">
                        <Building />
                      </i>
                    }
                    size="54px"
                    iconSize="20px"
                    iconColor="#000000"
                  />
                  <div>
                    <span className="work-title">{work.title}</span>
                    <div className="">
                      <span className="work-company-name company-name-email">
                        {work.company_name}
                      </span>
                      {work.email && (
                        <span
                          className="emailDot"
                          style={{ backgroundColor: "#000000" }}
                        ></span>
                      )}

                      <span className="work-email company-name-email email-name">
                        {work.email}
                      </span>
                    </div>
                    <div>
                      <span className="work-start-date">
                        {work?.start_date &&
                          moment(work.start_date).format("MMM D, YYYY")}{" "}
                        {work?.start_date && work?.end_date && <>-&nbsp;</>}
                        {work?.currently_working_here
                          ? "Present"
                          : work?.end_date &&
                            moment.utc(work?.end_date).format("MMM D, YYYY")}
                      </span>
                    </div>
                  </div>
                </div>

                {type != "view" && (
                  <div className="work-details">
                    <div className="keyLink-edit-remove">
                      <IconButton
                        type="button"
                        onClick={() => {
                          navigate(
                            `/dashboard/people/edit/view-work-detail/${work?.id}`
                          );
                        }}
                      >
                        <i className="">
                          <View />
                        </i>
                      </IconButton>
                      <IconButton
                        type="button"
                        onClick={() =>
                          navigate(
                            `/dashboard/people/edit/edit-work-detail/${work?.id}`
                          )
                        }
                      >
                        <i>
                          <EditBlue className="edit-blue-icon" />
                        </i>
                      </IconButton>
                      {/* <IconButton
                        type="button"
                        onClick={() => handleDelete(work)}
                      >
                        <i className="fi fi-rr-trash actions-icon-custom"></i>
                      </IconButton> */}
                    </div>
                  </div>
                )}
              </div>
            </>
          ))}
          {totalItems >= 10 && (
            <div className="mb-4">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            </div>
          )}
        </>
      ) : type === "view" ? (
        <NoContentCard
          small={true}
          title="No Work Details added yet"
          icon={<WorkIcon />}
        />
      ) : (
        <NoContentCard
          small={true}
          title="No Work Details added yet"
          subtitle="To add Work, click on the add work button."
          icon={<WorkIcon />}
          handleClick={() => {
            navigate(
              `/dashboard/people/edit/add-work-detail/${id || id || added_id}`
            );
          }}
          tag="Add Work"
        />
      )}
    </>
  );
};

export default Work;
