import "./styles.scss";
const ContentSharingShimmer = () => {
  return (
    <div className="content-sharing-shimmer-conatiner">
      {Array(10)
        .fill()
        .map((_, index) => (
          <>
            <div className="content-sharing-shimmer-item">
              <div className="content-sharing-shimmer-item-single"></div>
              <div className="content-sharing-shimmer-item-single"></div>
              <div className="content-sharing-shimmer-item-single"></div>
              <div className="content-sharing-shimmer-item-single"></div>
              <div className="content-sharing-shimmer-item-single"></div>
              <div className="content-sharing-shimmer-item-single"></div>
            </div>
            <div className="shimmer-items-border-wrapper"></div>
          </>
        ))}
    </div>
  );
};
export default ContentSharingShimmer;
