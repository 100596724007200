import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import AsyncSelect from "react-select/async";
import { customDropdownStyles } from "../../../assets/styles/dropdown";
import {
  GeoAutoCompleteAddress,
  GeoAutoCompleteApi,
} from "../../../services/googleLocationSelectHelpers";

const GoogleSelect = ({
  onChange,
  disabled,
  state,
  selected,
  type = "name",
  placeholder,
  classes,
  dropdownType,
}) => {
  const [cookies] = useCookies(["t"]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (selected) {
      const defaultOption = {
        label: selected,
        value: {
          city: selected,
          address: selected,
          latitude: null,
          longitude: null,
        },
      };
      setSelectedOption(defaultOption);
      // You may want to trigger the onChange event with the default value if needed
      // if (onChange) {
      //   onChange(defaultOption);
      // }
    }
  }, [selected]);
  // const [options, setOptions] = useState([]);

  // let debouncedApiCall;

  // debouncedApiCall = _.debounce(async (inputValue) => {
  //   try {
  //     const tempInputValue = {
  //       inputValue: inputValue,
  //       stateName: state,
  //     };

  //     let res;

  //     if (dropdownType === "city") {
  //       res = await GeoAutoCompleteApi(tempInputValue, cookies.t);
  //     } else {
  //       res = await GeoAutoCompleteAddress(tempInputValue, cookies.t);
  //     }
  //     const options = res?.map((prediction) => ({
  //       value: {
  //         name: prediction?.name,
  //         address: prediction?.address,
  //         longitude: prediction?.longitude,
  //         latitude: prediction?.latitude,
  //       },
  //       label:
  //         type === "name"
  //           ? prediction?.name
  //           : prediction?.name + "" + prediction?.address,
  //     }));
  //     // console.log("res", res);

  //     // console.log("options", options);

  //     return options;
  //   } catch (error) {
  //     console.error("Error fetching Google API:", error);
  //     return [];
  //   }
  // }, 900);

  const loadOptions = async (inputValue, callback) => {
    try {
      if (inputValue.length >= 2) {
        const tempInputValue = {
          inputValue: inputValue,
          stateName: state,
        };

        let res;

        if (dropdownType === "city") {
          res = await GeoAutoCompleteApi(tempInputValue, cookies.t);
        } else {
          res = await GeoAutoCompleteAddress(tempInputValue, cookies.t);
        }

        const options = res?.map((prediction) => ({
          value: {
            name: prediction?.name,
            address: prediction?.address,
            longitude: prediction?.longitude,
            latitude: prediction?.latitude,
          },
          label:
            type === "name"
              ? prediction?.name
              : prediction?.name + "" + prediction?.address,
        }));

        // const options = await debouncedApiCall(inputValue);

        // console.log("debounced", options);
        callback(options);
      }

      return [];
    } catch (error) {
      console.error("Error fetching options:", error);
      return [];
    }
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    // console.log("selected", selectedOption);

    if (onChange) {
      onChange(selectedOption);
    }
  };

  return (
    <AsyncSelect
      className={`basic-single ${classes}`}
      isAsync
      isSearchable
      cacheOptions
      styles={customDropdownStyles}
      loadOptions={loadOptions}
      onChange={handleSelectChange}
      value={selectedOption}
      classNamePrefix="select"
      // options={options}
      isDisabled={disabled}
      placeholder={placeholder}
    />
  );
};

export default GoogleSelect;
