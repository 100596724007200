import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthenticated } from "../hooks/useAuthenticated";
import { fetchUserData } from "../store/slices/peopleSlice";

interface AuthGuardProps {
  children: ReactNode;
  allowed?: any;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children, allowed } = props;
  const auth = useAuthenticated();
  const location = useLocation();
  const [cookies] = useCookies(["cuid"]);
  const dispatch: any = useDispatch();
  const userInfo = useSelector((state: any) => state.peopleData?.peopleData);

  const conditions: any = {
    resource: {
      condition: "resource",
      otherwise: userInfo?.investor_learn_more_link,
    },
    investment_library_education: {
      condition: "investment_library_education",
      otherwise: userInfo?.product_learn_more_link,
    },
    investor_database: {
      condition: "investor_database",
      otherwise: userInfo?.investor_learn_more_link,
    },
    manager_database: {
      condition: "manager_database",
      otherwise: userInfo?.product_learn_more_link,
    },
    is_admin: {
      condition: "is_admin",
      otherwise: "/",
    },
  };

  const [requestedLocation, setRequestedLocation] = useState<any>();
  localStorage.setItem(
    "emailrequest",
    location?.pathname === "/dashboard/requests"
      ? "email"
      : location?.pathname === "/dashboard/requests/Sent"
      ? "emailsent"
      : ""
  );

  const accessConditionKey = Object.keys(conditions).find((key) =>
    allowed?.includes(key)
  );

  const accessCondition = accessConditionKey
    ? conditions[accessConditionKey]
    : null;

  useEffect(() => {
    dispatch(fetchUserData(cookies.cuid));
  }, [dispatch, cookies.cuid, accessConditionKey]);

  if (!auth) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Navigate to="/auth" />;
  }

  if (allowed?.length) {
    if (userInfo?.has_master_access || userInfo[accessCondition?.condition]) {
      return <>{children}</>;
    } else {
      window.location.href = accessCondition?.otherwise;
    }
  } else {
    if (requestedLocation && location.pathname !== requestedLocation) {
      setRequestedLocation(null);
      return <Navigate to={requestedLocation} />;
    }
  }
  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
