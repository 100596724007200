import moment from "moment";
import { useState } from "react";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../assets/styles/toast";
import Copy from "../../../Icons/Copy";
import TypeDiv from "../../../atoms/TypeDiv";
import "./contentSharing.scss";

const LabelValueDisplay = ({ label, children }) => {
  return (
    <div className="view-info-container">
      <div className="label">{label}</div>
      <div className="data">
        <div className="colon">:</div>
        {children}
      </div>
    </div>
  );
};

const SharedView = ({ data, onClose, type, onUpdate }) => {
  const [isCopy, setIsCopy] = useState(false);

  const handleCopyUrl = () => {
    var text = document?.getElementById("primealphasharedlink")?.innerHTML;

    // Decode HTML entities
    const tempElement = document.createElement("div");
    tempElement.innerHTML = text;
    text = tempElement.textContent;

    // console.log("text", text);
    navigator?.clipboard?.writeText(text);
    setIsCopy(true);
    toast.success("Link Copied to clipboard.", {
      style: confimationStyles,
      duration: 1000,
    });
  };

  return (
    <div className="request-info-container">
      <LabelValueDisplay label="Module">{data?.module}</LabelValueDisplay>
      <LabelValueDisplay label="Type">{data?.type}</LabelValueDisplay>
      {data?.name && (
        <LabelValueDisplay label="Item Shared">{data?.name}</LabelValueDisplay>
      )}
      {data?.email && (
        <LabelValueDisplay label="Email">{data?.email}</LabelValueDisplay>
      )}

      {data?.company_name && (
        <LabelValueDisplay label="Company Name">
          {data?.company_name}
        </LabelValueDisplay>
      )}

      <LabelValueDisplay label="Link">
        <div className="link-ellipse-container-wrapper">
          <div className="link-ellipse-container" id="primealphasharedlink">
            {data?.link}
          </div>
          <div onClick={handleCopyUrl} className="cursor-pointer-conatiner">
            <Copy />
          </div>
        </div>
      </LabelValueDisplay>
      <LabelValueDisplay label="Status">
        <TypeDiv
          data={data?.status}
          color={data?.status === "Revoked" ? "#FFB7B7" : "#B5E4CA"}
        />
      </LabelValueDisplay>
      <LabelValueDisplay label="Shared Date">
        {moment(data?.data).format("MMM D, YYYY hh:mm a")}
      </LabelValueDisplay>
      <LabelValueDisplay label="Expiry Date">
        {moment(data?.expiry).format("MMM D, YYYY hh:mm a")}
      </LabelValueDisplay>

      {/* <div className="d-flex justify-content-end">
        <button
          className="btn btn-primary w-30"
          type="submit"
          onClick={() => handleUpdate()}
        >
          Revoke
        </button>
      </div> */}
    </div>
  );
};

export default SharedView;
