const Direction = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15.76 7.44L8.56 0.24C8.24003 -0.08 7.76 -0.08 7.44 0.24L0.24 7.44C-0.08 7.76 -0.08 8.24003 0.24 8.56L7.44 15.76C7.76 16.08 8.24003 16.08 8.56 15.76L15.76 8.56C16.08 8.24003 16.08 7.76003 15.76 7.44ZM9.6 10V8.00003H6.4V10.4H4.8V7.20003C4.8 6.72003 5.12 6.40003 5.6 6.40003H9.6V4.40003L12.4 7.20003L9.6 10Z"
        fill="#1A1D1F"
      />
    </svg>
  );
};
export default Direction;
