import { useEffect, useRef, useState } from "react";
import FilterIcon from "../../Icons/FilterIcon";
import CustomButton from "../../atoms/CustomeButton";
import { DateSelector } from "../../molecules/MonthYearSelector/index";
import CloseBtnIcom from "../Modal/CloseBtnIcon";
const FilterComponent = ({
  onFilterChange,
  filterData: propsFilterData,
  isDateRange = false,
  headingMobile,
  headingText = "Filters",
}) => {
  const containerRef = useRef(null);
  const dropdownRef = useRef(null);

  const filterData = propsFilterData || {};

  const updatedFilterData = isDateRange
    ? { ...filterData, "Date Range": ["startDate", "endDate"] }
    : filterData;

  // console.log("updatedFilter", updatedFilterData);

  const [isOpen, setIsOpen] = useState(false);
  // Add this state
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [selectedKey, setSelectedKey] = useState(); // Initialize with the first key
  const [selectedOptionsMap, setSelectedOptionsMap] = useState({});

  useEffect(() => {
    const keys = Object.keys(filterData);
    setSelectedKey(keys[0]);
  }, [filterData]);

  const toggleFilterBox = () => {
    setIsOpen(!isOpen);
  };

  const handleKeyClick = (key) => {
    // console.log("key", key);
    setSelectedKey(key);
  };

  const handleOptionChange = (option) => {
    setSelectedOptionsMap((prevOptionsMap) => {
      const updatedOptionsMap = { ...prevOptionsMap };

      if (!updatedOptionsMap[selectedKey]) {
        updatedOptionsMap[selectedKey] = [];
      }

      if (updatedOptionsMap[selectedKey].includes(option)) {
        updatedOptionsMap[selectedKey] = updatedOptionsMap[selectedKey].filter(
          (prevOption) => prevOption !== option
        );
      } else {
        updatedOptionsMap[selectedKey] = [
          ...updatedOptionsMap[selectedKey],
          option,
        ];
      }

      return updatedOptionsMap;
    });
  };

  useEffect(() => {
    if (!isDateRange) {
      const handleClickOutside = (event) => {
        // Close the filter box if clicked outside
        if (
          isOpen &&
          containerRef.current &&
          !containerRef.current.contains(event.target) &&
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      };

      // Add the event listener to the document body
      document.addEventListener("click", handleClickOutside);

      // Cleanup the event listener on component unmount
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }
  }, [isOpen]);

  // const handleApply = () => {
  //   onFilterChange(selectedOptionsMap);
  //   setIsOpen(false);
  // };

  const handleApply = () => {
    // Include start date and end date in the callback
    const filterValues = {
      ...selectedOptionsMap,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    };

    onFilterChange(filterValues);
    setIsOpen(false);
  };

  const handleReset = () => {
    setSelectedOptionsMap({});
    setDateRange({ startDate: null, endDate: null });
    setIsOpen(false);
    onFilterChange({});
  };

  return (
    <div
      className={
        headingMobile
          ? "filter-container filter-mobile-container"
          : "filter-container"
      }
      ref={containerRef}
    >
      <CustomButton
        handleClick={toggleFilterBox}
        icon={<FilterIcon />}
        text={headingMobile ? "" : headingText}
        classes={headingMobile ? "filter-mobile-container-btn" : ""}
      />

      {isOpen && (
        <div className="filter-box" ref={dropdownRef}>
          <div className="filter-header">
            <p className="header">Filters</p>

            <span
              className="header"
              style={{ cursor: "pointer" }}
              onClick={toggleFilterBox}
            >
              <i className="d-flex">
                <CloseBtnIcom />
              </i>
            </span>
          </div>
          <div className="filter-data-box">
            <div className="filter-key-box">
              {Object.keys(updatedFilterData).map((key) => (
                <div
                  key={key}
                  onClick={() => handleKeyClick(key)}
                  className={`filter-key ${
                    selectedKey === key ? "active-key" : ""
                  }`}
                >
                  {key}
                </div>
              ))}
            </div>

            <div className="filters-column">
              {selectedKey === "Date Range" ? (
                <div>
                  <div>
                    <label htmlFor="startDate">Start Date</label>
                    <DateSelector
                      placeholder="Start Date"
                      onChange={(date) =>
                        setDateRange((prev) => ({ ...prev, startDate: date }))
                      }
                      selectedDate={dateRange.startDate}
                    />
                  </div>

                  <div className="mt-3">
                    <label htmlFor="endDate">End Date</label>
                    <DateSelector
                      placeholder="End Date"
                      onChange={(date) =>
                        setDateRange((prev) => ({ ...prev, endDate: date }))
                      }
                      selectedDate={dateRange.endDate}
                    />
                  </div>
                </div>
              ) : (
                <>
                  {Object.keys(updatedFilterData)?.map((key) => (
                    <div
                      key={key}
                      style={{
                        display: selectedKey === key ? "block" : "none",
                      }}
                    >
                      {updatedFilterData[key].map((option) => (
                        <>
                          <div key={option} className="mb-1">
                            <label className="check-container" htmlFor={option}>
                              {option}
                              <input
                                type="checkbox"
                                id={option}
                                checked={
                                  selectedOptionsMap[selectedKey] &&
                                  selectedOptionsMap[selectedKey].includes(
                                    option
                                  )
                                }
                                onChange={() => handleOptionChange(option)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </>
                      ))}
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          <hr className="my-0" />
          <div className="d-flex justify-content-end gap-3 p-3">
            <button
              className="btn btn-outline-dark mt-0"
              onClick={() => handleReset()}
            >
              Reset
            </button>
            <button
              className="btn btn-primary mt-0"
              onClick={() => handleApply()}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterComponent;
