import { Field, FieldArray, Form, Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { statusOptions } from "../../../../../../helpers/staticData";
import { getData } from "../../../../../../services";
import {
  getGeography,
  getPeopleByCompany,
  getSector,
  getStrategyById,
  getStructureById,
  getSubStrategyById,
  getTypeById,
} from "../../../../../../services/editHelper";
import {
  addProductConfiguration,
  editProductConfiguration,
} from "../../../../../../services/productHelper";
import SelectDropdown from "../../../../../atoms/Dropdown";
import Error from "../../../../../atoms/Error";
import InputField from "../../../../../atoms/InputField";
import InputLayout from "../../../../../atoms/InputLayout";
import { MonthYearSelector } from "../../../../../molecules/MonthYearSelector";

import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/toast";
import { getCompanyDetail } from "../../../../../../services/productHelper";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import CheckboxWrapper from "../../../../../atoms/CheckBox";
import QuillEditor from "../../../../../molecules/QuillEditor/QuillEditor";

const ProductConfiguration = ({ page_type, onTabChange }) => {
  const dispatch = useDispatch();
  const { company_id, id } = useParams();
  const navigate = useNavigate();
  const [cookies] = useCookies(["t"]);

  // States -----------------------------------------------
  const [categoryData, setCategoryData] = useState();
  const [structure, setStructure] = useState([]);
  const [type, setType] = useState([]);
  const [strategy, setStrategy] = useState([]);
  const [subStrategies, setSubStrategies] = useState([]);
  const [geography, setGeography] = useState(null);
  const [sector, setSector] = useState(null);
  const [companyData, setCompanyData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [peopleData, setPeopleData] = useState([]);
  // --------------------------------------------------------

  //  Fetching pre required data ------------------------------
  const handleApiCall = async (id) => {
    dispatch(setLoading(true));
    const response = await getData({
      endpoint: "Product/getProductDetails",
      params: { product_id: id },
      token: cookies.t,
    });
    dispatch(setLoading(false));
    setProductData(response[0]);
  };

  const getCategoryData = async () => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "Taxonomy/getallProductCategory",
      params: {},
      token: cookies.t,
    });
    dispatch(setLoading(false));
    const data = res.data;

    const formattedCategoryData = data?.map((category) => {
      return {
        value: category?.category_id,
        label: category?.category,
      };
    });

    setCategoryData(formattedCategoryData);
  };

  const getCompanyDetails = async (id) => {
    dispatch(setLoading(true));
    const response = await getCompanyDetail(id, cookies.t);
    dispatch(setLoading(false));
    setCompanyData(response);
  };

  useEffect(() => {
    handleApiCall(id);
    getCategoryData();
    if (company_id) {
      getCompanyDetails(company_id);
    }
  }, []);

  useEffect(() => {
    fetchRequiredData(productData);
  }, [productData]);

  const fetchSubStrategy = async (option, index) => {
    const data = await getSubStrategyById(option);

    setSubStrategies((prevSubStrategies) => {
      const updatedSubStrategies = [...prevSubStrategies];
      updatedSubStrategies[index] = data;
      return updatedSubStrategies;
    });
  };

  const fetchRequiredData = async (productData) => {
    if (productData?.category) {
      const stdata = await getStructureById(productData?.category);
      setStructure(stdata);
    }

    if (productData?.structure) {
      const tdata = await getTypeById(productData?.structure);
      setType(tdata);
    }

    if (productData?.type) {
      const stData = await getStrategyById(productData?.type);
      setStrategy(stData);
    }

    if (productData?.company_id) {
      const pData = await getPeopleByCompany(productData?.company_id);
      setPeopleData(pData);
    }

    if (company_id) {
      const pData = await getPeopleByCompany(company_id);
      setPeopleData(pData);
    }

    const gdata = await getGeography();
    setGeography(gdata);
    const sdata = await getSector();
    setSector(sdata);

    if (productData?.strategy) {
      await Promise.all(
        productData?.strategy?.map(async (value, index) => {
          await fetchSubStrategy(value, index);
        })
      );
    }
  };

  // ------------------------------------------------------------

  // Form Data -----------------------------------------------
  const initialValues = {
    id: id,
    company_name: productData?.company_name || companyData?.name || "",
    company_id: productData?.company_id || company_id || null,
    name: productData?.name || "",
    product_category: productData?.category || "",
    product_category_name: productData?.category || "",
    product_structure: productData?.structure || "",
    product_type: productData?.type || "",
    product_geography: productData?.geography || "",
    product_sector: productData?.sectors || "",
    status: productData?.status || "",
    main_contact_person: productData?.main_contact_person?.id || null,
    inspection: productData?.inception || null,
    aum_amount: productData?.aum_amount || null,
    aum_month_year: productData?.aum_month_year || null,
    is_prelaunch: productData?.pre_launch || false,
    description: productData?.description || "",
    is_not_applicable: productData?.not_applicable || false,
    user_role: "Customer",
    product_strategy_sub_strategy: (productData?.strategy?.length > 0
      ? productData.strategy
      : [null]
    ) // Ensure at least one entry
      .map((strategyValue) => ({
        product_strategy: strategyValue || null,
        product_sub_strategy: productData?.sub_strategy || [null],
      })),
  };

  const validationSchema = yup.object().shape({
    // id: yup.string().required("ID is required"),
    // company_name: yup.string().required("Company name is required"),
    name: yup.string().required("Name is required"),
    product_category: yup.string().required("Product category is required"),
    product_structure: yup.string().required("Product structure is required"),
    product_type: yup.string().required("Product type is required"),
    product_geography: yup
      .array()
      .of(yup.string().required("Geography is required"))
      .min(1, "Product geography is required")
      .required("Product geography is required"),
    product_sector: yup
      .array()
      .of(yup.string().required("Sector is required"))
      .min(1, "Product sector is required")
      .required("Product sector is required"),
    inspection: yup.string().nullable(),
    aum_amount: yup.number().when("is_not_applicable", {
      is: false,
      then: () =>
        yup.number().when("is_prelaunch", {
          is: false, // or true, based on your logic
          then: () => yup.number().required("AUM amount is required"),
          otherwise: () => yup.number().nullable(),
        }),
      otherwise: () => yup.number().nullable(),
    }),
    aum_month_year: yup.string().when("is_not_applicable", {
      is: false,
      then: () =>
        yup.string().when("is_prelaunch", {
          is: false, // or true based on your logic
          then: () => yup.string().required("AUM month/year is required"),
          otherwise: () => yup.string().nullable(),
        }),
      otherwise: () => yup.string().nullable(),
    }),
    is_prelaunch: yup.boolean(),
    is_not_applicable: yup.boolean(),
    status: yup.string().required(),
    product_strategy_sub_strategy: yup.array().of(
      yup.object().shape({
        product_strategy: yup.string().nullable().notRequired(),
        product_sub_strategy: yup.array().nullable(),
        // .required("Product Sub-Strategy is required"),
      })
    ),
  });

  const handleEdit = async (values) => {
    let modifiedFormData = JSON.parse(JSON.stringify(values));

    // Extract and flatten the product_sub_strategy arrays, then store unique values in product_sub_strategy array
    modifiedFormData.product_sub_startegy = [
      ...new Set(
        values.product_strategy_sub_strategy
          .flatMap((item) => item.product_sub_strategy)
          .filter((subStrategy) => subStrategy !== null)
      ),
    ];

    // Extract unique values from product_strategy_sub_strategy and store them in product_strategy array
    modifiedFormData.product_strategy = [
      ...new Set(
        values.product_strategy_sub_strategy
          .flatMap((item) => item?.product_strategy)
          .filter((strategy) => strategy !== null && strategy !== undefined) // Filter out null and undefined values
      ),
    ];

    modifiedFormData.aum_amount =
      typeof values.aum_amount !== "number" ? null : values.aum_amount;

    // Remove the product_strategy_sub_strategy array
    delete modifiedFormData.product_strategy_sub_strategy;

    const data = {
      ...modifiedFormData,
      id: id,
    };

    modifiedFormData.aum_amount =
      typeof values.aum_amount !== "number" ? null : values.aum_amount;

    try {
      dispatch(setLoading(true));
      const res = await editProductConfiguration(data, cookies.t);
      if (res.status) {
        dispatch(setLoading(false));
        toast.success("Product Configuration Updated Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
      }
    } catch (error) {
      toast.error("Please try again later.");
    }
  };

  const handleAdd = async (values) => {
    // Create a deep copy of the formData object
    let modifiedFormData = JSON.parse(JSON.stringify(values));

    // Extract and flatten the product_sub_strategy arrays, then store unique values in product_sub_strategy array
    modifiedFormData.product_sub_startegy = [
      ...new Set(
        values.product_strategy_sub_strategy
          .flatMap((item) => item.product_sub_strategy)
          .filter((subStrategy) => subStrategy !== null)
      ),
    ];

    // Extract unique values from product_strategy_sub_strategy and store them in product_strategy array
    modifiedFormData.product_strategy = [
      ...new Set(
        values.product_strategy_sub_strategy
          .flatMap((item) => item?.product_strategy)
          .filter((strategy) => strategy !== null && strategy !== undefined) // Filter out null and undefined values
      ),
    ];

    modifiedFormData.aum_amount =
      typeof values.aum_amount !== "number" ? null : values.aum_amount;

    // Remove the product_strategy_sub_strategy array
    delete modifiedFormData.product_strategy_sub_strategy;

    try {
      dispatch(setLoading(true));
      const response = await addProductConfiguration(
        modifiedFormData,
        cookies.t
      );

      if (response) {
        dispatch(setLoading(false));
        toast.success("Product Configuration Added", {
          duration: 1000,
          style: confimationStyles,
        });

        navigate(
          `/dashboard/product/add/${company_id}/${response?.data?.data?.product_id}`
        );
        onTabChange("Product Overview");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // --------------------------------------------------------------

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleEdit}
      key={111}
    >
      {({
        isValid,
        dirty,
        handleSubmit,
        touched,
        errors,
        values,
        setFieldValue,
        setErrors,
        setFieldError,
        validateForm,
      }) => (
        <Form className="mb-2 px-2">
          <div className="row g-3">
            <InputLayout>
              {/* {type === "edit" ? ( */}
              <InputField
                type="text"
                label="Company Name"
                name="company_name"
                id="company_name"
                labelClassName="required-field"
                placeholder="Enter Company Name"
                value={values.company_name}
                disabled={true}
              />
              {/* ) : (
                <>
                  <label htmlFor="Company Name">Company Name</label>
                  <SelectDropdown
                    data={companyData}
                    onChange={async (option) => {
                      setFieldValue("company_id", option);
                      getPeopleDetails(option);
                    }}
                    // onClear={() => setFieldValue("company_id", "")}
                    placeholder="Select Company"
                    selectedValue={productData?.company_id}
                  />
                </>
              )} */}
            </InputLayout>

            <InputLayout>
              <InputField
                type="text"
                label="Product Name"
                name="name"
                id="name"
                labelClassName="required-field"
                placeholder="Enter Product Name"
                value={values.name}
                onChange={(e) => {
                  setFieldValue("name", e.target.value);
                }}
              />
            </InputLayout>

            <InputLayout>
              <label htmlFor="" className="required-field">
                Product Category
              </label>
              <SelectDropdown
                data={categoryData}
                onChange={async (option) => {
                  setFieldValue("product_category", option);
                  setFieldValue("product_category_name", option.label);
                  setFieldValue("product_structure", null);
                  setFieldValue("product_type", null);
                  setFieldValue("product_strategy", null);
                  setFieldValue("product_s", null);
                  setFieldValue(
                    "product_strategy_sub_strategy",
                    values.product_strategy_sub_strategy.map(() => ({
                      product_strategy: null,
                      product_sub_strategy: [],
                    }))
                  );
                  setStrategy(null);
                  setSubStrategies([]);
                  const data = await getStructureById(option);
                  setStructure(data);
                  const gdata = await getGeography();
                  setGeography(gdata);
                  const sdata = await getSector();
                  setSector(sdata);
                  setFieldError("product_structure", "It is required");
                }}
                placeholder="Select Product Category"
                selectedValue={values?.product_category}
              />
            </InputLayout>
          </div>{" "}
          <div className="row g-3 mt-2">
            <InputLayout>
              <label className="required-field">Product Structure</label>
              <SelectDropdown
                data={structure}
                onChange={async (option) => {
                  setFieldValue("product_structure", option);
                  setFieldValue("product_type", null);
                  setFieldValue(
                    "product_strategy_sub_strategy",
                    values.product_strategy_sub_strategy.map(() => ({
                      product_strategy: null,
                      product_sub_strategy: [],
                    }))
                  );
                  validateForm({
                    ...values,
                    product_structure: option,
                    product_type: null,
                    product_strategy_sub_strategy:
                      values.product_strategy_sub_strategy.map(() => ({
                        product_strategy: null,
                        product_sub_strategy: [],
                      })),
                  });
                  const data = await getTypeById(option);
                  setType(data);
                }}
                // onClear={() => setFieldValue("product_structure", "")}
                placeholder="Select Product Structure"
                selectedValue={values?.product_structure}
              />
            </InputLayout>

            <InputLayout>
              <label className="required-field">Product Type</label>
              <SelectDropdown
                data={type}
                onChange={async (option) => {
                  setFieldValue("product_type", option);
                  const data = await getStrategyById(option);
                  setStrategy(data);
                  setFieldValue(
                    "product_strategy_sub_strategy",
                    values.product_strategy_sub_strategy.map(() => ({
                      product_strategy: null,
                      product_sub_strategy: [],
                    }))
                  );
                }}
                // onClear={() => setFieldValue("product_type", "")}
                selectedValue={values?.product_type}
                placeholder="Select Product Type"
              />
            </InputLayout>

            <InputLayout></InputLayout>
          </div>
          <FieldArray name="product_strategy_sub_strategy">
            {({ push, remove }) => (
              <>
                {values.product_strategy_sub_strategy.map((pair, index) => (
                  <div key={index}>
                    <div className="row g-3 mt-2">
                      <InputLayout>
                        <label htmlFor="">Product Strategy</label>
                        <SelectDropdown
                          data={strategy} // replace with your data source
                          onChange={async (option) => {
                            setFieldValue(
                              `product_strategy_sub_strategy[${index}].product_strategy`,
                              option
                            );
                            fetchSubStrategy(option, index); // Call the function to fetch sub-strategies
                          }}
                          isClearable
                          onClear={() => {
                            setFieldValue(
                              `product_strategy_sub_strategy[${index}]`,
                              {
                                product_strategy: null, // Explicitly set product_strategy to null
                                product_sub_strategy: [], // Reset product_sub_strategy
                              }
                            );
                          }}
                          placeholder="Select Product Strategy"
                          selectedValue={
                            values.product_strategy_sub_strategy[index]
                              .product_strategy
                          }
                        />
                      </InputLayout>

                      <InputLayout>
                        <label htmlFor="">Product Sub Strategy</label>
                        <SelectDropdown
                          data={subStrategies[index] || []} // replace with your data source
                          onChange={(option) =>
                            setFieldValue(
                              `product_strategy_sub_strategy[${index}].product_sub_strategy`,
                              option
                            )
                          }
                          multiSelect={true}
                          placeholder="Select Product Sub-Strategy"
                          selectedValue={
                            values.product_strategy_sub_strategy[index]
                              .product_sub_strategy
                          }
                        />
                      </InputLayout>

                      <InputLayout>
                        <div className="d-flex add-remove-container">
                          {index === 0 && (
                            <div
                              onClick={() =>
                                push({
                                  product_strategy: null,
                                  product_sub_strategy: null,
                                })
                              }
                            >
                              <span className="add-feild">Add</span>
                            </div>
                          )}

                          {index !== 0 && (
                            <div onClick={() => remove(index)}>
                              <span className="remove-feild">Remove</span>
                            </div>
                          )}
                        </div>
                      </InputLayout>
                    </div>
                  </div>
                ))}
              </>
            )}
          </FieldArray>
          {/* <FieldArray name="product_strategy_sub_strategy">
            {({ push, remove }) => (
              <>
                {values.product_strategy_sub_strategy.map((pair, index) => (
                  <div key={index}>
                    <div className="row g-3 mt-2">
                      <InputLayout>
                        <label htmlFor="">Product Strategy</label>
                        <SelectDropdown
                          data={strategy} // replace with your data source
                          onChange={async (option) => {
                            setFieldValue(
                              `product_strategy_sub_strategy[${index}].product_strategy`,
                              option
                            );
                            fetchSubStrategy(option, index); // Call the function to fetch sub-strategies
                          }}
                          placeholder="Select Product Strategy"
                          selectedValue={
                            values.product_strategy_sub_strategy[index]
                              .product_strategy
                          }
                        />
                      </InputLayout>

                      <InputLayout>
                        <label htmlFor="">Product Sub Strategy</label>
                        <SelectDropdown
                          data={subStrategies[index] || []} // replace with your data source
                          onChange={(option) =>
                            setFieldValue(
                              `product_strategy_sub_strategy[${index}].product_sub_strategy`,
                              option
                            )
                          }
                          multiSelect={true}
                          placeholder="Select Product Sub-Strategy"
                          selectedValue={
                            values.product_strategy_sub_strategy[index]
                              .product_sub_strategy
                          }
                        />
                      </InputLayout>

                      <InputLayout>
                        <div className="d-flex add-remove-container">
                          {index ===
                            values.product_strategy_sub_strategy.length - 1 && (
                            <div
                              onClick={() =>
                                push({
                                  product_strategy: null,
                                  product_sub_strategy: null,
                                })
                              }
                            >
                              <span className="add-field">Add</span>
                            </div>
                          )}

                          {index !== 0 && (
                            <div onClick={() => remove(index)}>
                              <span className="remove-field">Remove</span>
                            </div>
                          )}
                        </div>
                      </InputLayout>
                    </div>
                  </div>
                ))}
              </>
            )}
          </FieldArray> */}
          <div className="row g-3 mt-2">
            <InputLayout>
              <label htmlFor="" className="required-field">
                Product Geography
              </label>
              <SelectDropdown
                data={geography}
                onChange={(option) => {
                  setFieldValue("product_geography", option);
                }}
                onClear={() => setFieldValue("product_geography", null)}
                placeholder="Select Geography"
                multiSelect={true}
                selectedValue={productData?.geography}
              />
            </InputLayout>

            <InputLayout>
              <label htmlFor="" className="required-field">
                Product sector
              </label>
              <SelectDropdown
                data={sector}
                placeholder="Select Product Sector"
                onChange={(option) => setFieldValue("product_sector", option)}
                onClear={() => setFieldValue("product_sector", null)}
                multiSelect={true}
                key={2}
                selectedValue={productData?.sectors}
              />
            </InputLayout>

            <InputLayout></InputLayout>
          </div>
          <div className="row g-3 mt-2">
            <InputLayout>
              <label>Main Product Contact</label>
              <SelectDropdown
                placeholder="Select Main Contact at Product"
                data={peopleData}
                onChange={(option) =>
                  setFieldValue("main_contact_person", option)
                }
                selectedValue={productData?.main_contact_person?.id}
              />
            </InputLayout>

            <InputLayout>
              <label>Inception</label>
              <MonthYearSelector
                placeholder="Select inspection month & year"
                onChange={(date) =>
                  setFieldValue(
                    "inspection",
                    moment(`${date}-01`).toISOString()
                  )
                }
                selectedDate={productData?.inception}
              />
            </InputLayout>

            <InputLayout>
              <label className="required-field">Status</label>
              <SelectDropdown
                data={statusOptions}
                onChange={(option) => setFieldValue("status", option)}
                // onClear={() => setFieldValue("status", null)}
                placeholder="Status"
                selectedValue={productData?.status}
              />
            </InputLayout>
          </div>
          <div className="row g-3 mt-2">
            <InputLayout>
              <label className="required-field">AUM ($USD Millions)</label>

              <div className="d-flex justify-content-between align-items-center">
                <div style={{ width: "45%" }}>
                  <Field
                    type="number"
                    name="aum_amount"
                    placeholder="Enter Amount"
                    className={`w-100 ${
                      touched.aum_amount && errors.aum_amount
                        ? "error-input"
                        : ""
                    }`}
                  />
                  {touched.aum_amount && errors.aum_amount && (
                    <Error error={errors.aum_amount} />
                  )}
                </div>

                <div>as of</div>

                {/* Second 40% */}
                <div style={{ width: "40%" }}>
                  <MonthYearSelector
                    placeholder="Month & Year"
                    onChange={(date) =>
                      setFieldValue(
                        "aum_month_year",
                        moment(`${date}-10`).toISOString()
                      )
                    }
                    selectedDate={productData?.aum_month_year}
                  />
                </div>
              </div>
            </InputLayout>

            <div className="col-md py-1 d-flex align-items-center">
              <div className="d-flex w-100 mt-0 mt-md-4 align-items-center">
                <div className="d-flex align-items-center w-40 me-3">
                  <CheckboxWrapper
                    type="checkbox"
                    id="is_not_applicable"
                    value={values.is_not_applicable}
                    onChange={() =>
                      setFieldValue(
                        "is_not_applicable",
                        !values.is_not_applicable
                      )
                    }
                    checked={values.is_not_applicable}
                  />
                  <label
                    htmlFor="checkbox1"
                    className="mb-0"
                    style={{ marginTop: "2px" }}
                  >
                    Not Applicable
                  </label>
                </div>
                <div className="d-flex align-items-center w-40">
                  <CheckboxWrapper
                    type="checkbox"
                    id="pre_launch"
                    value={values.is_prelaunch}
                    onChange={() =>
                      setFieldValue("is_prelaunch", !values.is_prelaunch)
                    }
                    checked={values.is_prelaunch}
                  />
                  <label
                    htmlFor="checkbox2"
                    className="mb-0"
                    style={{ marginTop: "2px" }}
                  >
                    Pre Launch
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md"></div>

            <div className="row g-3 mt-2">
              <div className="form-group mb-2">
                {/* <label htmlFor="description">Description</label> */}
                {/* <Field
                  as="textarea"
                  rows="5"
                  placeholder="Description"
                  name="description"
                  id="description"
                  style={{ width: "100%", resize: "both" }}
                /> */}
                <QuillEditor
                  label="Description"
                  placeholderText="Enter Description"
                  onChange={async (content) => {
                    setFieldValue("description", content);
                  }}
                  hp="80%"
                  type=""
                  height="300px"
                  value={values.description}
                />
              </div>
            </div>
          </div>
          {page_type !== "edit" && (
            <>
              {id ? (
                <>
                  <div className="d-flex justify-content-end mt-2">
                    <button
                      type="button"
                      className="btn btn-primary ms-3"
                      disabled={!isValid}
                      onClick={() => handleEdit(values)}
                    >
                      Update
                    </button>
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-end mt-2">
                  <button
                    type="button"
                    className="btn btn-primary ms-3"
                    disabled={!isValid}
                    onClick={() => handleAdd(values)}
                  >
                    Continue
                  </button>
                </div>
              )}
            </>
          )}
          {page_type === "edit" && (
            <footer className="footer">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className={`btn btn-outline-dark px-4 mt-0 `}
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>

                <button
                  type="button"
                  className="btn btn-primary ms-3"
                  disabled={!isValid}
                  onClick={() => handleEdit(values)}
                >
                  Update
                </button>
              </div>
            </footer>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ProductConfiguration;
