import React, { FC } from "react";
import CloseBtnIcom from "../CloseBtnIcon";
import "./modal.scss";

interface CloseButtonProps {
  onClose: () => void;
}

const CloseButton: FC<CloseButtonProps> = ({ onClose }) => {
  return (
    <div className="close-btn" onClick={onClose}>
      <CloseBtnIcom />
    </div>
  );
};

interface ModalProps {
  id: any;
  title: string;
  isActive: boolean;
  onClose: () => void;
  children: React.ReactNode;
  width?: string;
  overflowStyle?: object;
  bodyStyle?: object;
  type?: string;
  scale?: any;
}

const Modal: FC<ModalProps> = ({
  id,
  title,
  isActive,
  onClose,
  children,
  overflowStyle,
  bodyStyle,
  width = "450px",
  type,
  scale,
}) => {
  // const modalContentRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const handleOverflowChange = () => {
  //     const content = modalContentRef.current;

  //     if (content && content.scrollHeight > content.clientHeight) {
  //       // Content overflows, add the 'scrollable' class
  //       content.classList.add('scrollable');
  //     } else {
  //       // Content does not overflow, remove the 'scrollable' class
  //       content?.classList.remove('scrollable');
  //     }
  //   };

  //   // Listen for changes in overflow
  //   const content = modalContentRef.current;
  //   content?.addEventListener('scroll', handleOverflowChange);

  //   // Check overflow initially
  //   handleOverflowChange();

  //   // Cleanup listener
  //   return () => {
  //     content?.removeEventListener('scroll', handleOverflowChange);
  //   };
  // }, []);

  const styles: React.CSSProperties = {
    width: width,
  };
  // console.log("isActive", !isActive);
  // useEffect(() => {
  //   if (isActive) {
  //     //This is using beacause it is becoming problem in moble after opening modal the scroll bar is scrolling above the modal
  //     document.body.style.position = "fixed";
  //     // document.body.style.top = `-${window.scrollY}px`;
  //   } else {
  //     document.body.style.position = "";
  //   }
  // }, [isActive]);

  return (
    <div
      id={id}
      className={`modal ${isActive ? "active" : ""}`}
      style={{ ...overflowStyle }}
    >
      <div
        style={{
          overflow: type === "visible" ? "visible" : "hidden",
          borderRadius: "30px",
        }}
        className={scale ? "scale-the-modal" : ""}
      >
        <div
          className="modal-normal-content"
          style={{ ...styles, ...overflowStyle }}
        >
          <div className="modal-header">
            <p className="modal-heading mb-0">{title}</p>
            <CloseButton onClose={onClose} />
          </div>
          <div className="modal-normal-body" style={{ ...bodyStyle }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
