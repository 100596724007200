import { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import UserProfileCardRoad from "../../../../../molecules/UserProfileCard/userCardRoadMap";
import style from "./index.module.scss";

const InvestorPeople = ({ company_id, data }) => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const [investmentData, setInvestmentData] = useState();

  return (
    <div className={style.peopleWrap}>
      {data.length > 0 &&
        data?.map((item) => {
          return (
            <div>
              <div className={style.company_team}>
                <UserProfileCardRoad
                  key={item.people_id}
                  profileName={item.first_name}
                  profilePosition={item.title}
                  onClick={() =>
                    window.open(`/dashboard/people/${item.people_id}`, "_blank")
                  }
                  profilePicture={item.profile_photo}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default InvestorPeople;
