import { patchFormData, postFormData } from "../../../../../../services";

export const addCompanyInsight = async (data, token) => {
  try {
    const response = await postFormData({
      endpoint: "Company/addCompanyInsight",
      data: data,
      params: {},
      token: token,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const editInsightDetail = async (data, token) => {
  const res = await patchFormData({
    endpoint: "Company/editCompanyInsight",
    data: data,
    params: {},
    token: token,
  });
  return res;
};
