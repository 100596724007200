import React, { FC } from "react";
import "./type_div.scss";

interface TypeDivProps {
  data?: boolean | string;
  color?: string;
  classes?: string;
  style?: React.CSSProperties;
}

const TypeDiv: FC<TypeDivProps> = ({
  data,
  color: givenColor,
  classes,
  style,
}) => {
  // Define a function to map data values to colors

  const color = givenColor;

  let styles: React.CSSProperties = {
    backgroundColor: color,
    ...style,
  };

  return (
    <div className={`type-div ${classes}`} style={styles}>
      <span>
        {typeof data === "boolean" ? (data ? "Active" : "Inactive") : data}
      </span>
    </div>
  );
};

export default TypeDiv;
