import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import PageHeader from "../../../../components/PageHeader";
import moment from "moment";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { getData } from "../../../../../../services";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import ArrowLeft from "../../../../../Icons/ArrowLeft";
import IconCircle from "../../../../../atoms/IconCircle";
import InputLayout from "../../../../../atoms/InputLayout";
import "./edit.scss";

const View = () => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const { id } = useParams();

  const [educationData, setEducationData] = useState(null);
  const isMounted = useRef(true);

  useEffect(() => {
    if (isMounted.current) {
      const fetchEducationData = async () => {
        dispatch(setLoading(true));
        try {
          const res = await getData({
            endpoint: "People/getPeopleEducationDetailsById",
            params: { id: id },
            token: cookies.t,
          });
          dispatch(setLoading(false));
          setEducationData(res);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchEducationData();
      isMounted.current = false;
    }
  });
  return (
    <div className="education-view">
      <div className="d-flex gap-3 align-items-center mb-3">
        <IconCircle
          color="#ffffff"
          border="1px solid #C0C4C9"
          size="40px"
          icon={
            <i className="d-flex">
              <ArrowLeft />
            </i>
          }
          handleClick={() => navigate(-1)}
          isClickable
        />
        <div className="h1-j">View Education</div>
      </div>
      <div classes="p-4 ">
        <div style={{ minHeight: "300px" }}>
          <div className="row g-3">
            <InputLayout>
              <label htmlFor="" className="mb-xs">
                School/Collage/Institute Name
              </label>
              <p className="p medium">
                {educationData?.institute_name || "--"}
              </p>
            </InputLayout>

            <InputLayout>
              <label htmlFor="" className="mb-xs">
                Degree
              </label>
              <p className="p medium">{educationData?.degree || "--"}</p>
            </InputLayout>

            <InputLayout>
              <label htmlFor="" className="mb-xs">
                Field of Education
              </label>
              <p className="p medium">
                {educationData?.field_of_study || "--"}
              </p>
            </InputLayout>
          </div>

          <div className="row g-3 mt-2">
            <InputLayout>
              <label htmlFor="" className="mb-xs">
                Start Date
              </label>

              <p className="p medium">
                {" "}
                {moment.utc(educationData?.start_date).format("MMM YYYY")}
              </p>
            </InputLayout>

            <InputLayout>
              <label htmlFor="" className="mb-xs">
                End Date
              </label>
              <p className="p medium">
                {" "}
                {moment.utc(educationData?.end_date).format("MMM YYYY")}
              </p>
            </InputLayout>

            <InputLayout></InputLayout>
          </div>

          <div className="row g-3 mt-2">
            <InputLayout>
              <label htmlFor="" className="mb-xs">
                About
              </label>
              <p className="p medium">{educationData?.about || "--"}</p>
            </InputLayout>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
