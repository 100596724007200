import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  customDropdownStyles,
  filterDropdownStyles,
  phoneNumberStyles,
} from "../../../assets/styles/dropdown";
import useScreenWidth from "../../../hooks/useScreenwidth";
import "./styles.scss";
interface Option {
  value: any;
  label: any;
}

interface SelectDropdownProps {
  id?: string;
  multiSelect?: boolean;
  selectedValue?: string | number | string[] | null;
  onChange: (value: string | number | string[]) => void;
  classes?: string;
  placeholder?: string;
  data: Option[];
  format?: string;
  isDisabled?: boolean;
  type?: string;
  isClearable?: boolean;
  onClear?: () => void;
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  id,
  multiSelect = false,
  selectedValue,
  onChange,
  classes,
  placeholder,
  data,
  format,
  isDisabled,
  type,
  isClearable,
  onClear,
}) => {
  const options = data || [];

  const [selectedOptions, setSelectedOptions] = useState<any>(null);
  const screenWidth = useScreenWidth();

  useEffect(() => {
    if (multiSelect && selectedValue) {
      const initialSelectedOptions = options?.filter((option) =>
        (selectedValue as string[])?.includes(option.value)
      );
      setSelectedOptions(initialSelectedOptions);
    } else if (!multiSelect && selectedValue != null) {
      const initialSelectedOption =
        options?.find((option) => option?.value === selectedValue) || null;
      setSelectedOptions(initialSelectedOption);
    } else {
      const defaultSelectedOptions = multiSelect ? [] : null;
      setSelectedOptions(defaultSelectedOptions);
    }
  }, [selectedValue, multiSelect, options]);

  const handleSelectChange = (selectedOptions: any) => {
    setSelectedOptions(selectedOptions);

    if (multiSelect) {
      let selectedValues;
      if (format === "all") {
        selectedValues = (selectedOptions as Option[])?.map((option) => option);
      } else {
        selectedValues = (selectedOptions as Option[])?.map(
          (option) => option?.value
        );
      }
      onChange(selectedValues);
    } else {
      if (!selectedOptions) {
        onClear && onClear();
      }
      if (format === "all") {
        onChange(selectedOptions);
      } else {
        onChange(selectedOptions ? selectedOptions?.value : null);
      }
    }
  };

  return (
    <Select
      className={`basic-single ${classes}`}
      classNamePrefix="select"
      options={options}
      value={selectedOptions}
      isMulti={multiSelect}
      onChange={handleSelectChange}
      styles={
        type === "filter"
          ? filterDropdownStyles
          : type === "phoneNumber"
          ? { ...customDropdownStyles, ...phoneNumberStyles }
          : customDropdownStyles
      }
      key={id}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isClearable={isClearable}
    />
  );
};

export default SelectDropdown;
