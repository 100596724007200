import axios from "../../../../axios";
import {
  getData,
  patchData,
  patchFormData,
  postData,
  postFormData,
} from "../../../../services";

export const getComapnyData = async (params) => {
  if (params?.length > 1) {
    params = ["Investor/Manager/Sponsor"];
  }
  try {
    const response = await axios.get("Company/getCompanyList", {
      params: { types: JSON.stringify(params) },
      // params: { types: params },
    });
    return response?.data;
  } catch (exp) {
    console.log("exp", exp);
  }
};

export const getAllPeopleData = async (
  searchValue,
  page,
  pageLimit,
  filters
) => {
  try {
    const reponse = await axios.get("People/getAllPeople", {
      params: {
        page: page,
        page_limit: pageLimit,
        search_query: searchValue,
        status: JSON.stringify(filters?.status),
        types: JSON.stringify(filters?.types),
        is_admin:
          filters?.Admin?.length >= 2
            ? null
            : filters?.Admin?.[0] === "Yes"
            ? true
            : filters?.Admin?.[0] === "No"
            ? false
            : "",
      },
    });
    return reponse?.data;
  } catch (exp) {
    console.log("Error", exp);
  }
};

export const createPeople = async (payload, token) => {
  try {
    const reponse = await postFormData({
      endpoint: "People/addPeople",
      data: payload,
      params: {},
      token: token,
    });
    return reponse?.data;
  } catch (exp) {
    console.log("error", exp);
  }
};

export const DeletePeopleData = async (payload) => {
  try {
    const response = await axios.delete("People/deletePeople", {
      params: {
        people_id: payload,
      },
    });
    return response;
  } catch (exp) {
    console.log("error", exp);
  }
};

export const updatePeople = async (payload, peopleId, token) => {
  const tempParams = {
    people_id: peopleId,
  };
  try {
    const reponse = await patchFormData({
      endpoint: "People/editPeople",
      data: payload,
      params: tempParams,
      token: token,
    });
    return reponse;
  } catch (exp) {
    console.log("error", exp);
  }
};

export const getStateData = async (payload) => {
  try {
    const reponse = await axios.get("Settings/getStateRegionByCountryId", {
      params: {
        country_id: payload,
      },
    });
    return reponse?.data;
  } catch (exp) {
    console.log("error", exp);
  }
};
export const getCountryData = async () => {
  try {
    const reponse = await axios.get("Settings/getCountries");
    return reponse?.data;
  } catch (exp) {
    console.log("error", exp);
  }
};
export const getcityDataById = async (params) => {
  try {
    const response = await axios.get("Settings/getCityByStateRegionId", {
      params: {
        state_region_id: params,
      },
    });
    return response.data;
  } catch (exp) {
    console.log("error", exp);
  }
};

export const createKeyLink = async (payload, token) => {
  try {
    const response = await postData({
      endpoint: "People/addPeopleKeyLink",
      data: payload,
      params: {},
      token: token,
    });
    return response;
  } catch (exp) {
    console.log("error", exp);
  }
};

export const getAllPeopleKeyLink = async (payload) => {
  try {
    const reponse = await axios.get("People/getAllPeopleKeyLink", {
      params: {
        page: payload.page,
        page_limit: payload.pageLimit,
        people_id: payload.people_id,
      },
    });
    return reponse.data;
  } catch (exp) {
    console.log("exrror", exp);
  }
};
export const getPeopleKeyLinkById = async (payload) => {
  try {
    const reponse = await axios.get("People/getPeopleKeyLink", {
      params: {
        key_link_id: payload.key_link_id,
      },
    });
    return reponse.data;
  } catch (exp) {
    console.log("exrror", exp);
  }
};

export const updateKeyLinkData = async (payload, token) => {
  try {
    const response = await patchData({
      endpoint: "People/editPeopleKeyLink",
      data: payload,
      params: {},
      token: token,
    });
    return response;
  } catch (exp) {
    console.log("error", exp);
  }
};

export const getallProductStructure = async () => {
  try {
    const response = await axios.get("Taxonomy/getallProductStructure");
    return response?.data;
  } catch (exp) {
    console.log("Error", exp);
  }
};

export const addPeopleInvestorPreference = async (data, token) => {
  try {
    const response = await postData({
      endpoint: "People/addPeopleInvestmentPrefrence",
      data: data.prefrences,
      params: { people_id: data?.people_id },
      token: token,
    });
    return response;
  } catch (error) {
    console.log("addPeopleInvestmentPreferance", error);
  }
};

export const getPeopleInvestmentData = async (payload) => {
  try {
    const response = await axios.get("People/getAllPeopleInvestmentPrefrence", {
      params: {
        page: payload.page,
        page_limit: payload.pageLimit,
        people_id: payload.people_id,
      },
    });
    return response.data;
  } catch (exp) {
    console.log("error", exp);
  }
};
export const getPeopleInvestmentDataByID = async (payload) => {
  try {
    const response = await axios.get("People/getInvestmentPreferenceDetails", {
      params: {
        preference_id: payload,
      },
    });
    return response.data;
  } catch (exp) {
    console.log("error", exp);
  }
};
export const updatePeopleInvestmentData = async (payload, token) => {
  try {
    const response = await patchData({
      endpoint: "People/editPeopleInvestmentPrefrence",
      data: payload,
      params: {},
      token: token,
    });
    return response;
  } catch (exp) {
    console.log("error", exp);
  }
};

export const getPeopleDetailsById = async (people_id, token) => {
  try {
    const response = await getData({
      endpoint: "People/getPeopleDetails",
      params: {
        people_id: people_id,
      },
      token: token,
    });
    return response;
  } catch (exp) {
    console.log("Error", exp);
  }
};

export const addOrDraftPeople = async (data, token) => {
  try {
    const response = await postData({
      endpoint: "People/peopleCreateOrSaveAsDraft",
      data: {},
      params: { people_id: data.people_id, is_draft: data.is_draft },
      token: token,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const addPeopleWorkHistory = async (data, people_id, token) => {
  try {
    const response = await postData({
      endpoint: "People/addPeopleWorkDetails",
      data: data.addWork,
      params: { people_id: people_id },
      token: token,
    });
    return response;
  } catch (error) {
    console.log("addPeopleInvestmentPreferance", error);
  }
};

export const getPeopleWorkHistory = async (payload) => {
  try {
    const response = await axios.get("People/getAllPeopleWorkDetail", {
      params: {
        page: payload.page,
        page_limit: payload.pageLimit,
        people_id: payload.people_id,
      },
    });
    return response.data;
  } catch (exp) {
    console.log("Error", exp);
  }
};

export const getWorkDetailsById = async (work_detail_id) => {
  try {
    const response = await axios.get("People/getPeopleWorkDetails", {
      params: {
        work_detail_id: work_detail_id,
      },
    });
    return response.data;
  } catch (exp) {
    console.log("Error", exp);
  }
};

export const updateWorkDetailsData = async (payload, token) => {
  try {
    const response = await patchData({
      endpoint: "People/editPeopleWorkDetail",
      data: payload,
      params: {},
      token: token,
    });
    return response;
  } catch (exp) {
    console.log("error", exp);
  }
};

export const deleteWorkDetails = async (workId) => {
  try {
    const response = await axios.delete("People/deletePeopleWorkDetail", {
      params: {
        work_id: workId,
      },
    });
    return response;
  } catch (exp) {
    console.log("Error", exp);
  }
};

export const addPeopleChannel = async (data, token) => {
  try {
    const response = await postData({
      endpoint: "People/addPeopleChannel",
      data: data.links,
      params: { people_id: data.people_id },
      token: token,
    });
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const getPeopleChannel = async (payload) => {
  try {
    const response = await axios.get("People/getPeopleAllChannel", {
      params: {
        page: payload.page,
        page_limit: payload.pageLimit,
        people_id: payload.people_id,
      },
    });
    return response.data;
  } catch (exp) {
    console.log("Error", exp);
  }
};

export const updateChannelData = async (payload, editId, token) => {
  payload.id = editId;
  try {
    const response = await patchData({
      endpoint: "People/editPeopleChannel",
      data: payload,
      params: {},
      token: token,
    });
    return response;
  } catch (exp) {
    console.log("error", exp);
  }
};

export const editPeopleSocialDetail = async (data, token) => {
  const res = await patchData({
    endpoint: "People/editPeopleChannel",
    data: data,
    params: {},
    token: token,
  });
  return res;
};
