import React, { ReactNode } from "react";
import styles from "./header.module.scss";

interface HeadingProps {
  title?: string;
  children?: ReactNode;
  type?: string;
  classes?: string;
}

const Heading: React.FC<HeadingProps> = ({
  title,
  children,
  type,
  classes,
}) => {
  return (
    <div
      className={`${styles.header_container} ${classes} ${
        type === "small" || type === "smaller" || type === "sub"
          ? "mb-0"
          : "mb-4"
      }`}
    >
      <h3
        style={{ color: "#1A1D1F" }}
        className={
          type === "main"
            ? styles.main_header
            : type === "sub"
            ? styles.sub_header
            : type === "small"
            ? styles.small_header
            : type === "smaller"
            ? styles.smaller_header
            : styles.main_header
        }
      >
        {title}
      </h3>
      {children && <div className={styles.child}>{children}</div>}
    </div>
  );
};

export default Heading;
