import PropTypes from "prop-types";
const Location = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_3582_21111)">
        <path
          d="M9 4.5C8.40666 4.5 7.82664 4.67595 7.33329 5.00559C6.83994 5.33524 6.45543 5.80377 6.22836 6.35195C6.0013 6.90013 5.94189 7.50333 6.05765 8.08527C6.1734 8.66721 6.45912 9.20176 6.87868 9.62132C7.29824 10.0409 7.83279 10.3266 8.41473 10.4424C8.99667 10.5581 9.59987 10.4987 10.1481 10.2716C10.6962 10.0446 11.1648 9.66006 11.4944 9.16671C11.8241 8.67336 12 8.09334 12 7.5C12 6.70435 11.6839 5.94129 11.1213 5.37868C10.5587 4.81607 9.79565 4.5 9 4.5ZM9 9C8.70333 9 8.41332 8.91203 8.16665 8.7472C7.91997 8.58238 7.72771 8.34811 7.61418 8.07403C7.50065 7.79994 7.47095 7.49834 7.52882 7.20736C7.5867 6.91639 7.72956 6.64912 7.93934 6.43934C8.14912 6.22956 8.41639 6.0867 8.70737 6.02882C8.99834 5.97094 9.29994 6.00065 9.57403 6.11418C9.84812 6.22771 10.0824 6.41997 10.2472 6.66665C10.412 6.91332 10.5 7.20333 10.5 7.5C10.5 7.89783 10.342 8.27936 10.0607 8.56066C9.77936 8.84196 9.39783 9 9 9Z"
          fill="#1A1D1F"
        />
        <path
          d="M9.00023 18.0005C8.36869 18.0038 7.74557 17.8556 7.18303 17.5686C6.6205 17.2815 6.13493 16.8638 5.76698 16.3505C2.90873 12.4078 1.45898 9.44377 1.45898 7.54027C1.45898 5.54021 2.25351 3.62206 3.66777 2.2078C5.08202 0.793546 7.00017 -0.000976562 9.00023 -0.000976562C11.0003 -0.000976562 12.9184 0.793546 14.3327 2.2078C15.747 3.62206 16.5415 5.54021 16.5415 7.54027C16.5415 9.44377 15.0917 12.4078 12.2335 16.3505C11.8655 16.8638 11.38 17.2815 10.8174 17.5686C10.2549 17.8556 9.63178 18.0038 9.00023 18.0005ZM9.00023 1.63627C7.43455 1.63806 5.9335 2.26082 4.82639 3.36793C3.71928 4.47504 3.09652 5.97609 3.09473 7.54177C3.09473 9.04927 4.51448 11.837 7.09148 15.3913C7.31026 15.6926 7.59726 15.9379 7.92902 16.107C8.26078 16.2761 8.62786 16.3642 9.00023 16.3642C9.37261 16.3642 9.73969 16.2761 10.0714 16.107C10.4032 15.9379 10.6902 15.6926 10.909 15.3913C13.486 11.837 14.9057 9.04927 14.9057 7.54177C14.9039 5.97609 14.2812 4.47504 13.1741 3.36793C12.067 2.26082 10.5659 1.63806 9.00023 1.63627Z"
          fill="#1A1D1F"
        />
      </g>
      <defs>
        <clipPath id="clip0_3582_21111">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
Location.propTypes = {
  className: PropTypes.string, // expects a string for additional class name
};
export default Location;
