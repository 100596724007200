const Disclaimer = ({ data }: any) => {
  return (
    <>
      <div className="Disclaimer-product-container">
        <div className="h3-j semi pro-info-heading mb-3 Disclaimer-Heading">
          Disclaimer
        </div>
        <div className="quill quill-change">
          <div
            className="ql-container ql-snow ql-no-border"
            style={{ background: "white" }}
          >
            <div
              className="ql-editor blog-content"
              style={{ padding: "0 !important" }}
              dangerouslySetInnerHTML={{ __html: data?.disclaimer }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Disclaimer;
