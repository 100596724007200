import { useEffect, useState } from "react";
import SelectDropdown from "../../../../atoms/Dropdown/index";
import HeadingDropDown from "../../../../atoms/HeadingDropDown";
import style from "../NewProductDatabase/index.module.scss";

import {
  getCountries,
  getMultipleCityById,
  getStatesById,
} from "../../../../../services/editHelper";
import ArrowDown from "../../../../Icons/ArrowDown";

const LocationFilter = ({
  handleFilter,
  type,
  filterOpenlocation,
  setFilterOpenlocation,
  id,
  dropdownPosition,
  reset,
}) => {
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedCityId, setSelectedCityId] = useState();
  const [selectedStateIds, setSelectedStateIds] = useState();
  const [isConfirmed, setIsConfirmed] = useState();

  const fetchCountries = async () => {
    const res = await getCountries();

    if (res) {
      setCountry(res);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (reset) {
      handleReset();
      setFilterOpenlocation(false);
    }
  }, [reset]);

  const handleReset = () => {
    setSelectedCity(null);
    setSelectedCountry(null);
    setSelectedState(null);
    setSelectedCityId(null);
    setSelectedStateIds(null);

    handleFilter(null);
    setIsConfirmed(false);

    setFilterOpenlocation(!filterOpenlocation);
  };

  const handleConfirm = () => {
    if (
      selectedCountry ||
      selectedState?.length > 0 ||
      selectedCity?.length > 0
    ) {
      setIsConfirmed(true);
    }

    let data = {
      country: selectedCountry?.label,
      state: selectedState,
      city: selectedCity,
    };
    handleFilter(data);

    setFilterOpenlocation(!filterOpenlocation);
  };

  const handleCityChange = () => {
    //  to update the city list
    const updatedCityIds = selectedCityId?.filter((cityId) =>
      city.some((city) => city.value === cityId)
    );
    setSelectedCityId(updatedCityIds);
  };

  return (
    <div className={style.multi_select_filter_wrapper}>
      <HeadingDropDown
        title="Location"
        icon={<ArrowDown />}
        type={type}
        IsOpen={filterOpenlocation}
        setIsOpen={setFilterOpenlocation}
        // styles={{ top: "3.5rem" }}
        id={id}
        dropdownPosition={dropdownPosition}
        active={isConfirmed}
      >
        <div>
          <h2 className={style.InvestorFormHeader}>Location</h2>
          <form className={style.formConatiner}>
            <div className={style.formInput}>
              <label htmlFor="country">Country</label>
              <SelectDropdown
                data={country}
                placeholder="Select Country"
                onChange={async (value) => {
                  setSelectedCountry(value);
                  const data = await getStatesById(value.value);
                  setState(data);
                }}
                format="all"
                selectedValue={selectedCountry?.value}
              />
            </div>

            <div className={style.formInput}>
              <label htmlFor="country">State</label>
              <SelectDropdown
                placeholder="Select State"
                isDisabled={!selectedCountry}
                data={state}
                onChange={async (value) => {
                  const stateNames = value.map((o) => o.label);
                  setSelectedState(stateNames);
                  const stateIds = value.map((o) => o.value);
                  setSelectedStateIds(stateIds);
                  const data = await getMultipleCityById(stateIds);
                  setCity(data);
                  handleCityChange();
                }}
                format="all"
                selectedValue={selectedStateIds}
                multiSelect={true}
              />
            </div>

            <div className={style.formInput}>
              <label htmlFor="country">City</label>
              <SelectDropdown
                placeholder="Select City"
                isDisabled={selectedState?.length <= 0}
                data={city}
                format="all"
                onChange={(value) => {
                  const cityNames = value.map((o) => o.label);
                  const cityIds = value.map((o) => o.value);
                  setSelectedCityId(cityIds);
                  setSelectedCity(cityNames);
                }}
                selectedValue={selectedCityId}
                multiSelect={true}
              />
            </div>
          </form>

          <div className={style.bottomContainer}>
            <span
              className="medium cursor-pointer"
              onClick={() => handleReset()}
            >
              Cancel
            </span>
            <button
              className={`btn btn-primary ${style.customBtn}`}
              onClick={() => handleConfirm()}
            >
              Confirm
            </button>
          </div>
        </div>
      </HeadingDropDown>
    </div>
  );
};

export default LocationFilter;
