import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getData } from "../../../../services";
import { loaderData, setLoading } from "../../../../store/slices/loaderSlice";
import BannerImage from "../../../atoms/BannerImage";
import TxtFileRenderer from "../Library/TxtFileRenderer";
import "./thought.scss";

const View = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.peopleData?.peopleData);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies(["t"]);
  const [blogData, setBlogData] = useState({});

  const loadingDataValue = useSelector(loaderData);

  const getBlogData = async () => {
    setIsLoading(true);
    dispatch(setLoading(true));
    try {
      const res = await getData({
        endpoint: "Blog/getLibraryDetails",
        params: { blog_id: id },
        token: cookies.t,
      });
      dispatch(setLoading(false));
      setBlogData(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBlogData();
  }, [id]);

  return (
    <div className="thought-container">
      <div className="thought-leadership-view">
        <div>
          <h1 className="h1-j mt-4">{blogData?.header}</h1>
          <div className="d-flex align-items-center mb-large flex-wrap date-tab-responsive">
            <p className="p16 mb-0 p-dark-light light">
              By {blogData?.company_name || "PrimeAlpha"}
            </p>
            <div className="d-flex align-items-center">
              <div className="dot-dark"></div>
              <p className="p16 mb-0 p-dark-light light">
                {moment.utc(blogData?.modified_date).format("MMM D, YYYY")}
              </p>
            </div>
          </div>
        </div>

        {blogData?.cover_photo && (
          <BannerImage
            imageUrl={blogData?.cover_photo}
            altText="cover"
            type="cover"
            style={{ marginBottom: "15px" }}
          />
        )}

        <div
          className={`quill quill-change ${
            blogData?.cover_photo ? "mt-3" : ""
          }`}
        >
          {blogData?.text && <TxtFileRenderer fileUrl={blogData?.text} />}
        </div>
      </div>

      <footer className="footer w-80 footer-responsive">
        <div className="d-flex gap-3">
          <button
            type="submit"
            className="btn btn-outline-dark px-4 mt-0 "
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </div>
      </footer>
    </div>
  );
};

export default View;
