export const extractLatLongFromGoogleMapsUrl = (url) => {
  // Regular expression to extract latitude and longitude
  const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
  const match = url.match(regex);

  // console.log('url', url)

  if (match && match.length >= 3) {
    const latitude = parseFloat(match[1]);
    const longitude = parseFloat(match[2]);
    return { latitude, longitude };
  } else {
    return null;
  }
};
