import moment from "moment";
import "../components.scss";

const ProductDetails = ({ data }: any) => {
  const renderField = ({ label, value, type }: any) => (
    <>
      {value && (
        <tr>
          <th className="view-muted">{label}</th>
          <td>
            {type === "date"
              ? moment.utc(value).isValid()
                ? moment.utc(value).format("MMM YYYY")
                : "--"
              : typeof value === "boolean"
              ? value
                ? "Yes"
                : "No"
              : value || "--"}
          </td>
        </tr>
      )}
    </>
  );

  return (
    <>
      <h1 className="h1-j mb-large">Product Details</h1>

      <div className="h3-j semi pro-info-heading mb-small">
        Service Providers
      </div>

      <table className="info-table mb-large">
        <tbody>
          {renderField({ label: "Prime Broker", value: data?.prime_broker })}
          {renderField({ label: "Administrator", value: data?.administrator })}
          {renderField({
            label: "Bank / Custodian",
            value: data?.bank_custodian,
          })}
          {renderField({ label: "Auditor", value: data?.auditor })}
          {renderField({ label: "Legal Counsel", value: data?.legal_counsel })}
        </tbody>
      </table>

      <div className="h3-j semi pro-info-heading mb-small">Structure</div>

      <table className="info-table mb-large">
        <tbody>
          {renderField({
            label: "Open/Close Ended",
            value: data?.open_close_ended,
          })}
          {renderField({ label: "Funding Status", value: data?.fund_status })}
          {renderField({
            label: "Funding Goal ($USD Millions)",
            value: data?.funding_goal?.toLocaleString("en-US"),
          })}
          {renderField({
            label: "Target Close Date",
            value: data?.target_close_date,
            type: "date",
          })}
        </tbody>
      </table>

      {/* Add your structure-related info-rows here */}

      <div className="h3-j semi pro-info-heading mb-small">Terms</div>

      <table className="info-table mb-large">
        <tbody>
          {renderField({
            label: "Eligible Investors",
            value: data?.eligible_investor,
          })}
          {renderField({
            label: "Minimum Investment ($USD)",
            value: data?.minimum_investment?.toLocaleString("en-US"),
          })}
          {renderField({
            label: "Management Fee (%)",
            value: data?.management_fee,
          })}
          {renderField({
            label: "Performance Fee (%)",
            value: data.performance_fee,
          })}
          {renderField({
            label: "Hurdle Rate (%)",
            value: data?.hurdle_rate,
          })}
          {renderField({
            label: "High Water Mark",
            value: data?.high_water_mark,
          })}
          {renderField({
            label: "Subscription Frequency",
            value: data?.subscription_frequency,
          })}
          {renderField({
            label: "Redemption Frequency",
            value: data?.redemtion_frequency,
          })}
          {renderField({
            label: "Redemption Notice Period (In Days)",
            value: data?.redemption_notice_period,
          })}
          {renderField({
            label: "Redemption Penalty (%)",
            value: data?.redemption_penalty,
          })}
          {renderField({ label: "Lockup Period", value: data?.lockup_period })}
        </tbody>
      </table>
    </>
  );
};

export default ProductDetails;
