import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import "./user_team.scss";

import { useDispatch } from "react-redux";
import { getData } from "../../../../../services";
import { setLoading } from "../../../../../store/slices/loaderSlice";
import UserProfileCard from "../../../../molecules/UserProfileCard";

const UserTeams = () => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["cid", "t"]);
  const navigate = useNavigate();
  const [data, setData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const result = await getData({
          endpoint: "People/customer/getUserTeamPeoples",
          params: { company_id: cookies.cid },
          token: cookies.t,
        });
        dispatch(setLoading(false));
        setData(result);
      } catch (error) {
        console.log(error);
        // } finally {
        //   setLoading(false);
        // }
      }
    };

    fetchData();
  }, []);

  return (
    <div className="user-team">
      <div className="h2-i">Teams</div>
      <div id="user-teams" className="user-team-container">
        {data?.map((item) => (
          <UserProfileCard
            key={item.people_id}
            profileName={item.name}
            profilePosition={item.job_title}
            onClick={() => navigate(`/dashboard/people/${item.people_id}`)}
            profilePicture={item.profile_photo}
            style={{ minWidth: "12rem" }}
          />
        ))}
      </div>
    </div>
  );
};

export default UserTeams;
