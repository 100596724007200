import { Menu, MenuItem } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import { patchData } from "../../../../services";
import { loaderData } from "../../../../store/slices/loaderSlice";
import RightUpArrow from "../../../Icons/RightUpArrow";
import VerticalMenu from "../../../Icons/VerticalMenu";
import View from "../../../Icons/View";
import CircleAvatar from "../../../atoms/CircleAvatar/index";
import TypeDiv from "../../../atoms/TypeDiv";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import Modal from "../../../molecules/Modal/Normal/index";
import NoContentCard from "../../../molecules/NoContentCard/index";
import Pagination from "../../../molecules/Pagination";
import ContentSharingShimmer from "../ContentSharing/Shimmer/ConetntSharing";
import ContentSharingPhoneShimmer from "../ContentSharing/Shimmer/ContentSharingPhones";
import ViewRequest from "./ViewRequest";

const ProductRequest = ({
  requestData,
  getRequestData,
  currentPage,
  itemsPerPage,
  handlePageChange,
  handleItemsPerPageChange,
  refetchProductRequest,
  searchedValue,
  filters,
}) => {
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();
  const loadingDataValue = useSelector(loaderData);
  const [modalState, setModalState] = useState({
    isViewModalOpen: false,
    modalId: "",
    name: "",
    formData: null,
  });

  const [anchorEl, setAnchorEl] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  // Function to handle opening menu for a specific row
  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleStatusMenuSmall = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  // Function to close menu for a specific row
  const handleCloseMenu = (index) => {
    setAnchorEl(null);
  };

  const handleView = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isViewModalOpen: true,
      modalId: data.id,
      name: `View Request Information`,
      formData: data,
    }));
  };

  const handleUpdate = async (id, status) => {
    try {
      const res = await patchData({
        endpoint: "RequestAccess/requestApproveOrReject",
        params: {
          request_id: id,
          status: status,
        },
      });

      if (res) {
        toast.success("Status Updated Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        refetchProductRequest();
      }
    } catch (error) {
      console.log("error", error);
      toast.error("An Error Occured", { duration: 1000, style: errorStyles });
    }
  };

  const totalItems = requestData?.total_record;

  const columns = [
    {
      field: "details",
      headerName: "Requested By",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center gap-3">
            <CircleAvatar
              name={params.row.user_name}
              size={40}
              fontSize="16px"
              color="#f4f4f4"
              border="1px solid #efefef"
              iconColor="#3D9BE9"
            />
            <div className="d-flex flex-column ">
              <span
                style={{ fontWeight: 800, fontSize: "14px" }}
                title={params.row.user_name}
                className="cursor-pointer truncate"
                onClick={() =>
                  navigate(`/dashboard/people/${params.row.user_id}`, {
                    state: { navigationType: "other" },
                  })
                }
              >
                {params.row.user_name}
              </span>
              <span
                style={{ color: "#6F767E", fontSize: "14px" }}
                title={params.row.user_company}
                className="truncate"
              >
                {params.row.user_company}
              </span>
              {/* <span style={{ color: "#6F767E", fontSize: "14px" }}>
                {params.row.user_email}
              </span> */}
            </div>
          </div>
        );
      },
    },
    {
      field: "product_name",
      headerName: "Product Requested",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div
            className="cursor-pointer"
            onClick={() =>
              navigate(`/dashboard/product/${params.row.product_id}`, {
                state: { navigationType: "other" },
              })
            }
          >
            <div className="company-rightUpWrapper">
              <div className="uniform-truncate">{params.row.product_name}</div>
              <div className="rightUpArrow">
                <RightUpArrow />
              </div>
            </div>
          </div>
        );
      },
    },
    {
      field: "comment",
      headerName: "Description",
      flex: 0.7,
    },
    {
      field: "date",
      headerName: "Requested Date",
      renderCell: (params) => {
        return (
          <span>{moment(params.row.date).format("MMM D, YYYY hh:mm a")}</span>
        );
      },
      flex: 0.6,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        return (
          <TypeDiv
            data={params.row.status}
            color={
              params.row.status === "Pending"
                ? "#FFD88D"
                : params.row.status === "Approved"
                ? "#B5E4CA"
                : "#FFB7B7"
            }
          />
        );
      },
      flex: 0.5,
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.4,
      renderCell: (params, index) => {
        return (
          <>
            <span type="button" onClick={() => handleView(params.row)}>
              {/* <EditBlue className="edit-video-icon" /> */}
              <View />
            </span>

            <span
              type="button"
              onClick={(event) => handleStatusMenu(event, params.row)}
              className="ms-3"
            >
              {/* <EditBlue className="edit-video-icon" /> */}
              <VerticalMenu />
            </span>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={() => handleCloseMenu(index)}
              PaperProps={{
                style: {
                  width: "180px",
                  borderRadius: "16px",
                  marginTop: "10px", // Add margin-top to create space
                  boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.015)", // Aesthetically pleasing box shadow
                  border: "1px solid #efefef",
                  fontWeight: "500",
                },
              }}
            >
              {params.row.status === "Pending" && (
                <>
                  <MenuItem
                    onClick={() => {
                      // setAnchorEl(null);
                      handleUpdate(params.row.id, "Approved");
                    }}
                  >
                    Approve
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      // setAnchorEl(null);
                      handleUpdate(params.row.id, "Denied");
                    }}
                  >
                    <span style={{ color: "#BE0F00" }}>Deny </span>
                  </MenuItem>
                </>
              )}

              {params.row.status === "Revoked" && (
                <MenuItem
                  onClick={() => {
                    // setAnchorEl(null);
                    handleUpdate(params.row.id, "Approved");
                  }}
                >
                  Approve
                </MenuItem>
              )}

              {params.row.status === "Denied" && (
                <MenuItem
                  onClick={() => {
                    // setAnchorEl(null);
                    handleUpdate(params.row.id, "Approved");
                  }}
                >
                  Approve
                </MenuItem>
              )}

              {params.row.status === "Approved" && (
                <MenuItem
                  onClick={() => {
                    // setAnchorEl(null);
                    handleUpdate(params.row.id, "Revoked");
                  }}
                >
                  <span style={{ color: "#BE0F00" }}>Revoke</span>
                </MenuItem>
              )}
            </Menu>
          </>
        );
      },
    },
  ];

  const CustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        <NoContentCard title="No Record Found" />
      </>
    );
  });

  return (
    <>
      {screenWidth > 900 ? (
        <>
          {loadingDataValue ? (
            <ContentSharingShimmer />
          ) : (
            <CustomDatagrid
              rows={requestData?.data || []}
              getRowId={(row) => row.id}
              columns={columns}
              height={() => 80}
              hoverColor="ffffff"
              CustomNoRowsOverlay={CustomNoRowsOverlay}
            />
          )}
        </>
      ) : (
        <>
          {loadingDataValue ? (
            <ContentSharingPhoneShimmer />
          ) : requestData?.data?.length > 0 ? (
            <div className="content-sharing-phone-container">
              {requestData?.data?.map((item, index) => {
                return (
                  <div className="sharing-details-phone-wrapper margin-request-conatiner">
                    <div
                      className="detail-contet-item-phone"
                      style={{ paddingLeft: "15px" }}
                    >
                      <div className="p-dark mb-2 mt-2">Requested By</div>
                      {/* <div className="p-light-small">{item?.module}</div> */}
                      <div className="d-flex align-items-center gap-3">
                        <CircleAvatar
                          name={item?.user_name}
                          size={40}
                          fontSize="16px"
                          color="#f4f4f4"
                          border="1px solid #efefef"
                          iconColor="#3D9BE9"
                        />
                        <div
                          className="d-flex flex-column cursor-pointer"
                          onClick={() =>
                            navigate(`/dashboard/people/${item?.user_id}`)
                          }
                        >
                          <span style={{ fontWeight: 800, fontSize: "14px" }}>
                            {item?.user_name}
                          </span>
                          <span style={{ color: "#6F767E", fontSize: "14px" }}>
                            {item?.user_company}
                          </span>
                          {/* <span style={{ color: "#6F767E", fontSize: "14px" }}>
                {params.row.user_email}
              </span> */}
                        </div>
                      </div>
                    </div>
                    <div className="sharing-details-content-conatiner no-border-request-container">
                      <div className="detail-contet-item-phone">
                        <div className="p-dark">Product Request</div>
                        <div className="p-light-small">
                          {item?.product_name}
                        </div>
                      </div>
                      <div className="detail-contet-item-phone">
                        <div className="p-dark">Description</div>
                        <div className="p-light-small">{item?.comment}</div>
                      </div>
                      <div className="detail-contet-item-phone">
                        <div className="p-dark">Requested Date</div>
                        <div className="p-light-small">
                          {moment(item?.date).format("MMM D, YYYY hh:mm a")}
                        </div>
                      </div>
                      <div className="detail-contet-item-phone">
                        <div className="p-dark">Status</div>
                        <div className="p-light-small">
                          <TypeDiv
                            style={{ color: "black" }}
                            data={item?.status}
                            color={
                              item?.status === "Pending"
                                ? "#FFD88D"
                                : item?.status === "Approved"
                                ? "#B5E4CA"
                                : "#FFB7B7"
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sharing-details-phone-btns">
                      <button
                        className="btn btn-primary"
                        onClick={() => handleView(item)}
                      >
                        <View className="sharing-view-icon" /> View
                      </button>
                      <button
                        className="btn btn-outline-dark "
                        onClick={(e) => handleStatusMenuSmall(e, item.id)}
                      >
                        {/* <span className="mb-1">
                      <RevokeIcon />
                    </span>{" "} */}
                        More
                      </button>

                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedRow === item.id}
                        onClose={() => handleCloseMenu(index)}
                        PaperProps={{
                          style: {
                            width: "150px",
                            borderRadius: "16px",
                            marginTop: "10px", // Add margin-top to create space
                            boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.015)", // Aesthetically pleasing box shadow
                            border: "1px solid #efefef",
                            fontWeight: "500",
                            paddingTop: 0,
                            paddingBottom: 0,
                          },
                        }}
                      >
                        {item.status === "Pending" && (
                          <>
                            <MenuItem
                              onClick={() => {
                                setAnchorEl(null);
                                handleUpdate(item.id, "Approved");
                              }}
                            >
                              Approve
                            </MenuItem>

                            <MenuItem
                              onClick={() => {
                                setAnchorEl(null);
                                handleUpdate(item.id, "Denied");
                              }}
                            >
                              <span style={{ color: "#BE0F00" }}>Deny </span>
                            </MenuItem>
                          </>
                        )}

                        {item.status === "Revoked" && (
                          <MenuItem
                            onClick={() => {
                              setAnchorEl(null);
                              handleUpdate(item.id, "Approved");
                            }}
                          >
                            Approve
                          </MenuItem>
                        )}

                        {item.status === "Denied" && (
                          <MenuItem
                            onClick={() => {
                              setAnchorEl(null);
                              handleUpdate(item.id, "Approved");
                            }}
                          >
                            Approve
                          </MenuItem>
                        )}

                        {item.status === "Approved" && (
                          <MenuItem
                            onClick={() => {
                              setAnchorEl(null);
                              handleUpdate(item.id, "Revoked");
                            }}
                          >
                            <span style={{ color: "#BE0F00" }}>Revoke</span>
                          </MenuItem>
                        )}
                      </Menu>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="no-content">
              {searchedValue ||
              filters?.status ||
              filters?.startDate ||
              filters?.endDate ? (
                <NoContentCard title="Sorry! No Result Found" type="Search" />
              ) : (
                <NoContentCard title="No Data Found !" type="" />
              )}
            </div>
          )}
        </>
      )}
      {totalItems >= 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}

      {modalState.isViewModalOpen && (
        <Modal
          id={modalState.modalId}
          isActive={modalState.isViewModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isViewModalOpen: false,
            }))
          }
          title={`View Request Information`}
          width="642px"
          overflowStyle={{ overflow: "visible" }}
          type="visible"
          scale="0.7"
        >
          <ViewRequest
            data={modalState.formData}
            onClose={() =>
              setModalState((prevState) => ({
                ...prevState,
                isViewModalOpen: false,
              }))
            }
            onUpdate={() => getRequestData()}
          />
        </Modal>
      )}
    </>
  );
};
export default ProductRequest;
