import { useNavigate } from "react-router-dom";
import BackArrowLeft from "../../Icons/BackArrowLeft";
import "./back.scss";

const BackButton = ({ tag, module }: any) => {
  const navigate = useNavigate();

  const handleBackButton = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (module === "library") {
      navigate("/dashboard/library");
    } else {
      navigate("/dashboard/resources");
    }
  };

  return (
    <button
      className="btn btn-outline-dark backbtn"
      onClick={() => {
        handleBackButton();
      }}
    >
      <div className="d-flex align-items-center justify-content-center">
        <i className="d-flex align-items-center">
          <BackArrowLeft />
        </i>
        {tag}
      </div>
    </button>
  );
};

export default BackButton;
