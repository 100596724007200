import "./shimmer.scss";
const ShimmerBlog = ({ isShared = false }) => {
  return (
    <div className="d-flex srbl-main-container">
      <div
        className="srbl-container"
        style={
          isShared ? { margin: "0 auto", minWidth: "0", maxWidth: "100%" } : {}
        }
      >
        <div className="srbl-blog-back"></div>
        <div className="srbl-blog-heading"></div>
        <div className="srbl-blog-header-content">
          <div className="srbl-blog-description"></div>
          <div className="srbl-blog-description"></div>
        </div>
        <div className="srbl-blog-image"></div>
        <div className="srbl-blog-content"></div>
        <div className="srbl-blog-content"></div>
        <div className="srbl-blog-content"></div>
        <div className="srbl-blog-content"></div>
        <div className="srbl-blog-content"></div>
        <div className="srbl-blog-content"></div>
        <div className="srbl-blog-content"></div>
      </div>
      {!isShared && (
        <div className="srbl-container-right">
          <div className="srbl-blog-content"></div>
          <div
            style={{ background: "none" }}
            className="srbl-blog-content-mini"
          >
            <div className="srbl-blog-content-1"></div>
            <div className="srbl-blog-content-2"></div>
            <div className="srbl-blog-content-3"></div>
          </div>
          <div
            style={{ background: "none" }}
            className="srbl-blog-content-mini"
          >
            <div className="srbl-blog-content-1"></div>
            <div className="srbl-blog-content-2"></div>
            <div className="srbl-blog-content-3"></div>
          </div>
          <div
            style={{ background: "none" }}
            className="srbl-blog-content-mini"
          >
            <div className="srbl-blog-content-1"></div>
            <div className="srbl-blog-content-2"></div>
            <div className="srbl-blog-content-3"></div>
          </div>
          <div
            style={{ background: "none" }}
            className="srbl-blog-content-mini"
          >
            <div className="srbl-blog-content-1"></div>
            <div className="srbl-blog-content-2"></div>
            <div className="srbl-blog-content-3"></div>
          </div>

          <div
            style={{ background: "none" }}
            className="srbl-blog-content-mini"
          >
            <div className="srbl-blog-content-1"></div>
            <div className="srbl-blog-content-2"></div>
            <div className="srbl-blog-content-3"></div>
          </div>
          <div
            style={{ background: "none" }}
            className="srbl-blog-content-mini"
          >
            <div className="srbl-blog-content-1"></div>
            <div className="srbl-blog-content-2"></div>
            <div className="srbl-blog-content-3"></div>
          </div>
          <div
            style={{ background: "none" }}
            className="srbl-blog-content-mini"
          >
            <div className="srbl-blog-content-1"></div>
            <div className="srbl-blog-content-2"></div>
            <div className="srbl-blog-content-3"></div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ShimmerBlog;
