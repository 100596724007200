import { Formik } from "formik";
import { useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { confimationStyles } from "../../../../assets/styles/toast";
import { patchData } from "../../../../services";
import { setLoading } from "../../../../store/slices/loaderSlice";
import Error from "../../../atoms/Error";
import PasswordField from "../../../atoms/PasswordField";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t"]);
  const dispatch = useDispatch();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
        "Please enter the correct format"
      )
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSubmit = async (values, resetForm) => {
    dispatch(setLoading(true));
    const res = await patchData({
      endpoint: "CustomerAuth/changePassword",
      data: {},
      params: { password: values.password },
      token: cookies.t,
    });
    if (res) {
      dispatch(setLoading(false));
      toast.success("Password Updated Successfully", {
        duration: 1000,
        style: confimationStyles,
      });
      resetForm();
    }
  };

  return (
    <>
      <div className="h1-i">Change Password</div>
      <div className="p">
        Enter the new password to change your existing password.
      </div>

      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({
          touched,
          errors,
          values,
          resetForm,
          handleBlur,
          setFieldValue,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <div className="edit-password">
            <div className="edit-change-pass">
              <form>
                <div className="form-group mt-4">
                  <>
                    <PasswordField
                      label="Password"
                      id="password"
                      name="password"
                      value={values.password}
                      onChange={(e) =>
                        setFieldValue("password", e.target.value)
                      }
                      onBlur={handleBlur}
                      placeholder="Enter your Password"
                      className={`${
                        (touched.password || isSubmitting) && errors.password
                          ? "error-input"
                          : ""
                      }`}
                    />
                    {(touched.password || isSubmitting) && errors.password && (
                      <Error error={errors.password} />
                    )}
                  </>
                </div>

                <div className="form-group mt-4">
                  <>
                    <PasswordField
                      label="Confirm Password"
                      id="confirmPassword"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={(e) =>
                        setFieldValue("confirmPassword", e.target.value)
                      }
                      onBlur={handleBlur}
                      placeholder="Enter your Password"
                      className={`${
                        (touched.confirmPassword || isSubmitting) &&
                        errors.confirmPassword
                          ? "error-input"
                          : ""
                      }`}
                    />
                    {(touched.confirmPassword || isSubmitting) &&
                      errors.confirmPassword && (
                        <Error error={errors.confirmPassword} />
                      )}
                  </>
                </div>

                <div className="d-flex gap-3 justify-content-end mt-3">
                  <button
                    type="button"
                    className="btn btn-outline-dark"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={!isValid || !dirty}
                    onClick={() => handleSubmit(values, resetForm)}
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>

            <div className="edit-validator">
              <div
                className={`password-strength ${
                  !isPasswordValid && touched.password
                    ? "conditions-not-met"
                    : ""
                }`}
              >
                <p className="condition-heading">Password must :</p>
                <div className="condition-wrapper">
                  <div
                    className={`condition ${
                      values.password.length >= 8 ? "met" : ""
                    }`}
                  ></div>
                  <span className="condition-description">
                    {" "}
                    Minimum 8 characters
                  </span>
                </div>
                <div className="condition-wrapper">
                  <div
                    className={`condition ${
                      /[A-Z]/.test(values.password) ? "met" : ""
                    }`}
                  ></div>
                  <span className="condition-description">
                    At least one uppercase character
                  </span>
                </div>
                <div className="condition-wrapper">
                  <div
                    className={`condition ${
                      /[a-z]/.test(values.password) ? "met" : ""
                    }`}
                  ></div>
                  <span className="condition-description">
                    At least one lowercase character
                  </span>
                </div>
                <div className="condition-wrapper">
                  <div
                    className={`condition ${
                      /[0-9]/.test(values.password) ? "met" : ""
                    }`}
                  ></div>
                  <span className="condition-description">
                    At least one numeric character
                  </span>
                </div>
                <div className="condition-wrapper">
                  <div
                    className={`condition ${
                      /[!@#$%^&*]/.test(values.password) ? "met" : ""
                    }`}
                  ></div>
                  <span className="condition-description">
                    One special character
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default ChangePassword;
