import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import SharedBlog from "../components/organisms/blogShare/SharedBlog";
import FAQ from "../components/pages/FAQ/FAQ";
import ForgotPassword from "../components/pages/ForgotPassword/ForgotPassword";
import HelpCenter from "../components/pages/HelpCenter/HelpCenter";
import None from "../components/pages/dashboard/None";
import ChangePassword from "../components/pages/editProfile/changePassword";
import Edit from "../components/pages/editProfile/edit";
import { protectedRoleType } from "../helpers/staticData";
import AuthGuard from "./AuthGaurd";
import GuestGuard from "./GuestGaurd";

import Login from "../components/pages/Login/Login";
import AuthLayout from "../components/templates/AuthLayout";
import PasswordLayout from "../components/templates/PasswordLayout";

import ResetPassword from "../components/pages/ResetPassword/ResetPassword";
import Verification from "../components/pages/Verification/Verification";
import EditProfileLayout from "../components/templates/EditProfileLayout";

import Company from "../components/pages/dashboard/Company";
import EditCompany from "../components/pages/dashboard/CompanyEdit";
import ViewInvestmentPreference from "../components/pages/dashboard/InvestmentPreference/View";
import Library from "../components/pages/dashboard/Library";
import ViewLibrary from "../components/pages/dashboard/Library/View";
import People from "../components/pages/dashboard/People";
import EditPeople from "../components/pages/dashboard/PeopleEdit";
import Product from "../components/pages/dashboard/Product";
import EditProduct from "../components/pages/dashboard/Product/Edit/index";
import Resources from "../components/pages/dashboard/Resource";
import ViewResource from "../components/pages/dashboard/Resource/View";
import DashboardLayout from "../components/templates/DashboardLayout";

import Investors from "../components/pages/dashboard/Investors";
import Products from "../components/pages/dashboard/Products";

import SavedList from "../components/pages/dashboard/SavedList";
import Search from "../components/pages/dashboard/Search";

import AddInvestmentPreference from "../components/pages/dashboard/InvestmentPreference/AddInvestmentPreference";
import AddEducation from "../components/pages/dashboard/PeopleEdit/PeopleTab/Education/Add";

import ViewEducation from "../components/pages/dashboard/PeopleEdit/PeopleTab/Education/View";
import AddWork from "../components/pages/dashboard/PeopleEdit/PeopleTab/Work/AddWork";
import ViewWork from "../components/pages/dashboard/PeopleEdit/PeopleTab/Work/ViewPeopleWorkHistory";

import RoadShow from "../components/pages/dashboard/Investors/RoadShow";
import ProductRoadShow from "../components/pages/dashboard/Products/RoadShow";

import EULA from "../components/pages/dashboard/policy/EULA";
import Terms from "../components/pages/dashboard/policy/Terms";
import Policy from "../components/pages/dashboard/policy/policy";

import ContentSharing from "../components/pages/dashboard/ContentSharing";
import VideoDataRoom from "../components/pages/dashboard/Product/ViewDataRoom/ViewDataRoom";
import Requests from "../components/pages/dashboard/Requests";

import ThoughtLeadership from "../components/pages/dashboard/ThoughtLeadership";
import ThoughtLeadershipAdd from "../components/pages/dashboard/ThoughtLeadership/Add";
import ThoughtLeadershipView from "../components/pages/dashboard/ThoughtLeadership/View";

import NewInvestorDatabase from "../components/pages/dashboard/Investors/NewInvestorDatabase";
import SharedProduct from "../components/pages/dashboard/Product/SharedProduct/SharedProduct";
import SharedVideos from "../components/pages/dashboard/Product/components/ViewSharedVideos";
import Preview from "../components/pages/dashboard/ThoughtLeadership/Preview";

import NewProductDatabase from "../components/pages/dashboard/Products/NewProductDatabase";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<></>}>
      <Component {...props} />
    </Suspense>
  );

const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem("page-has-been-force-refreshed") || "false"
    );
    try {
      const component = await componentImport();
      window.localStorage.setItem("page-has-been-force-refreshed", "false");
      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem("page-has-been-force-refreshed", "true");
        return window.location.reload();
      }
      throw error;
    }
  });

// const HelpCenter = Loadable(
//   lazyWithRetry(async () => import("../components/pages/HelpCenter/HelpCenter"))
// );

// const SharedBlog = Loadable(
//   lazyWithRetry(
//     async () => import("../components/organisms/blogShare/SharedBlog")
//   )
// );
// const FAQ = Loadable(
//   lazyWithRetry(async () => import("../components/pages/FAQ/FAQ"))
// );

// // Auth
// const Login = Loadable(
//   lazyWithRetry(async () => import("../components/pages/Login/Login"))
// );
// const AuthLayout = Loadable(
//   lazyWithRetry(async () => import("../components/templates/AuthLayout"))
// );
// const PasswordLayout = Loadable(
//   lazyWithRetry(async () => import("../components/templates/PasswordLayout"))
// );

// const EditProfileLayout = Loadable(
//   lazyWithRetry(async () => import("../components/templates/EditProfileLayout"))
// );
// const ResetPassword = Loadable(
//   lazyWithRetry(
//     async () => import("../components/pages/ResetPassword/ResetPassword")
//   )
// );
// const Verification = Loadable(
//   lazyWithRetry(
//     async () => import("../components/pages/Verification/Verification")
//   )
// );
// const ForgotPassword = Loadable(
//   lazyWithRetry(
//     async () => import("../components/pages/ForgotPassword/ForgotPassword")
//   )
// );

// const DashboardLayout = Loadable(
//   lazyWithRetry(async () => import("../components/templates/DashboardLayout"))
// );
// const Company = Loadable(
//   lazyWithRetry(
//     async () => import("../components/pages/dashboard/Company/index")
//   )
// );

// const EditCompany = lazyWithRetry(
//   async () => import("../components/pages/dashboard/CompanyEdit/index")
// );

// const ViewInvestmentPreference = lazyWithRetry(
//   async () => import("../components/pages/dashboard/InvestmentPreference/View")
// );
// const People = Loadable(
//   lazyWithRetry(
//     async () => import("../components/pages/dashboard/People/index")
//   )
// );

// const Resources = Loadable(
//   lazyWithRetry(
//     async () => import("../components/pages/dashboard/Resource/index")
//   )
// );
// const ViewResource = Loadable(
//   lazyWithRetry(
//     async () => import("../components/pages/dashboard/Resource/View")
//   )
// );

// const Library = Loadable(
//   lazyWithRetry(
//     async () => import("../components/pages/dashboard/Library/index")
//   )
// );
// const ViewLibrary = Loadable(
//   lazyWithRetry(
//     async () => import("../components/pages/dashboard/Library/View")
//   )
// );

// const Product = lazyWithRetry(
//   async () => import("../components/pages/dashboard/Product/index")
// );
// const EditProduct = Loadable(
//   lazyWithRetry(
//     async () => import("../components/pages/dashboard/Product/Edit/index")
//   )
// );

// const EditPeople = lazyWithRetry(
//   async () => import("../components/pages/dashboard/PeopleEdit/index")
// );

// const Products = Loadable(
//   lazyWithRetry(
//     async () => import("../components/pages/dashboard/Products/index")
//   )
// );

// const Investors = Loadable(
//   lazyWithRetry(
//     async () => import("../components/pages/dashboard/Investors/index")
//   )
// );

// const SavedList = Loadable(
//   lazyWithRetry(
//     async () => import("../components/pages/dashboard/SavedList/index")
//   )
// );

// const Search = Loadable(
//   lazyWithRetry(
//     async () => import("../components/pages/dashboard/Search/index")
//   )
// );

// const AddInvestmentPreference = lazyWithRetry(
//   async () =>
//     import(
//       "../components/pages/dashboard/InvestmentPreference/AddInvestmentPreference/index"
//     )
// );

// const AddEducation = lazyWithRetry(
//   async () =>
//     import("../components/pages/dashboard/PeopleEdit/PeopleTab/Education/Add")
// );

// const ViewEducation = lazyWithRetry(
//   async () =>
//     import("../components/pages/dashboard/PeopleEdit/PeopleTab/Education/View")
// );

// const AddWork = lazyWithRetry(
//   async () =>
//     import("../components/pages/dashboard/PeopleEdit/PeopleTab/Work/AddWork")
// );

// const ViewWork = lazyWithRetry(
//   async () =>
//     import(
//       "../components/pages/dashboard/PeopleEdit/PeopleTab/Work/ViewPeopleWorkHistory"
//     )
// );

// const InvestorDatabase = Loadable(
//   lazyWithRetry(
//     async () =>
//       import("../components/pages/dashboard/Investors/InvestorsDatabase/index")
//   )
// );
// const ProductDatabase = Loadable(
//   lazyWithRetry(
//     async () =>
//       import("../components/pages/dashboard/Products/ProductDatabase/index")
//   )
// );
// const RoadShow = Loadable(
//   lazyWithRetry(
//     async () => import("../components/pages/dashboard/Investors/RoadShow/index")
//   )
// );

// const ProductRoadShow = Loadable(
//   lazyWithRetry(
//     async () => import("../components/pages/dashboard/Products/RoadShow/index")
//   )
// );

// // Extra pages ----------------------------------------------------------------
// const Policy = Loadable(
//   lazyWithRetry(
//     async () => import("../../src/components/pages/dashboard/policy/policy")
//   )
// );

// const Terms = Loadable(
//   lazyWithRetry(
//     async () => import("../../src/components/pages/dashboard/policy/Terms")
//   )
// );

// const EULA = Loadable(
//   lazyWithRetry(
//     async () => import("../../src/components/pages/dashboard/policy/EULA")
//   )
// );
// const VideoDataRoom = Loadable(
//   lazyWithRetry(
//     async () =>
//       import(
//         "../../src/components/pages/dashboard/Product/ViewDataRoom/ViewDataRoom"
//       )
//   )
// );
// // -----------------------------------------------------------------------------

// const Requests = Loadable(
//   lazyWithRetry(async () => import("../components/pages/dashboard/Requests"))
// );

// const ContentSharing = Loadable(
//   lazyWithRetry(
//     async () => import("../components/pages/dashboard/ContentSharing/index")
//   )
// );

const routes = [
  {
    path: "/",
    element: <Navigate to="/auth" />,
  },
  {
    path: "auth",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        index: true, // Make this the index route
        element: <Login />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "verify",
        element: <Verification />,
      },
    ],
  },
  {
    path: "dashboard/*",
    element: <DashboardLayout />,
  },

  {
    path: "/pass",
    element: (
      <GuestGuard>
        <PasswordLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "create-password",
        element: <ResetPassword />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
    ],
  },

  {
    path: "/dashboard/edit",
    element: (
      <AuthGuard>
        <EditProfileLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Edit />,
      },
      {
        path: "edit-profile",
        element: <Edit />,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
      },
    ],
  },
  {
    path: "roadshow",
    element: (
      <AuthGuard allowed={[protectedRoleType.INVESTOR]}>
        <RoadShow />
      </AuthGuard>
    ),
  },
  {
    path: "product-roadshow",
    element: (
      <AuthGuard>
        <ProductRoadShow allowed={[protectedRoleType.MANAGER]} />
      </AuthGuard>
    ),
  },
  // {
  //   path: "help-center",
  //   element: <HelpCenter />,
  // },
  // {
  //   path: "faq",
  //   element: <FAQ />,
  // },
  {
    path: "view-data-room/:id?",
    element: <VideoDataRoom />,
  },
  {
    path: "view-library",
    element: <SharedBlog module="Library" />,
  },
  {
    path: "view-resource",
    element: <SharedBlog module="Resource" />,
  },
  {
    path: "view-product",
    element: <SharedProduct />,
  },
  {
    path: "view-video",
    element: <SharedVideos />,
  },
  {
    path: "my-blogs/preview/:id?",
    element: <Preview />,
  },
  {
    path: "policy",
    element: <Policy />,
  },
  {
    path: "terms",
    element: <Terms />,
  },
  {
    path: "eula",
    element: <EULA />,
  },
  {
    path: "none",
    element: <None />,
  },
];

export default routes;

export const dashboardRoute = (
  <Suspense>
    <Routes>
      <Route
        // path="/"
        index
        element={
          <AuthGuard>
            <Company />
          </AuthGuard>
        }
      />
      <Route
        path="company/:id?"
        element={
          <AuthGuard>
            <Company />
          </AuthGuard>
        }
      />
      <Route
        path="edit-company"
        element={
          <AuthGuard allowed={[protectedRoleType.ISADMIN]}>
            <EditCompany />
          </AuthGuard>
        }
      />
      <Route
        path="edit-company/add-investment-preference"
        element={
          <AuthGuard allowed={[protectedRoleType.ISADMIN]}>
            <AddInvestmentPreference investmentType="company" />
          </AuthGuard>
        }
      />
      <Route
        path="edit-company/edit-investment-preference/:iid?"
        element={
          <AuthGuard allowed={[protectedRoleType.ISADMIN]}>
            <AddInvestmentPreference investmentType="company" />
          </AuthGuard>
        }
      />
      <Route
        path="people/:id?"
        element={
          <AuthGuard>
            <People />
          </AuthGuard>
        }
      />
      <Route
        path="people/edit/:id?"
        element={
          <AuthGuard allowed={[protectedRoleType.ISADMIN]}>
            <EditPeople />
          </AuthGuard>
        }
      />
      <Route
        path="people/edit/add-investment-preference/:people_id?"
        element={
          <AuthGuard allowed={[protectedRoleType.ISADMIN]}>
            <AddInvestmentPreference investmentType="people" />
          </AuthGuard>
        }
      />
      <Route
        path="people/edit/edit-investment-preference/:iid?"
        element={
          <AuthGuard allowed={[protectedRoleType.ISADMIN]}>
            <AddInvestmentPreference investmentType="people" />
          </AuthGuard>
        }
      />
      <Route
        path="people/edit/view-investment-preference/:id?"
        element={
          <AuthGuard allowed={[protectedRoleType.ISADMIN]}>
            <ViewInvestmentPreference investmentType="people" />
          </AuthGuard>
        }
      />
      <Route
        path="people/edit/add-education-detail/:people_id?"
        element={
          <AuthGuard allowed={[protectedRoleType.ISADMIN]}>
            <AddEducation />
          </AuthGuard>
        }
      />
      <Route
        path="people/edit/edit-education-detail/:id?"
        element={
          <AuthGuard allowed={[protectedRoleType.ISADMIN]}>
            <AddEducation />
          </AuthGuard>
        }
      />
      <Route
        path="people/edit/view-education-detail/:id?"
        element={
          <AuthGuard allowed={[protectedRoleType.ISADMIN]}>
            <ViewEducation />
          </AuthGuard>
        }
      />
      <Route
        path="people/edit/add-work-detail/:people_id?"
        element={
          <AuthGuard allowed={[protectedRoleType.ISADMIN]}>
            <AddWork />
          </AuthGuard>
        }
      />
      <Route
        path="people/edit/edit-work-detail/:id?"
        element={
          <AuthGuard allowed={[protectedRoleType.ISADMIN]}>
            <AddWork />
          </AuthGuard>
        }
      />
      <Route
        path="people/edit/view-work-detail/:id?"
        element={
          <AuthGuard allowed={[protectedRoleType.ISADMIN]}>
            <ViewWork />
          </AuthGuard>
        }
      />
      <Route
        path="product/:id?"
        element={
          <AuthGuard>
            <Product />
          </AuthGuard>
        }
      />
      <Route
        path="product/edit/:id?"
        element={
          <AuthGuard allowed={[protectedRoleType.ISADMIN]}>
            <EditProduct type="edit" />
          </AuthGuard>
        }
      />
      <Route
        path="company/investment-preference/:id?"
        element={
          <AuthGuard allowed={[protectedRoleType.ISADMIN]}>
            <ViewInvestmentPreference investmentType="company" />
          </AuthGuard>
        }
      />
      <Route
        path="product/add/:company_id?/:id?"
        element={
          <AuthGuard allowed={[protectedRoleType.ISADMIN]}>
            <EditProduct type="add" />
          </AuthGuard>
        }
      />
      <Route
        path="products"
        element={
          <AuthGuard>
            <Products />
          </AuthGuard>
        }
      />
      {/* <Route
        path="products/productdatabase"
        element={
          <AuthGuard allowed={[protectedRoleType.MANAGER]}>
            <ProductDatabase />
          </AuthGuard>
        }
      /> */}
      <Route
        path="products/productdatabase/:query?"
        element={
          <AuthGuard allowed={[protectedRoleType.MANAGER]}>
            <NewProductDatabase />
          </AuthGuard>
        }
      />

      <Route
        path="investors"
        element={
          <AuthGuard>
            <Investors />
          </AuthGuard>
        }
      />
      {/* <Route
        path="investors/investordatabase"
        element={
          <AuthGuard allowed={[protectedRoleType.INVESTOR]}>
            <InvestorDatabase />
          </AuthGuard>
        }
      /> */}
      <Route
        path="investors/investordatabase/:query?"
        element={
          <AuthGuard allowed={[protectedRoleType.INVESTOR]}>
            <NewInvestorDatabase />
          </AuthGuard>
        }
      />

      {/* <Route
        path="investors/investordatabase/people"
        element={
          <AuthGuard allowed={[protectedRoleType.INVESTOR]}>
            <InvestorDatabasePeople />
          </AuthGuard>
        }
      /> */}
      <Route
        path="resources"
        element={
          <AuthGuard allowed={[protectedRoleType.RESOURCES]}>
            <Resources />
          </AuthGuard>
        }
      />
      <Route
        path="resources/view/:id?/:category?"
        element={
          <AuthGuard allowed={[protectedRoleType.RESOURCES]}>
            <ViewResource />
          </AuthGuard>
        }
      />
      <Route
        path="library/:search_query?"
        element={
          <AuthGuard allowed={[protectedRoleType.LIBRARY]}>
            <Library />
          </AuthGuard>
        }
      />
      <Route
        path="library/view/:id?/:category?"
        element={
          <AuthGuard allowed={[protectedRoleType.LIBRARY]}>
            <ViewLibrary />
          </AuthGuard>
        }
      />
      <Route
        path="shared-content"
        element={
          <AuthGuard>
            <ContentSharing />
          </AuthGuard>
        }
      />
      <Route
        path="my-blogs"
        element={
          <AuthGuard>
            <ThoughtLeadership />
          </AuthGuard>
        }
      />

      <Route
        path="my-blogs/add"
        element={
          <AuthGuard>
            <ThoughtLeadershipAdd type="add" />
          </AuthGuard>
        }
      />

      <Route
        path="my-blogs/edit/:id"
        element={
          <AuthGuard>
            <ThoughtLeadershipAdd type="edit" />
          </AuthGuard>
        }
      />
      <Route
        path="my-blogs/view/:id"
        element={
          <AuthGuard>
            <ThoughtLeadershipView />
          </AuthGuard>
        }
      />
      <Route
        path="saved-list"
        element={
          <AuthGuard>
            <SavedList />
          </AuthGuard>
        }
      />
      <Route
        path="search"
        element={
          <AuthGuard>
            <Search />
          </AuthGuard>
        }
      />
      <Route
        path="requests/:query?"
        element={
          <AuthGuard>
            <Requests />
          </AuthGuard>
        }
      />
      <Route path="help-center" element={<HelpCenter />} />
      <Route path="faq" element={<FAQ />} />
    </Routes>
  </Suspense>
);
