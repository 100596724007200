import InputLayout from "../../../../atoms/InputLayout";
import PageCard from "../../../../atoms/PageCard";

const InvestmentPreference = ({ data }: any) => {
  const mapProductStrategyNames = (investmentItem: any) => {
    const data =
      investmentItem?.product_startegy?.map((strategy: any) => strategy.name) ||
      [];
    // console.log("data", data);
    return data.join(", ");
  };

  const mapProductSectore = (investmentItem: any) => {
    const data =
      investmentItem?.product_sectors?.map((strategy: any) => strategy.name) ||
      [];
    return data.join(", ");
  };

  const mapProductGeography = (investmentItem: any) => {
    const data =
      investmentItem?.product_geography?.map(
        (strategy: any) => strategy.name
      ) || [];
    return data.join(", ");
  };

  return (
    <>
      <h3 className="h3-j mb-3">
        {data?.investment_preference?.length <= 1
          ? "Investment Preference"
          : "Invetsment Preferences"}
      </h3>

      {data?.investment_preference?.length > 0 &&
        data?.investment_preference?.map((item: any) => (
          <PageCard
            customStyles={{ backgroundColor: "#F9F9F9" }}
            classes="p-4 mb-4 investement-mobile-responsive"
          >
            <div className="row g-3">
              <InputLayout>
                <div className="p-light-small">Product Structure</div>
                <div className="p-dark">
                  {item?.product_structure?.name || "--"}
                </div>
              </InputLayout>

              <InputLayout>
                <div className="p-light-small">Product Type</div>
                <div className="p-dark">{item?.product_type?.name || "--"}</div>
              </InputLayout>

              <InputLayout>
                <div className="p-light-small">Product Strategy</div>
                <div className="p-dark">{mapProductStrategyNames(item)}</div>
              </InputLayout>
            </div>

            <div className="row g-3 mt-2">
              <InputLayout>
                <div className="p-light-small">Product Sector</div>
                <div className="p-dark">{mapProductSectore(item)}</div>
              </InputLayout>

              <InputLayout>
                <div className="p-light-small">Product Geography</div>
                <div className="p-dark">{mapProductGeography(item)}</div>
              </InputLayout>

              <InputLayout>
                <div className="p-light-small">AUM Threshold</div>
                <p className="p-dark">
                  {" "}
                  {item?.min_aum && "$"}&nbsp;
                  {item?.min_aum?.toLocaleString("en-US")}&nbsp;
                  {item?.min_aum && item?.max_aum && "-"}&nbsp;
                  {item?.max_aum && "$"}&nbsp;
                  {item?.max_aum?.toLocaleString("en-US")}
                </p>
              </InputLayout>
            </div>

            {item?.min_track_record && item?.max_track_record && (
              <div className="row g-3">
                <InputLayout>
                  <div className="p-light-small"> Track Length Record</div>
                  <p className="p-dark">
                    {" "}
                    {item?.min_track_record}&nbsp;
                    {item?.min_track_record && item?.max_track_record && "-"}
                    &nbsp;
                    {item?.max_track_record}
                    {(item?.max_track_record || item?.min_track_record) &&
                      "Years"}
                  </p>
                </InputLayout>

                {/* <InputLayout>
              </InputLayout> */}
                {/* <InputLayout></InputLayout> */}
              </div>
            )}
          </PageCard>
        ))}
    </>
  );
};

export default InvestmentPreference;
