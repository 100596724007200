import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import TabStrip from "../../../../molecules/TabStrip";

import Address from "./Address";
import Insight from "./Insight";
import InvestmentPreference from "./InvestmentPreference";
import Product from "./Product";
import Socials from "./Socials";

const TabData = ({ companyType }) => {
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    setActiveTab(companyType === 1 ? "Investment Preference" : "Product");
  }, [companyType]);

  const onTabChange = (event, value) => {
    setActiveTab(value);
    sessionStorage.setItem("companyTab", value);
  };

  let dataArray = [
    {
      id: 0,
      label: "Product",
      view: <Product />,
    },
    {
      id: 1,
      label: "Investment Preference",
      view: <InvestmentPreference />,
    },
    {
      id: 2,
      label: "Address",
      view: <Address />,
    },
    {
      id: 3,
      label: "Insights & News",
      view: <Insight />,
    },
    {
      id: 4,
      label: "Social",
      view: <Socials />,
    },
  ];

  if (companyType === 1) {
    dataArray = dataArray.filter((item) => item.label !== "Product");
  }
  if (companyType === 2) {
    dataArray = dataArray.filter(
      (item) => item.label !== "Investment Preference"
    );
  }

  const _getComponet = (tabs) => {
    return dataArray
      .filter((item) => item.label == tabs)
      .map((item) => item.view)[0];
  };

  return (
    // customStyles={{ backgroundColor: "#F4F4F4" }}
    // <PageCard classes="p-0 mt-4 pt-2" >
    <div className="mt-4 pt-2">
      <TabStrip
        activeTab={activeTab}
        onTabChange={onTabChange}
        data={dataArray}
        allowedTabId="0"
        margin="1rem"
      />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ pt: 3 }}>{_getComponet(activeTab)}</Box>
      </Box>
    </div>

    //   </PageCard>
  );
};

export default TabData;
