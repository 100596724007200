import moment from "moment";
import React from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Resend from "../../../../assets/images/repeat.png";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import { postData } from "../../../../services";
import { loaderData } from "../../../../store/slices/loaderSlice";
import RightUpArrow from "../../../Icons/RightUpArrow";
import TypeDiv from "../../../atoms/TypeDiv/index";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import NoContentCard from "../../../molecules/NoContentCard/index";
import Pagination from "../../../molecules/Pagination";
import "../ContentSharing/ContentSharingPhone/sharingPhone.scss";
import ContentSharingShimmer from "../ContentSharing/Shimmer/ConetntSharing";
import ContentSharingPhoneShimmer from "../ContentSharing/Shimmer/ContentSharingPhones";

const RequestedProduct = ({
  requestedProduct,
  currentPageProduct,
  itemsPerPageProduct,
  handleItemsPerPageChangeProduct,
  handlePageChangeProduct,
  searchedValue,
  filters,
}) => {
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();
  const [cookies, setCookies] = useCookies(["t"]);
  const loadingDataValue = useSelector(loaderData);
  const CustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        <NoContentCard title="No Record Found" />
      </>
    );
  });
  const totalItems = requestedProduct?.total_record;

  const handleResend = async (data) => {
    const res = await postData({
      endpoint: "RequestAccess/resendRequest",
      data: {},
      params: { request_id: data.id },
      token: cookies.t,
    });

    if (res) {
      if (res?.data?.response === 400) {
        toast(
          "Your product request limit has been exceeded, only three requests are permitted.",
          { duration: 3000, style: errorStyles }
        );
      } else {
        // if(res?.data)
        toast.success("Request Resent Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
      }
      // queryClient.invalidateQueries("allUsers");
    }
  };

  const columns = [
    {
      field: "product_name",
      headerName: "Product Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.status === "Approved" ? (
              <div className="productNameWrapRequest">
                <div
                  style={{ fontWeight: 800, fontSize: "14px" }}
                  className="cursor-pointer"
                  onClick={() =>
                    navigate(`/dashboard/product/${params.row.product_id}`, {
                      state: { navigationType: "other" },
                    })
                  }
                >
                  <div className="company-rightUpWrapper">
                    <div
                      className="uniform-truncate"
                      style={{ maxWidth: "14vw" }}
                      title={params?.row?.product_name}
                    >
                      {params?.row?.product_name}
                    </div>
                    <div className="rightUpArrow">
                      <RightUpArrow />
                    </div>
                  </div>
                </div>
                <div style={{ color: "#6F767E", fontSize: "14px" }}>
                  {params?.row?.company_name}
                </div>
              </div>
            ) : (
              <div
                className="cursor-pointer"
                onClick={() =>
                  navigate(`/dashboard/company/${params.row.company_id}`, {
                    state: { navigationTab: "Products" },
                  })
                }
              >
                <div style={{ fontWeight: 800, fontSize: "14px" }}>
                  <div className="company-rightUpWrapper">
                    <div
                      className="uniform-truncate"
                      style={{ maxWidth: "14vw" }}
                      title={params?.row?.product_name}
                    >
                      {params?.row?.product_name}
                    </div>
                    <div className="rightUpArrow">
                      <RightUpArrow />
                    </div>
                  </div>
                </div>
                <div style={{ color: "#6F767E", fontSize: "14px" }}>
                  {" "}
                  {params?.row?.company_name}
                </div>
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "product_type",
      headerName: "Product Type",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <div className="company-rightUpWrapper">
              <div>{params?.row?.product_type}</div>
              {/* <div className="rightUpArrow">
                <RightUpArrow />
              </div> */}
            </div>
          </div>
        );
      },
    },
    // {
    //   field: "company_name",
    //   headerName: "Company Name",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <div
    //         style={{ fontSize: "14px" }}
    //         className="cursor-pointer"
    //         onClick={() =>
    //           navigate(`/dashboard/company/${params.row.company_id}`)
    //         }
    //       >
    //         <div>{params?.row?.company_name}</div>
    //       </div>
    //     );
    //   },
    // },
    {
      field: "comment",
      headerName: "Description",
      flex: 0.6,
    },
    {
      field: "date",
      headerName: "Requested Date",
      renderCell: (params) => {
        return (
          <span>{moment(params.row.date).format("MMM D, YYYY hh:mm a")}</span>
        );
      },
      flex: 0.6,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        return (
          <TypeDiv
            data={params.row.status}
            color={
              params.row.status === "Pending"
                ? "#FFD88D"
                : params.row.status === "Approved"
                ? "#B5E4CA"
                : "#FFB7B7"
            }
          />
        );
      },
      flex: 0.5,
    },
    {
      field: "Action",
      headerName: "Action",
      renderCell: (params) => {
        return (
          <>
            {params.row.status === "Pending" && (
              <>
                <span type="button" onClick={() => handleResend(params.row)}>
                  <img
                    src={Resend}
                    className="actions-icon-custom"
                    alt="Resend"
                    style={{ width: "26px", height: "25px" }}
                  />
                </span>
              </>
            )}
          </>
        );
      },
    },
  ];
  return (
    <>
      {screenWidth > 900 ? (
        <>
          {loadingDataValue ? (
            <ContentSharingShimmer />
          ) : (
            <CustomDatagrid
              rows={requestedProduct?.data || []}
              getRowId={(row) => row.id}
              columns={columns}
              hoverColor="ffffff"
              CustomNoRowsOverlay={CustomNoRowsOverlay}
              height={() => 70}
            />
          )}
        </>
      ) : (
        <>
          {loadingDataValue ? (
            <ContentSharingPhoneShimmer />
          ) : requestedProduct?.data?.length > 0 ? (
            <div className="content-sharing-phone-container">
              {requestedProduct?.data?.map((item, index) => {
                return (
                  <div className="sharing-details-phone-wrapper margin-request-conatiner">
                    <div className="sharing-details-content-conatiner no-border-request-container">
                      <div className="detail-contet-item-phone">
                        <div className="p-dark">Product Name</div>
                        <div className="p-light-small">
                          {item?.product_name ? item?.product_name : "--"}
                        </div>
                      </div>
                      <div className="detail-contet-item-phone">
                        <div className="p-dark">Product Type</div>
                        <div className="p-light-small">
                          {item?.product_type ? item?.product_type : "--"}
                        </div>
                      </div>
                      <div className="detail-contet-item-phone">
                        <div className="p-dark">Company Name</div>
                        <div className="p-light-small">
                          {item?.company_name ? item?.company_name : "--"}
                        </div>
                      </div>
                      <div className="detail-contet-item-phone">
                        <div className="p-dark">Comment</div>
                        <div className="p-light-small">
                          {item?.comment ? item?.comment : "--"}
                        </div>
                      </div>
                      <div className="detail-contet-item-phone">
                        <div className="p-dark">Requested Date</div>
                        <div className="p-light-small">
                          {item?.date
                            ? moment(item?.date).format("MMM D, YYYY hh:mm a")
                            : "--"}
                        </div>
                      </div>
                      <div className="detail-contet-item-phone">
                        <div className="p-dark">Status</div>
                        <div className="p-light-small">
                          <TypeDiv
                            style={{ color: "black" }}
                            data={item?.status}
                            color={
                              item?.status === "Pending"
                                ? "#FFD88D"
                                : item?.status === "Approved"
                                ? "#B5E4CA"
                                : "#FFB7B7"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="no-content">
              {searchedValue ||
              filters?.status ||
              filters?.startDate ||
              filters?.endDate ? (
                <NoContentCard title="Sorry! No Result Found" type="Search" />
              ) : (
                <NoContentCard title="No Data Found !" type="" />
              )}
            </div>
          )}
        </>
      )}
      {totalItems > 10 && (
        <Pagination
          itemsPerPage={itemsPerPageProduct}
          totalItems={totalItems}
          currentPage={currentPageProduct}
          onPageChange={handlePageChangeProduct}
          onItemsPerPageChange={handleItemsPerPageChangeProduct}
        />
      )}
    </>
  );
};
export default RequestedProduct;
