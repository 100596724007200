import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import "./style.scss";

const CircularDropdown = ({
  icon,
  border,
  color,
  bgColor,
  children,
  size = "50px",
  direction = "left",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getPosition = () => {
    const iconRect = dropdownRef.current.getBoundingClientRect();
    return {
      top: iconRect.bottom + window.scrollY,
      left: iconRect.left + window.scrollX,
    };
  };

  const positionStyles = {
    position: "absolute",
    top: "50px", // Adjust the position as needed
    right: "4px",
    backgroundColor: "white",
    border: "1px solid #efefef",
    borderRadius: "16px",
    padding: "1rem",
    zIndex: 1,
    minWidth: "150px",
  };

  if (direction === "right") {
    positionStyles.left = "4px";
  } else {
    positionStyles.right = "4px";
  }

  return (
    <div
      className="circular-dropdown"
      onClick={handleClick}
      style={{
        border: border,
        color: color,
        backgroundColor: bgColor,
        borderRadius: "50%",
        width: size,
        height: size,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        position: "relative",
      }}
      ref={dropdownRef}
    >
      <div className="circle-dropdown-icon-container">
        <i className={`d-flex align-items-center`}>{icon}</i>
      </div>

      {isOpen && (
        <div className="dropdown-content" style={positionStyles}>
          {children}
        </div>
      )}
    </div>
  );
};

CircularDropdown.propTypes = {
  icon: PropTypes.any,
  border: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  children: PropTypes.node,
};

export default CircularDropdown;
