import Mail from "../../Icons/Mail";
import Heading from "../../atoms/Heading";
import ContactUsForm from "../../organisms/forms/ContactUsForm";
import styles from "./contactTemplate.module.scss"; // Import your CSS file for styling

const ContentTemplate = () => {
  return (
    <div className={styles.contact_template}>
      <div className={styles.form_container}>
        <ContactUsForm />
      </div>
      <div className={styles.contact_info_container}>
        <Heading title="Contact Us" type="small" />
        {/* <div className={styles.info}>
          <i className="d-flex">
            <Phone />
          </i>
          <span>+1 212-960-3316</span>
        </div> */}
        <div className={styles.info}>
          <i className="d-flex">
            <Mail />
          </i>
          <span>info@primealpha.com</span>
        </div>
      </div>
    </div>
  );
};

export default ContentTemplate;
