import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import useScreenWidth from "../../../hooks/useScreenwidth";
import { dashboardRoute } from "../../../routes/index";
import Footer from "../../molecules/Footer";
import Navbar from "../../molecules/Navbar";
import Sidebar from "../../molecules/Sidebar";
import styles from "./dashboard_layout.module.scss";

const DashboardLayout = () => {
  const [isActive, setIsActive] = useState(true);
  const screenWidth = useScreenWidth();

  const location = useLocation();

  const toggleActiveState = () => {
    setIsActive(!isActive);
  };
  const sidebarRef = useRef<HTMLDivElement>(null);
  const navbarRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node) &&
        navbarRef.current &&
        !navbarRef.current.contains(event.target as Node)
      ) {
        setIsActive(false);
      }
    };
    const handleScroll = () => {
      if (screenWidth < 900) setIsActive(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [screenWidth]);
  return (
    <>
      <Navbar
        isActive={isActive}
        setIsActive={setIsActive}
        toggleActive={toggleActiveState}
        searchRequired={false}
        navbarRef={screenWidth < 900 ? navbarRef : null}
      />
      <div className={styles.dashboard_container}>
        <Sidebar
          isActive={isActive}
          setIsActive={setIsActive}
          sidebarRef={screenWidth < 900 ? sidebarRef : null}
        />
        <div
          className={styles.dashboard_content_container}
          style={{
            minHeight:
              location.pathname ===
              "/dashboard/investors/investordatabase/Roadshow"
                ? "100vh"
                : "130vh",
          }}
        >
          <Outlet />
          {dashboardRoute}
        </div>
      </div>

      <div style={{ marginTop: "auto" }}>
        <Footer />
      </div>
    </>
  );
};

export default DashboardLayout;
