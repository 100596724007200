import React, { CSSProperties } from "react";
import "./IconCircle.scss";
interface IconCircleProps {
  icon: any; // ReactNode allows any valid JSX, including an <i> element
  color?: string;
  size?: number;
  borderRadius?: any;
  iconColor?: any;
  iconSize?: string;
  border?: string;
  isClickable?: boolean;
  handleClick?: () => void;
}

const IconCircle: React.FC<IconCircleProps> = ({
  icon,
  color,
  size = 50,

  borderRadius = "50%",
  iconColor = "#1A1D1F",
  iconSize,
  border,
  isClickable,
  handleClick,
}) => {
  const containerStyle: CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: size,
    height: size,
    borderRadius: borderRadius,
    border: border,
    background: color,
    flexShrink: 0,
    cursor: isClickable ? "pointer" : "",
  };

  const iconStyle: CSSProperties = {
    fontSize: iconSize || "1.2em", // Adjust the font size as needed
    color: `${iconColor} !important`, // You can customize the color of the icon separately
    height: "1em",
    width: "1em",
    lineHeight: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div
      style={containerStyle}
      className="address-icon-center"
      onClick={handleClick}
    >
      <span style={iconStyle}>{icon}</span>
    </div>
  );
};

export default IconCircle;
