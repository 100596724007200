import React, { ReactNode } from "react";
import styles from "./content_container.module.scss";

interface ContentContainerProps {
  children?: ReactNode;
  classes?: string;
  type?: string;
}

const ContentContainer: React.FC<ContentContainerProps> = ({
  children,
  classes,
  type,
}) => {
  return (
    <div
      className={`${
        type === "dashboard"
          ? styles.dashboardContentContainer
          : styles.contentContainer
      } ${classes}`}
    >
      {children}
    </div>
  );
};

export default ContentContainer;
