import { useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import InputLayout from "../../../../../atoms/InputLayout";
import { SmallLoader } from "../../../../../atoms/Loader";

import "./dataroom.scss";

const ViewData = ({ data, id }) => {
  const [loading, setLoading] = useState(true);

  const getEmbedInfo = (link) => {
    if (link) {
      const buzzsproutRegex = /buzzsprout\.com\/(\d+)\/(\d+)/;

      const buzzsproutMatch = link.match(buzzsproutRegex);

      const vimeoRegex = /vimeo\.com\/(?:video\/)?(\d+)/;

      const vimeoMatch = link.match(vimeoRegex);

      if (buzzsproutMatch) {
        const embedUrl = `${link}-tet?client_source=small_player&iframe=true`;
        return {
          isBuzzsprout: true,
          isVimeo: false,
          embedUrl: embedUrl,
        };
      } else if (vimeoMatch) {
        const embedUrl = `https://player.vimeo.com/video/${vimeoMatch[1]}`;
        return {
          isBuzzsprout: false,
          isVimeo: true,
          embedUrl: embedUrl,
        };
      } else {
        return {
          isBuzzsprout: false,
          isVimeo: false,
          embedUrl: null,
        };
      }
    } else {
      return {
        isBuzzsprout: false,
        isVimeo: false,
        embedUrl: null,
      };
    }
  };

  const { isBuzzsprout, isVimeo, embedUrl } = getEmbedInfo(data?.link);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <>
      {data?.link && (
        <div
          className="video-div mb-4 mt-3"
          style={{ height: isBuzzsprout ? "200px" : "335px" }}
        >
          {!loading && embedUrl ? (
            <iframe
              src={embedUrl}
              title="Embedded Video"
              frameBorder="0"
              onLoad={handleLoad}
              style={{
                borderRadius: isVimeo && "10px",
                backgroundColor: isVimeo && "black",
              }}
            ></iframe>
          ) : (
            <SmallLoader />
          )}

          <iframe
            src={embedUrl || ""}
            title="Embedded Video"
            frameBorder="0"
            id="hidden-iframe"
            onLoad={handleLoad}
          ></iframe>
        </div>
      )}

      {data?.type === "Podcast" && data?.document && (
        <div className="py-3 mb-3">
          <AudioPlayer
            header={
              <>
                <div className="audio-image-div">
                  <img
                    src={data?.podcast_thumbnail}
                    alt=""
                    width="100%"
                    height="100%"
                  />
                </div>
              </>
            }
            autoPlay={false}
            src={data?.document}
            onPlay={(e) => console.log("onPlay")}
            showJumpControls={false}
            autoPlayAfterSrcChange={false}
            // other props here
          />
        </div>
      )}

      {/* <div className="row g-3">
        <InputLayout>
          <label htmlFor="">Name</label>
          <p className="view-muted">{data?.name}</p>
        </InputLayout>

        <InputLayout>
          <label htmlFor="">Updation Date</label>
          <p className="view-muted">
            {" "}
            {moment.utc(data?.upload_date).format("DD MMM YYYY")}
          </p>
        </InputLayout>
      </div> */}

      {/* <div className="row g-3 mt-2">
        <InputLayout>
          <label htmlFor="">Type</label>
          <p className="view-muted">{data?.type}</p>
        </InputLayout>
        {data?.sub_type && (
          <InputLayout>
            <label htmlFor="">Sub Type</label>
            <p className="view-muted"> {data?.sub_type}</p>
          </InputLayout>
        )}

        {!data.sub_type && (
          <InputLayout>
            <label htmlFor="">Tags</label>
            <p className="view-muted">{data?.tag?.join(", ")}</p>
          </InputLayout>
        )}
      </div> */}

      {/* {data?.document && (
        <div className="row g-3 mt-2">
          <InputLayout>
            <label htmlFor="">Link</label>
            {/* <p className="view-muted">{data?.document}</p> */}
      {/* <a href={data?.document} target="__blank" className="view-muted">
              {getFileName(data?.document)}
            </a>
          </InputLayout>
        </div>
      )} */}

      {/* {data?.sub_type && (
        <div className="row g-3 mt-2">
          <InputLayout>
            <label htmlFor="">Tags</label>
            <p className="view-muted">{data?.tag?.join(", ")}</p>
          </InputLayout>
        </div>
      )} */}

      <div className="row g-3">
        {data?.description && (
          <InputLayout>
            <label htmlFor="" className="mb-1">
              Description
            </label>

            <div className="quill quill-change mb-3">
              <div
                className="ql-container ql-snow ql-no-border "
                style={{ background: "white" }}
              >
                <div
                  className="ql-editor blog-content ps-0"
                  style={{ padding: "0 !important" }}
                  dangerouslySetInnerHTML={{ __html: data.description }}
                ></div>
              </div>
            </div>
          </InputLayout>
        )}
      </div>
    </>
  );
};

export default ViewData;
