import React from "react";
import "./BannerImages.scss";
interface BannerImageProps {
  imageUrl: any;
  altText: string;
  children?: React.ReactNode;
  type?: string;
  style?: any;
}

const BannerImage: React.FC<BannerImageProps> = ({
  imageUrl,
  altText,
  children,
  type,
  style,
}) => {
  return (
    <div className="banner-image">
      <img
        src={imageUrl}
        alt="blogImages"
        className={`${type === "cover" ? "coverStyle" : "bannerStyle"}`}
        style={style}
      />
      <div className="overlay">{children}</div>
    </div>
  );
};

export default BannerImage;
