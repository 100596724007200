import PropTypes from "prop-types";
const DeleteRed = ({ fill, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <g clipPath="url(#clip0_2699_14391)">
        <path
          d="M17.5262 3.33333H14.9391C14.7454 2.39284 14.2329 1.54779 13.488 0.940598C12.7432 0.333408 11.8114 0.0012121 10.8499 0L9.18078 0C8.21922 0.0012121 7.28747 0.333408 6.54259 0.940598C5.7977 1.54779 5.28523 2.39284 5.09153 3.33333H2.50446C2.28313 3.33333 2.07086 3.42113 1.91435 3.57741C1.75785 3.73369 1.66992 3.94565 1.66992 4.16667C1.66992 4.38768 1.75785 4.59964 1.91435 4.75592C2.07086 4.9122 2.28313 5 2.50446 5H3.339V15.8333C3.34033 16.938 3.78037 17.997 4.56262 18.7782C5.34486 19.5593 6.40544 19.9987 7.5117 20H12.5189C13.6252 19.9987 14.6858 19.5593 15.468 18.7782C16.2503 17.997 16.6903 16.938 16.6916 15.8333V5H17.5262C17.7475 5 17.9598 4.9122 18.1163 4.75592C18.2728 4.59964 18.3607 4.38768 18.3607 4.16667C18.3607 3.94565 18.2728 3.73369 18.1163 3.57741C17.9598 3.42113 17.7475 3.33333 17.5262 3.33333ZM9.18078 1.66667H10.8499C11.3675 1.6673 11.8723 1.82781 12.295 2.1262C12.7176 2.42459 13.0375 2.84624 13.2108 3.33333H6.81986C6.9931 2.84624 7.31299 2.42459 7.73568 2.1262C8.15836 1.82781 8.66313 1.6673 9.18078 1.66667ZM15.0226 15.8333C15.0226 16.4964 14.7588 17.1323 14.2893 17.6011C13.8197 18.0699 13.1829 18.3333 12.5189 18.3333H7.5117C6.8477 18.3333 6.21089 18.0699 5.74137 17.6011C5.27185 17.1323 5.00808 16.4964 5.00808 15.8333V5H15.0226V15.8333Z"
          fill={fill || "#BE0F00"}
          className={className}
        />
        <path
          d="M8.34626 14.9987C8.56759 14.9987 8.77986 14.9109 8.93636 14.7546C9.09287 14.5983 9.18079 14.3864 9.18079 14.1654V9.16536C9.18079 8.94435 9.09287 8.73239 8.93636 8.57611C8.77986 8.41983 8.56759 8.33203 8.34626 8.33203C8.12492 8.33203 7.91266 8.41983 7.75615 8.57611C7.59964 8.73239 7.51172 8.94435 7.51172 9.16536V14.1654C7.51172 14.3864 7.59964 14.5983 7.75615 14.7546C7.91266 14.9109 8.12492 14.9987 8.34626 14.9987Z"
          fill={fill || "#BE0F00"}
          className={className}
        />
        <path
          d="M11.6842 14.9987C11.9055 14.9987 12.1178 14.9109 12.2743 14.7546C12.4308 14.5983 12.5187 14.3864 12.5187 14.1654V9.16536C12.5187 8.94435 12.4308 8.73239 12.2743 8.57611C12.1178 8.41983 11.9055 8.33203 11.6842 8.33203C11.4628 8.33203 11.2505 8.41983 11.094 8.57611C10.9375 8.73239 10.8496 8.94435 10.8496 9.16536V14.1654C10.8496 14.3864 10.9375 14.5983 11.094 14.7546C11.2505 14.9109 11.4628 14.9987 11.6842 14.9987Z"
          fill={fill || "#BE0F00"}
          className={className}
        />
      </g>
      <defs>
        <clipPath id="clip0_2699_14391">
          <rect width="20.0289" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
DeleteRed.propTypes = {
  fill: PropTypes.string, // expects a string for fill color
  className: PropTypes.string, // expects a string for additional class name
};
export default DeleteRed;
