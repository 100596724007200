import { useNavigate } from "react-router-dom";
import UserProfileCard from "../../../../molecules/UserProfileCard";
import "./component.scss";

const CompanyTeam = ({ data }: any) => {
  const navigate = useNavigate();
  const investment = data?.teams?.investment_team;
  const marketing = data?.teams?.marketting_team;
  const operation = data?.teams?.operation_team;

  return (
    <>
      {investment.length > 0 && (
        <>
          <h3 className="h3-j mb-m">Investment Team</h3>
          <div className="company_team">
            {investment?.map((item: any) => (
              <UserProfileCard
                key={item.id}
                profileName={item.name}
                profilePosition={item.job_title}
                onClick={() => navigate(`/dashboard/people/${item.id}`)}
                profilePicture={item.profile_photo}
              />
            ))}
          </div>
        </>
      )}
      {marketing.length > 0 && (
        <>
          <h3 className="h3-j mb-m ">Marketing Team</h3>
          <div className="company_team">
            {marketing?.map((item: any) => (
              <UserProfileCard
                key={item.id}
                profileName={item.name}
                profilePosition={item.job_title}
                onClick={() => navigate(`/dashboard/people/${item.id}`)}
                profilePicture={item.profile_photo}
              />
            ))}
          </div>
        </>
      )}
      {operation.length > 0 && (
        <>
          <h3 className="h3-j mb-m ">Operation Team</h3>
          <div className="company_team">
            {operation?.map((item: any) => (
              <UserProfileCard
                key={item.id}
                profileName={item.name}
                profilePosition={item.job_title}
                onClick={() => navigate(`/dashboard/people/${item.id}`)}
                profilePicture={item.profile_photo}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default CompanyTeam;
