import moment from "moment";
import LinkIcon from "../../../../Icons/linkIcon";
import "../people.scss";

const Keylinks = ({ data }: any) => {
  const handleClick = (data: any) => {
    window.open(data.link, "_blank");
  };

  return (
    <>
      <h3 className="h3-j mb-m">Keylinks</h3>

      <div className="keylinkContainer">
        {data?.map((item: any) => (
          <div className="keylink-card" onClick={() => handleClick(item)}>
            <div className="keylink-image">
              {/* <div style={{ height: "30px" }}> */}
              <LinkIcon className="keyLink-image-size" />
              {/* </div> */}
            </div>

            <div className="keylink-col">
              <div className="h4-j">{item.title}</div>

              <p className="p-dark light mb-xs">{item.description}</p>
              <p className="p mb-1">
                {moment.utc(item?.created_date).format("MMM D, YYYY")}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Keylinks;
