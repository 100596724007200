import Excel from "../../../../../../assets/images/docTypes/Excel.png";
import Pdf from "../../../../../../assets/images/docTypes/Pdf.png";
import Podcast from "../../../../../../assets/images/docTypes/Podcast.png";
import Other from "../../../../../../assets/images/docTypes/other2.png";
import Ppt from "../../../../../../assets/images/docTypes/ppt.png";

const DataRoomImage = ({ thumbnail, type, sub_type }: any) => {
  return (
    <>
      {sub_type === "Pdf" && (
        <div className="data-room-img">
          <img src={Pdf} alt="Pdf" />
        </div>
      )}

      {sub_type === "Excel" && (
        <div className="data-room-img">
          <img src={Excel} alt="Excel" />
        </div>
      )}

      {sub_type === "Ppt" && (
        <div className="data-room-img">
          <img src={Ppt} alt="Ppt" />
        </div>
      )}

      {sub_type === "Others" && (
        <div className="data-room-img">
          <img src={Other} alt="Others" />
        </div>
      )}

      {(type === "Podcast" || sub_type === "Podcast") && (
        <div className="data-room-img">
          <img src={Podcast} alt="Podcast" />
        </div>
      )}

      {(type === "Video" || sub_type === "Video") && (
        <div className="data-room-img">
          <img src={thumbnail} alt="Video" />
        </div>
      )}
    </>
  );
};

export default DataRoomImage;
