export const getFileName = (file) => {
  if (file) {
    const urlObject = new URL(file);
    const filename = urlObject.pathname.split("/").pop();
    const afterUnderscore = filename.split("_")[1];
    const decodedString = decodeURIComponent(afterUnderscore);
    return decodedString;
  } else {
    return "";
  }
};
