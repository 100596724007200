import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { getData } from "../../../../../../services";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import InputLayout from "../../../../../atoms/InputLayout";
import PageCard from "../../../../../atoms/PageCard";
import TypeDiv from "../../../../../atoms/TypeDiv";
import styles from "./index.module.scss";

const Product = ({ company_id }) => {
  const [cookies] = useCookies(["t"]);
  const dispatch = useDispatch();
  const [productData, setProductData] = useState();

  const fetchProductData = async () => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "Product/getCompanyProducts",
      params: {
        company_id: company_id,
        page: 1,
        page_limit: 1000,
      },
      token: cookies.t,
    });

    if (res) {
      dispatch(setLoading(false));
      setProductData(res);
    }
  };

  useEffect(() => {
    fetchProductData();
  }, [company_id]);

  return (
    <div>
      {productData?.data?.map((item) => {
        return (
          <div>
            <PageCard
              customStyles={{ backgroundColor: "#F9F9F9" }}
              classes="p-4 mb-4"
            >
              <div>
                <TypeDiv data={item?.category_name} classes="mb-2" />
                <h3 className="h2-j mb-3 cursor-pointer">{item?.name}</h3>

                <div className={styles.productDetailContainer}>
                  {item.structure_name && (
                    <InputLayout>
                      <div className="p-light-small">Structure</div>

                      <div className="p-dark-small">{item?.structure_name}</div>
                    </InputLayout>
                  )}

                  <InputLayout>
                    <div className="p-light-small">Type</div>
                    <div className="p-dark-small">
                      {item?.type_name || "--"}
                    </div>
                  </InputLayout>
                  <InputLayout>
                    <div className="p-light-small">Strategy</div>
                    <div className="p-dark-small">
                      {item?.strategy_name
                        ? [item?.strategy_name].join(", ")
                        : "--"}
                    </div>
                  </InputLayout>
                  <InputLayout>
                    <div className="p-light-small">Geography</div>

                    <p className="p-dark-small">
                      {item?.geography_name
                        ? [item?.geography_name].join(", ")
                        : "--"}
                    </p>
                  </InputLayout>

                  <InputLayout>
                    <div className="p-light-small">Sector</div>
                    <p className="p-dark-small">
                      {item?.sector_name ? [item?.sector_name].join(",") : "--"}
                    </p>
                  </InputLayout>
                  <InputLayout>
                    <div className="p-light-small">Inception</div>
                    <div className="p-dark-small">
                      {item?.inception
                        ? moment.utc(item?.inception).format("MMM YYYY")
                        : "--"}
                    </div>
                  </InputLayout>

                  {item?.product_details?.target_close_date && (
                    <InputLayout>
                      <div className="p-light-small">Target Closed</div>
                      <div className="p-dark-small">
                        {item?.product_details?.target_close_date
                          ? moment
                              .utc(item?.product_details?.target_close_date)
                              .format("MMM YYYY")
                          : "--"}
                      </div>
                    </InputLayout>
                  )}

                  {item?.product_details?.funding_goal > 0 && (
                    <InputLayout>
                      <div className="p-light-small">Funding Goal</div>

                      <div className="p-dark-small">
                        {item?.product_details?.funding_goal
                          ? `$${item.product_details.funding_goal} MM`
                          : "--"}
                      </div>
                    </InputLayout>
                  )}

                  <InputLayout>
                    <div className="p-light-small">Funding Status</div>
                    <div
                      className={`p-dark-small ${
                        item?.status === "Active"
                          ? "status-active-small"
                          : "status-inactive-small"
                      }`}
                    >
                      {" "}
                      {item?.status}
                    </div>
                  </InputLayout>
                </div>
              </div>

              {item?.description && (
                <InputLayout>
                  <div className="p-light-small">Description</div>
                  <div
                    className="p-dark-small"
                    style={{ overflowWrap: "break-word" }}
                  >
                    {item?.description.substring(0, 250)}...
                  </div>
                </InputLayout>
              )}
            </PageCard>
          </div>
        );
      })}
    </div>
  );
};

export default Product;
