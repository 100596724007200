// App.js
import { useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useRoutes } from "react-router-dom";
import ScrollToTop from "./SrollToTop";
import "./assets/scss/style.scss";
import GlobaLoader from "./components/atoms/GlobalLoader";
import routes from "./routes";
import { setPeopleData } from "./store/slices/peopleSlice";

function App() {
  const content = useRoutes(routes);
  const userInfo = useSelector((state) => state.peopleData?.peopleData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [, , removeCookies] = useCookies(["t", "un", "email", "cuid"]);
  const cookieNames = ["t", "un", "email", "cuid", "cid", "role"];

  const handleLogout = () => {
    setPeopleData({});
    cookieNames.forEach((cookieName) => {
      removeCookies(cookieName, { path: "/" });
    });
    dispatch(setPeopleData({ peopleData: { response: null } }));
    navigate("/", { replace: true });
  };

  useEffect(() => {
    if (userInfo.response === 404) {
      handleLogout();
    }
  }, [userInfo]);

  const loadingBarRef = useRef(null);
  const loadingData = useSelector((state) => state.topLoader.loading);

  useEffect(() => {
    if (loadingData) {
      loadingBarRef.current.continuousStart();
      setTimeout(() => {
        loadingBarRef.current.complete();
      }, 500);
    }
  }, [loadingData]);

  return (
    <>
      <GlobaLoader loadingBarRef={loadingBarRef} />
      <ScrollToTop />
      {content}
      <Toaster />
    </>
  );
}

export default App;
