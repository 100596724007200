import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import SelectorDiv from "../../../../molecules/SelectorDiv";
import InvestmentProcess from "./ProductOverviewParts/InvestmentProcess";
import ProductDetails from "./ProductOverviewParts/ProductDetails";
import Strategy from "./ProductOverviewParts/Strategy";
import TrackAum from "./ProductOverviewParts/TrackAum";
import Video from "./Video";
import "./components.scss";

const ProductOverview = ({ data, type }) => {
  const [expanded, setExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState(
    data?.product_overview?.strategy_data
      ? "Strategy"
      : data?.product_overview?.investment_data
      ? "Investment Process"
      : data?.product_overview?.details_data
      ? "Product Details"
      : "Track Record/AUM"
  );

  useEffect(() => {
    const selected = data?.product_overview?.strategy_data
      ? "Strategy"
      : data?.product_overview?.investment_data
      ? "Investment Process"
      : data?.product_overview?.details_data
      ? "Product Details"
      : "Track Record/AUM";
    setActiveTab(selected);
  }, [data]);
  let dataArray = [];

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  if (data?.product_overview?.strategy_data) {
    dataArray.push({
      id: 0,
      label: "Strategy",
      view: <Strategy data={data?.product_overview?.product_strategy[0]} />,
    });
  }

  if (data?.product_overview?.investment_data) {
    dataArray.push({
      id: 1,
      label: "Investment Process",
      view: (
        <InvestmentProcess
          data={data?.product_overview?.investment_process[0]}
        />
      ),
    });
  }

  if (data?.product_overview?.details_data) {
    dataArray.push({
      id: 2,
      label: "Product Details",
      view: (
        <ProductDetails data={data?.product_overview?.product_details[0]} />
      ),
    });
  }

  const netAumCondition =
    data?.product_overview?.net_aum?.length > 0 &&
    data?.product_overview?.show_net_aum;

  const monthlyAumCondition =
    Array.isArray(data?.product_overview?.monthly_aum) &&
    data?.product_overview?.monthly_aum.length > 0 &&
    data?.product_overview?.show_monthly_aum;

  if (netAumCondition || monthlyAumCondition) {
    dataArray.push({
      id: 3,
      label: "Track Record/AUM",
      view: <TrackAum data={data?.product_overview} />,
    });
  }

  const _getComponent = (tabs) => {
    return dataArray
      .filter((item) => item.label === tabs)
      .map((item) => item.view)[0];
  };

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };

  const normalData =
    data?.product_configuration[0]?.description === "<p><br></p>"
      ? "__"
      : data?.product_configuration[0]?.description;

  const displayText = expanded
    ? normalData || "__"
    : (normalData || "__").substring(0, 650); // Display first 100 characters
  return (
    <>
      {displayText && (
        <>
          {data?.video?.length > 0 && (
            <div className="mb-5">
              <Video
                type="view"
                data={data?.video}
                visibility_type={type}
                prod_name={data?.product_configuration[0]?.name}
                company_id={data?.product_configuration[0]?.company_id}
              />
            </div>
          )}
          {/* <p className="p" style={{ overflowWrap: "break-word" }}> */}
          <div className="quill quill-change">
            <div
              className="ql-container ql-snow ql-no-border"
              style={{ background: "white" }}
            >
              <div
                className="ql-editor blog-content"
                style={{ padding: "0 !important" }}
                dangerouslySetInnerHTML={{
                  __html: displayText,
                }}
              ></div>
            </div>
          </div>

          {normalData && normalData.length > 650 && (
            <span onClick={toggleExpand} className="view-more">
              {expanded ? "View less" : "View more"}
            </span>
          )}
          {/* </p> */}

          {dataArray?.length > 0 && (
            <div className="data-container mt-5">
              <SelectorDiv
                activeTab={activeTab}
                onTabChange={onTabChange}
                data={dataArray}
              />
              <div className="product-overview-container">
                <Box sx={{ width: "100%", minHeight: "40rem" }}>
                  <Box>{_getComponent(activeTab)}</Box>
                </Box>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ProductOverview;
