import { useState } from "react";
import ArrowDown from "../../../../../../Icons/ArrowDown";
import HeadingDropDown from "../../../../../../atoms/HeadingDropDown";
import { DateSelector } from "../../../../../../molecules/MonthYearSelector";
import styles from "./styles.module.scss";

const DateRangeFilter = ({ handleFilter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [afterDateForValidation, setAfterDateForValidation] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleReset = () => {
    setStartDate(null);
    setAfterDateForValidation(null);
    setEndDate(null);
    handleFilter({
      start_date: null,
      end_date: null,
    });
    setIsConfirmed(false);
    setIsOpen(false);
  };

  const handleConfirm = () => {
    if (startDate || endDate) {
      setIsConfirmed(true);
    }
    const data = {
      start_date: startDate,
      end_date: endDate,
    };
    handleFilter(data);
    setIsOpen(false);
  };

  return (
    <>
      <HeadingDropDown
        title="Date Filter"
        icon={<ArrowDown />}
        setIsOpen={setIsOpen}
        IsOpen={isOpen}
        active={isConfirmed}
        type="large"
      >
        <div>
          <div>
            <label htmlFor="after-date">After Date</label>
            <DateSelector
              placeholder="After Date"
              onChange={(date) => {
                setAfterDateForValidation(date);
                setStartDate(date);
              }}
              selectedDate={startDate}
            />
          </div>
          <div className="mt-3">
            <label htmlFor="before-date">Before Date</label>
            <DateSelector
              placeholder="Before Date"
              onChange={(date) => {
                setEndDate(date);
              }}
              selectedDate={endDate}
              minDateData={afterDateForValidation}
            />
          </div>
          <div className={styles.bottomContainer}>
            <span
              className="medium cursor-pointer"
              onClick={() => handleReset()}
            >
              Reset
            </span>
            <button
              className={`btn btn-primary ${styles.customBtn}`}
              onClick={() => handleConfirm()}
            >
              Confirm
            </button>
          </div>
        </div>
      </HeadingDropDown>
    </>
  );
};

export default DateRangeFilter;
