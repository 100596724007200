import { useEffect, useRef, useState } from "react";
import useScreenWidth from "../../../hooks/useScreenwidth";
import style from "./index.module.scss";

const HeadingDropDown = ({
  title,
  icon,
  children,
  type,
  IsOpen,
  setIsOpen,
  styles,
  active,
  id,
  dropdownPosition,
}) => {
  const headDropdownRef = useRef(null);
  const [typeRight, setTypeRight] = useState("");
  const contentRef = useRef(null);
  const screenWidth = useScreenWidth();
  const [rightElement, setRightElement] = useState(false);
  const handleClickOutside = (event) => {
    if (
      headDropdownRef.current &&
      !headDropdownRef.current.contains(event.target) &&
      !event.target.closest(".MuiDateCalendar-root")
    ) {
      setIsOpen(false);
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  useEffect(() => {
    if (IsOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [IsOpen]);
  // console.log("varun :", { id: id, dropdownPosition: dropdownPosition });

  useEffect(() => {
    setIsOpen(false);
  }, [screenWidth]);

  useEffect(() => {
    setTypeRight("");
    if (IsOpen) {
      const content = contentRef.current;
      const computedStyles = window.getComputedStyle(content);
      const right = computedStyles.getPropertyValue("right");
      const integerValue = parseFloat(right);
      setTypeRight("");
      if (integerValue < 0 || integerValue == 0) {
        setTypeRight("right");
      } else {
        setTypeRight("");
      }
    }
  }, [IsOpen, screenWidth]);

  return (
    <div
      ref={headDropdownRef}
      className={`${
        type === "small"
          ? style.smallHeadingDropdownWrap
          : type === "large"
          ? style.largeHeadingDropdownWrap
          : ""
      }`}
    >
      <div
        className={`${style.labelWrap} ${
          active || IsOpen ? style.activeLabel : ""
        }`}
        onClick={() => setIsOpen(!IsOpen)}
        id={id}
      >
        <label className={style.title}>{title}</label>
        {active && (
          <div
            className={
              type === "small" ? "small-indicator-dot" : "indicator-dot"
            }
          >
            {" "}
          </div>
        )}
        <i className={`d-flex`}>{icon}</i>
      </div>
      {IsOpen && (
        <div
          className={`${style.positionStyles} dropdown-content`}
          style={{
            ...styles,
            left:
              screenWidth > 1230
                ? `${
                    headDropdownRef.current
                      ? headDropdownRef.current.offsetLeft +
                        headDropdownRef.current.offsetWidth -
                        245
                      : 0
                  }px`
                : screenWidth > 1230
                ? dropdownPosition?.left + 300 - 18 * 16 <
                  window.innerWidth - 18 * 16
                  ? dropdownPosition?.left - 18 * 16 > 0
                    ? `calc(${dropdownPosition?.left}px - 17.5rem)`
                    : `0.1px`
                  : "auto"
                : dropdownPosition?.left + 300 < window.innerWidth
                ? dropdownPosition?.left > 0
                  ? `calc(${dropdownPosition?.left}px - 0rem)`
                  : "10px"
                : "auto",
            top: `${
              headDropdownRef.current
                ? headDropdownRef.current.offsetTop +
                  headDropdownRef.current.offsetHeight
                : 0
            }px`,
            right:
              dropdownPosition?.left + 300 > window.innerWidth
                ? "10px"
                : "auto",
          }}
          // style={{
          //   left: `${
          //     headDropdownRef.current
          //       ? headDropdownRef.current.offsetLeft +
          //         headDropdownRef.current.offsetWidth -
          //         245
          //       : 0
          //   }px`,
          //   top: `${
          //     headDropdownRef.current
          //       ? headDropdownRef.current.offsetTop +
          //         headDropdownRef.current.offsetHeight
          //       : 0
          //   }px`,
          // }}
          onClick={(event) => {
            event.stopPropagation();
          }}
          ref={contentRef}
        >
          {children}
        </div>
      )}
    </div>
  );
};
export default HeadingDropDown;
