import "./styles.scss";
const ShimmerProduct = () => {
  return (
    <div className="shimmerProduct-container">
      <div className="shimmerProduct-heading"></div>
      <div className="ShimmerProduct-image"></div>
      <div className="shimmerProduct-stats-container">
        <div className="shimmer-stat-item">
          <div className="stat-number"></div>
          <div className="stat-name"></div>
        </div>
        <div className="shimmer-stat-item">
          <div className="stat-number"></div>
          <div className="stat-name"></div>
        </div>
        <div className="shimmer-stat-item">
          <div className="stat-number"></div>
          <div className="stat-name"></div>
        </div>
      </div>
    </div>
  );
};
export default ShimmerProduct;
