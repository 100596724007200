const RevokeIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_209_7839)">
        <path
          d="M22.535 8.46003C22.0716 7.99505 21.5205 7.62659 20.9137 7.37599C20.3069 7.12538 19.6565 6.99759 19 7.00003H2.8L7.1 2.70003C7.28724 2.51199 7.39212 2.25727 7.39155 1.99191C7.39099 1.72654 7.28504 1.47227 7.097 1.28503C6.90896 1.09778 6.65424 0.992909 6.38888 0.993471C6.12351 0.994034 5.86924 1.09999 5.682 1.28803L0.731997 6.23703C0.263319 6.70585 3.05176e-05 7.34162 3.05176e-05 8.00453C3.05176e-05 8.66744 0.263319 9.30321 0.731997 9.77203L5.682 14.723C5.77484 14.8161 5.88512 14.89 6.00652 14.9405C6.12793 14.991 6.25809 15.0171 6.38958 15.0173C6.52107 15.0175 6.65131 14.9918 6.77286 14.9416C6.89441 14.8915 7.00489 14.8179 7.098 14.725C7.1911 14.6322 7.26501 14.5219 7.3155 14.4005C7.36599 14.2791 7.39207 14.1489 7.39226 14.0174C7.39245 13.886 7.36673 13.7557 7.31658 13.6342C7.26644 13.5126 7.19284 13.4021 7.1 13.309L2.788 9.00003H19C19.7956 9.00003 20.5587 9.3161 21.1213 9.87871C21.6839 10.4413 22 11.2044 22 12V19C22 19.7957 21.6839 20.5587 21.1213 21.1213C20.5587 21.684 19.7956 22 19 22H5C4.73478 22 4.48043 22.1054 4.29289 22.2929C4.10535 22.4805 4 22.7348 4 23C4 23.2652 4.10535 23.5196 4.29289 23.7071C4.48043 23.8947 4.73478 24 5 24H19C20.3256 23.9984 21.5964 23.4711 22.5338 22.5338C23.4711 21.5965 23.9984 20.3256 24 19V12C24.0022 11.3424 23.8739 10.6908 23.6224 10.0832C23.3709 9.47549 23.0013 8.92378 22.535 8.46003Z"
          fill="#1A1D1F"
          className={className}
        />
      </g>
      <defs>
        <clipPath id="clip0_209_7839">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default RevokeIcon;
