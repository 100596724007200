import React, { ReactNode } from 'react';

interface InputLayoutProps {
  children?: any;
  classes?: string;
}

const InputLayout: React.FC<InputLayoutProps> = ({ children, classes }) => {
  return (
    <div className={`${classes} col-sm `}>
      <div className="form-group">
        {children}
      </div>
    </div>
  );
};

export default InputLayout;
