import { Field, FieldArray, Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { confimationStyles } from "../../../../../../assets/styles/toast";
import CustomButton from "../../../../../atoms/CustomeButton";
import InputLayout from "../../../../../atoms/InputLayout";
import { MonthYearSelector } from "../../../../../molecules/MonthYearSelector";
import "./edit.scss";

import { useDispatch } from "react-redux";
import { getData, patchData, postData } from "../../../../../../services";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import Add from "../../../../../Icons/Add";

const Education = () => {
  const dispatch = useDispatch();
  const { id, people_id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [education, setEducation] = useState([]);
  const navigate = useNavigate();
  const initialValues = {
    education: [
      {
        field_of_study: education?.field_of_study || "",
        institute_name: education?.institute_name || "",
        start_date: education?.start_date || null,
        end_date: education?.end_date || null,
        about: education?.about || "",
        degree: education?.degree || "",
        people_id: people_id,
        id: id,
      },
    ],
  };

  const fetchEducation = async () => {
    if (id) {
      dispatch(setLoading(true));
      const res = await getData({
        endpoint: "People/getPeopleEducationDetailsById",
        params: { id: id },
        token: cookies.t,
      });
      if (res) {
        dispatch(setLoading(false));
        setEducation(res);
      }
    }
  };

  useEffect(() => {
    if (id) {
      fetchEducation();
    }
  }, []);

  const validationSchema = Yup.object({
    education: Yup.array().of(
      Yup.object().shape({
        // start_date: Yup.date().required("Start Date is required"),
        // end_date: Yup.date()
        //   .required("End Date is required")
        //   .when(
        //     "start_date",
        //     (start_date, schema) =>
        //       start_date &&
        //       schema.min(
        //         start_date,
        //         "End Date should be greater than Start Date"
        //       )
        //   ),
        // start_date: Yup.string().required(),
        // end_date: Yup.string().required(),
        institute_name: Yup.string().required("Institute Name is required"),
      })
    ),
  });

  const handleSubmit = async (values) => {
    if (id) {
      const dataToSend = {
        id: id,
        ...values.education[0],
      };

      try {
        dispatch(setLoading(true));
        const res = await patchData({
          endpoint: "People/editPeopleEducation",
          data: dataToSend,
          params: {},
          token: cookies.t,
        });

        if (res) {
          dispatch(setLoading(false));
          toast.success("Education Detail Edited Succesfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate(-1);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      const dataToSend = {
        people_id: people_id,
        ...values,
      };

      try {
        dispatch(setLoading(true));
        const res = await postData({
          endpoint: "People/addPeopleEducation",
          data: dataToSend.education,
          params: { people_id: people_id },
          token: cookies.t,
        });

        if (res) {
          dispatch(setLoading(false));
          toast.success("Education Details added succesfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate(-1);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
      key={111}
    >
      {({
        isValid,
        dirty,
        handleSubmit,
        touched,
        errors,
        values,
        setFieldValue,
        setErrors,
        setFieldError,
      }) => (
        <div className="education-view">
          <FieldArray name="education">
            {({ push, remove }) => (
              <>
                <div className="d-flex justify-content-between">
                  <div className="h1-j mb-3">
                    {id ? "Edit Education" : "Add Education"}{" "}
                  </div>
                  {!id && (
                    <button
                      className={`btn btn-primary px-4 mt-0 `}
                      onClick={() => push(dataObj)}
                    >
                      <i className={` d-flex me-2`}>
                        <Add />
                      </i>
                      Add Education
                    </button>
                  )}
                </div>

                {values.education.map((edu, index) => (
                  <>
                    {id ? (
                      <>
                        <div classeName="p-4 pt-3 mb-4">
                          <form action="">
                            <div className="row">
                              <InputLayout>
                                <label htmlFor="" className="required-field">
                                  Name of Institution
                                </label>
                                <Field
                                  type="text"
                                  label="Name of Institution"
                                  name={`education[${index}].institute_name`}
                                  placeholder="Enter Name of Institution"
                                />
                              </InputLayout>

                              <InputLayout>
                                <label htmlFor="">Degree</label>
                                <Field
                                  type="text"
                                  label="Degree"
                                  name={`education[${index}].degree`}
                                  placeholder="Enter Degree"
                                />
                              </InputLayout>
                            </div>

                            <div className="row mt-3">
                              <InputLayout>
                                <label htmlFor="">Field of Education</label>
                                <Field
                                  type="text"
                                  label="Field of Study"
                                  name={`education[${index}].field_of_study`}
                                  placeholder="Enter Field of Study"
                                />
                              </InputLayout>

                              <InputLayout>
                                <label htmlFor="">Period</label>
                                <div className="row">
                                  <div className="col-6">
                                    <MonthYearSelector
                                      placeholder="Start Date"
                                      onChange={(value) => {
                                        setFieldValue(
                                          `education[${index}].start_date`,
                                          moment(`${value}-10`).toISOString()
                                        );
                                        setFieldValue(
                                          `education[${index}].min_date`,
                                          value
                                        );
                                      }}
                                      selectedDate={education?.start_date}
                                    />
                                  </div>

                                  <div className="col-6">
                                    <MonthYearSelector
                                      placeholder="End Date"
                                      onChange={(value) =>
                                        setFieldValue(
                                          `education[${index}].end_date`,
                                          moment(`${value}-10`).toISOString()
                                        )
                                      }
                                      minDateData={
                                        values.education[index].min_date
                                      }
                                      selectedDate={education?.end_date}
                                      // minDateData={new Date(values?.education[index]?.end_date)}
                                    />
                                  </div>
                                </div>
                              </InputLayout>
                            </div>

                            <div className="row mt-3">
                              <InputLayout>
                                <label htmlFor="">Additional Information</label>
                                <Field
                                  as="textarea"
                                  label="Additional Information"
                                  name={`education[${index}].about`}
                                  id="about"
                                  placeholder="Enter Additional Information"
                                />
                              </InputLayout>
                            </div>
                          </form>
                        </div>
                      </>
                    ) : (
                      <div key={index} className="mt-3">
                        <div
                          className="accordion"
                          id={`accordionExample${index}`}
                        >
                          <div
                            className="accordion-item"
                            style={{ position: "relative" }}
                          >
                            <h2
                              className="accordion-header"
                              id={`heading${index}`}
                              style={{ display: "flex" }}
                            >
                              {!id && (
                                <>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="h3-i semi edu-accordian-header">
                                      {`Education ${index + 1}`}
                                    </h4>
                                    {index !== 0 && (
                                      <span
                                        className="cursor-pointer error-div edu-remove"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          remove(index);
                                        }}
                                      >
                                        Remove
                                      </span>
                                    )}
                                  </div>
                                  {/* <hr className="mt-0" /> */}
                                </>
                              )}
                              <button
                                className={`accordion-button ${
                                  index === 0 ? "" : "collapsed"
                                }`}
                                // style={{width:'max-content'}}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                aria-expanded="false"
                                aria-controls={`collapse${index}`}
                              ></button>
                            </h2>
                            <div
                              id={`collapse${index}`}
                              className={`accordion-collapse collapse ${
                                index === 0 ? "show" : ""
                              }`}
                              aria-labelledby={`heading${index}`}
                            >
                              <div className="accordion-body">
                                <div classeName="p-4 pt-3 mb-4">
                                  <form action="">
                                    <div className="row">
                                      <InputLayout>
                                        <label
                                          htmlFor=""
                                          className="required-field"
                                        >
                                          Name of Institution
                                        </label>
                                        <Field
                                          type="text"
                                          label="School/Collage/ Institute Name"
                                          name={`education[${index}].institute_name`}
                                          placeholder="Enter Name of Institution"
                                        />
                                      </InputLayout>

                                      <InputLayout>
                                        <label htmlFor="">Degree</label>
                                        <Field
                                          type="text"
                                          label="Degree"
                                          name={`education[${index}].degree`}
                                          placeholder="Enter Degree"
                                        />
                                      </InputLayout>
                                    </div>

                                    <div className="row mt-3">
                                      <InputLayout>
                                        <label htmlFor="">
                                          Field of Education
                                        </label>
                                        <Field
                                          type="text"
                                          label="Field of Study"
                                          name={`education[${index}].field_of_study`}
                                          placeholder="Enter Field of Study"
                                        />
                                      </InputLayout>

                                      <InputLayout>
                                        <label htmlFor="">Period</label>
                                        <div className="d-flex gap-3 w-100">
                                          <div style={{ flex: 1 }}>
                                            <MonthYearSelector
                                              placeholder="Start Date"
                                              onChange={(value) => {
                                                setFieldValue(
                                                  `education[${index}].start_date`,
                                                  moment(
                                                    `${value}-10`
                                                  ).toISOString()
                                                );
                                                setFieldValue(
                                                  `education[${index}].min_date`,
                                                  value
                                                );
                                              }}
                                              selectedDate={
                                                education?.start_date
                                              }
                                            />
                                          </div>

                                          <div style={{ flex: 1 }}>
                                            <MonthYearSelector
                                              placeholder="End Date"
                                              onChange={(value) =>
                                                setFieldValue(
                                                  `education[${index}].end_date`,
                                                  moment(
                                                    `${value}-10`
                                                  ).toISOString()
                                                )
                                              }
                                              minDateData={
                                                values.education[index].min_date
                                              }
                                              selectedDate={education?.end_date}
                                              // minDateData={new Date(values?.education[index]?.end_date)}
                                            />
                                          </div>
                                        </div>
                                      </InputLayout>
                                    </div>

                                    <div className="row mt-3">
                                      <InputLayout>
                                        <label htmlFor="">
                                          Additional Information
                                        </label>
                                        <Field
                                          as="textarea"
                                          label="About"
                                          name={`education[${index}].about`}
                                          id="about"
                                          placeholder="Enter Additional Information"
                                        />
                                      </InputLayout>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                  // <div key={index}>
                  //   <div classeName="p-4 pt-3 mb-4">
                  //     {!id && (
                  //       <>
                  //         <div className="d-flex justify-content-between">
                  //           <h4
                  //             className="normal-heading"
                  //             style={{ border: "none" }}
                  //           >
                  //             {`Education ${index + 1}`}
                  //           </h4>
                  //           {index !== 0 && (
                  //             <span
                  //               className="cursor-pointer error-div"
                  //               onClick={() => remove(index)}
                  //               style={{ fontSize: "16px" }}
                  //             >
                  //               Remove
                  //             </span>
                  //           )}
                  //         </div>

                  //         <hr className="mt-0" />
                  //       </>
                  //     )}

                  //     <form action="">
                  //       <div className="row">
                  //         <InputLayout>
                  //           <label htmlFor="">
                  //             School/Collage/Institute Name
                  //           </label>
                  //           <Field
                  //             type="text"
                  //             label="School/Collage/ Institute Name"
                  //             name={`education[${index}].institute_name`}
                  //             placeholder="Enter School/Collage/ Institute Name"
                  //           />
                  //         </InputLayout>

                  //         <InputLayout>
                  //           <label htmlFor="">Degree</label>
                  //           <Field
                  //             type="text"
                  //             label="Degree"
                  //             name={`education[${index}].degree`}
                  //             placeholder="Enter Degree"
                  //           />
                  //         </InputLayout>
                  //       </div>

                  //       <div className="row mt-3">
                  //         <InputLayout>
                  //           <label htmlFor="">Field of Education</label>
                  //           <Field
                  //             type="text"
                  //             label="Field of Study"
                  //             name={`education[${index}].field_of_study`}
                  //             placeholder="Enter Field of Study"
                  //           />
                  //         </InputLayout>

                  //         <InputLayout>
                  //           <label htmlFor="">Period</label>
                  //           <div className="d-flex gap-3 w-100">
                  //             <div style={{ flex: 1 }}>
                  //               <MonthYearSelector
                  //                 placeholder="Start Date"
                  //                 onChange={(value) => {
                  //                   setFieldValue(
                  //                     `education[${index}].start_date`,
                  //                     moment(`${value}-10`).toISOString()
                  //                   );
                  //                   setFieldValue(
                  //                     `education[${index}].min_date`,
                  //                     value
                  //                   );
                  //                 }}
                  //                 selectedDate={education?.start_date}
                  //               />
                  //             </div>

                  //             <div style={{ flex: 1 }}>
                  //               <MonthYearSelector
                  //                 placeholder="End Date"
                  //                 onChange={(value) =>
                  //                   setFieldValue(
                  //                     `education[${index}].end_date`,
                  //                     moment(`${value}-10`).toISOString()
                  //                   )
                  //                 }
                  //                 minDateData={values.education[index].min_date}
                  //                 selectedDate={education?.end_date}
                  //                 // minDateData={new Date(values?.education[index]?.end_date)}
                  //               />
                  //             </div>
                  //           </div>
                  //         </InputLayout>
                  //       </div>

                  //       <div className="row mt-3">
                  //         <InputLayout>
                  //           <label htmlFor="">About</label>
                  //           <Field
                  //             as="textarea"
                  //             label="About"
                  //             name={`education[${index}].about`}
                  //             id="about"
                  //             placeholder="Enter About"
                  //           />
                  //         </InputLayout>
                  //       </div>
                  //     </form>
                  //   </div>
                  // </div>
                ))}

                <div style={{ marginTop: "auto" }}>
                  <footer className="footer">
                    <CustomButton
                      text="Back"
                      handleClick={() => {
                        if (dirty) {
                          const userConfirmed = window.confirm(
                            "You have unsaved changes. Are you sure you want to leave?"
                          );
                          if (!userConfirmed) {
                            return;
                          } else {
                            navigate(-1);
                          }
                        } else {
                          navigate(-1);
                        }
                      }}
                    />

                    <button
                      type="submit"
                      className={`btn btn-primary px-4 mt-0 `}
                      onClick={() => handleSubmit(values)}
                      disabled={id ? !isValid : !isValid || !dirty}
                    >
                      {id ? "Update" : "Add"}
                    </button>
                  </footer>
                </div>
              </>
            )}
          </FieldArray>
        </div>
      )}
    </Formik>
  );
};

const dataObj = {
  field_of_study: "",
  institute_name: "",
  start_date: "",
  end_date: "",
  about: "",
  degree: "",
};

export default Education;
