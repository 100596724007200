import { useNavigate } from "react-router-dom";
import DeleteRed from "../../../../Icons/DeleteRed";
import PeopleProfileCard from "../../../../atoms/PeopleProfileCard";
import NoContentCard from "../../../../molecules/NoContentCard";
import "../saved_list.scss";

const SavedCompanies = ({
  removeIcon,
  data,
  handleDelete,
  isSearched,
}: any) => {
  const navigate = useNavigate();
  return (
    <div className="saved-data-container">
      <>
        {data?.length > 0 ? (
          <>
            {data?.map((item: any) => (
              <div className="saved-item-container" key={item.company_id}>
                <div
                  className="saved-item cursor-pointer"
                  onClick={() =>
                    window.open(
                      `/dashboard/company/${item.company_id}`,
                      "_blank"
                    )
                  }
                >
                  <PeopleProfileCard
                    type="company"
                    profile={item?.profile}
                    size="90px"
                    borderRadius="10%"
                  />

                  <div>
                    <h4 className="h4-i semi-light">{item?.name}</h4>
                    <p className="p mb-1">
                      {" "}
                      <>
                        {item?.address?.state_region}
                        {item?.address?.state_region && ", "}{" "}
                        {item?.address?.country}
                      </>
                    </p>
                    <p className="p mb-1">{item?.role}</p>
                  </div>
                </div>

                <button
                  className={
                    !removeIcon
                      ? "btn btn-outline-danger Saved-remove-btn-web"
                      : "manage-icon"
                  }
                  onClick={() => handleDelete(item.saved_list)}
                >
                  {!removeIcon ? (
                    "Remove"
                  ) : (
                    <i className="d-flex">
                      <DeleteRed className="Saved-delete-icon-container" />
                    </i>
                  )}
                </button>
                {/* <button
            className={
              !removeIcon
                ? "btn btn-outline-danger Saved-remove-btn-phone"
                : "manage-icon"
            }
            onClick={() => handleDelete(item.saved_list)}
          >
            {!removeIcon ? "Remove" : <i className="fi fi-br-cross"></i>}
          </button> */}
              </div>
            ))}
          </>
        ) : (
          <div className="non-saved">
            {isSearched ? (
              <NoContentCard title="Sorry! No Result Found" type="Search" />
            ) : (
              <NoContentCard title="No Company Saved Yet !" type="" />
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default SavedCompanies;
