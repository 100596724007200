import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { getData } from "../../../../../../services";
import CircleAvatar from "../../../../../atoms/CircleAvatar";
import CustomButton from "../../../../../atoms/CustomeButton";
import PageCard from "../../../../../atoms/PageCard";
import "./styles.scss";

const Team = ({ company_id }) => {
  const { id } = useParams();
  const [team, setTeam] = useState([]);
  const [cookies] = useCookies(["t"]);

  const getTeam = async () => {
    const res = await getData({
      endpoint: "People/customer/getUserTeamPeoples",
      params: { company_id: company_id },
      token: cookies.t,
    });

    setTeam(res);
  };

  const handleView = (id) => {
    window.open(`/dashboard/people/${id}`, "_blank");
  };

  useEffect(() => {
    getTeam();
  }, [company_id]);

  return (
    <>
      {team?.filter((member) => member.people_id !== id)?.length > 0 && (
        <>
          <h4 className="h4-j">Team</h4>
          <div className="team-card-container-wrapper">
            <PageCard
              customStyles={{ backgroundColor: "#F6F6F6", padding: "20px" }}
              classes="team-card-container"
            >
              <div className="team-card">
                {team?.map((member) => (
                  <>
                    {member.people_id !== id && (
                      <div className="d-flex justify-content-between mb-3">
                        <div key={member.people_id} className="team-people">
                          <div>
                            {member?.profile_photo ? (
                              <img
                                src={member?.profile_photo}
                                alt=""
                                className="team-image"
                              />
                            ) : (
                              <>
                                <CircleAvatar
                                  borderRadius="50%"
                                  name={member?.name}
                                  size={60}
                                  fontSize="20px"
                                  color="#ffffff"
                                  iconColor="#3D9BE9"
                                  border="1px solid #efefef"
                                />
                              </>
                            )}
                          </div>

                          <div className="d-flex flex-column">
                            <div className="h4-j mb-0">{member?.name}</div>
                            <div className="p">{member?.job_title}</div>
                          </div>
                        </div>

                        <CustomButton
                          // icon={<Direction />}
                          handleClick={() => {
                            handleView(member?.people_id);
                          }}
                          classes="btn-outline-dark btn-sm"
                          text="View "
                        />
                      </div>
                    )}
                  </>
                ))}
              </div>
            </PageCard>
          </div>
        </>
      )}
    </>
  );
};

export default Team;
