import { useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { confimationStyles } from "../../../assets/styles/toast";
import { postData } from "../../../services";
import { setLoading } from "../../../store/slices/loaderSlice";
import Copy from "../../Icons/Copy";
import CustomButton from "../../atoms/CustomeButton";
import Error from "../../atoms/Error";
import "./styles.scss";

const ShareLinkEmail = ({
  generateURL,
  isCopy,
  handleCopyUrl,
  setModalStateEmail,
  setGenerateURL,
  onClose,
  expiry,
  module,
}) => {
  const sharedBase = process.env.REACT_APP_USER_BASE_URL;

  const dispatch = useDispatch();
  const [cookies] = useCookies(["t"]);
  const [shareLinkEmail, setShareLinkEmail] = useState({
    email: null,
    subject: "",
    message: "",
  });
  const [errors, setError] = useState({});
  //   console.log("generateURL", generateURL);
  const handleInputs = (e) => {
    const { name, value } = e.target;
    validate(e, name, value);
    setShareLinkEmail((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validate = (e, name, value) => {
    switch (name) {
      case "email":
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          setError({
            ...errors,
            email: "Enter a valid email address",
          });
        } else {
          setError({
            ...errors,
            email: "",
          });
        }
        break;
      case "subject":
        if (value.length < 3) {
          setError({
            ...errors,
            subject: "Enter a valid subject",
          });
        } else {
          setError({
            ...errors,
            subject: "",
          });
        }
        break;
      // case "message":
      //   if (value.length <= 1) {
      //     setError({
      //       ...errors,
      //       message: "Enter a valid message",
      //     });
      //   } else {
      //     setError({
      //       ...errors,
      //       message: "",
      //     });
      //   }
      //   break;

      default:
        break;
    }
  };

  const handleEmailShareLink = async (e) => {
    e.preventDefault();
    const payloadBody = {
      link_id: generateURL?.link_id,
      module: "Blog",
      link: generateURL?.link,
      email: shareLinkEmail?.email,
      subject: shareLinkEmail?.subject,
      message: shareLinkEmail?.message,
    };
    dispatch(setLoading(true));
    const res = await postData({
      endpoint: "ContentSharing/shareContent",
      data: payloadBody,
      token: cookies.t,
    });
    if (res) {
      dispatch(setLoading(false));
      setShareLinkEmail(() => ({
        email: "",
        subject: "",
        message: "",
      }));
      setModalStateEmail((prev) => ({ ...prev, isModalOpen: false }));
      setGenerateURL("");
      toast.success("Content Shared Successfully.", {
        style: confimationStyles,
        duration: 1000,
      });
      onClose();
    }
  };

  return (
    <div className="shareLinkEmail">
      <div className="titleWrap">
        <span className="title">
          You are about to share the {module} Blog. Enter email address of the
          person you would like to share this Blog with. Access will expire in{" "}
          {expiry} {expiry > 1 ? "days" : "day"}.
        </span>
      </div>
      <form onSubmit={handleEmailShareLink}>
        <div className="formStyle">
          <div className="email">
            <label>Email</label>
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={shareLinkEmail.email}
              onChange={handleInputs}
            />
            {errors?.email && <Error error={errors?.email} />}
          </div>
          <div className="email">
            <label>Subject</label>
            <input
              type="text"
              title="subject"
              placeholder="Subject"
              name="subject"
              value={shareLinkEmail.subject}
              onChange={handleInputs}
            />
            {errors?.subject && <Error error={errors?.subject} />}
          </div>
          <div className="email">
            <label>Message</label>
            <textarea
              type="email"
              name="message"
              placeholder="Enter the message"
              value={shareLinkEmail.message}
              onChange={handleInputs}
            />
            {errors?.message && <Error error={errors?.message} />}
          </div>
          <div className="linkAndIcon">
            <label id="primealphaurl">
              {generateURL ? generateURL?.link : sharedBase}
            </label>
            <i class="cursor-pointer" onClick={handleCopyUrl}>
              <Copy />
            </i>
          </div>
          <div className="titleButton">
            <div className="footertitle">
              Please make sure you are aware of the share policy agreed upon in
              our Terms and Conditions
            </div>
            <CustomButton
              text="Share"
              type="btn-primary"
              buttonType="submit"
              disabled={!shareLinkEmail.email}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ShareLinkEmail;
