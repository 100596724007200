import { useFormik } from "formik";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { confimationStyles } from "../../../../../../assets/styles/toast";
import { isUUID } from "../../../../../../helpers/checkUuid";
import { allPlatforms } from "../../../../../../helpers/staticData";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import SelectDropdown from "../../../../../atoms/Dropdown";
import Error from "../../../../../atoms/Error";
import { addCompanySocial } from "./socialHelper";

const AddChannel = ({ onSubmit, onClose, initialData, id, type, onAdded }) => {
  const [cookies] = useCookies(["t", "cid"]);
  const dispatch = useDispatch();

  const { id: pid } = useParams();

  const validationSchema = Yup.object().shape({
    platforms: Yup.array().of(Yup.string().required("Type is required")), // Change the validation message
    links: Yup.array().of(
      Yup.object().shape({
        type: Yup.string(), // Change from platform to type
        channel_link: Yup.string()
          .url("Invalid URL")
          .required("Channel link is required"), // Change from link to channel_link
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      platforms: initialData ? [initialData.type] : [],
      links: initialData
        ? [{ type: initialData.type, channel_link: initialData.link }]
        : [],
    },
    key: id,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const dataToSend = initialData
        ? {
            links: values.links.map((link) => ({
              ...link,
            })),
            id: initialData.id,
          }
        : { ...values, company_id: cookies.cid };

      if (isUUID(cookies.cid)) {
        try {
          dispatch(setLoading(true));
          const res = await addCompanySocial(dataToSend, cookies.t);
          if (res) {
            dispatch(setLoading(false));
            toast.success("Socials Added Successfully", {
              duration: 1000,
              style: confimationStyles,
            });
            formik.resetForm();
            onClose(); // Moved it inside the try block
          }
        } catch (error) {
          console.error(error); // Changed console.log to console.error for better visibility of errors
        }

        // Move this outside the try block to avoid calling it twice
        onAdded();
        onClose();
      }
    },
  });

  const handlePlatformChange = (selectedOptions) => {
    const newPlatforms = selectedOptions.map((option) => option);

    // Remove platforms that are not selected anymore
    const updatedLinks = formik.values.links.filter(
      (link) => newPlatforms?.includes(link.type) // Change from platform to type
    );

    // Add new platforms with empty links
    newPlatforms.forEach((platform) => {
      if (!formik.values.links.find((link) => link.type === platform)) {
        // Change from platform to type
        updatedLinks.push({ type: platform, channel_link: "" }); // Change from link to channel_link
      }
    });

    // Update formik values
    formik.setValues({
      ...formik.values,
      platforms: newPlatforms,
      links: updatedLinks,
    });
  };

  return (
    <div key={id}>
      <form onSubmit={formik.handleSubmit} key={id}>
        <div className="form-group">
          <label htmlFor="platforms" className="required-field">
            Channel
          </label>

          {initialData ? (
            <SelectDropdown
              key={id}
              name="platforms"
              data={allPlatforms}
              selectedValue={formik.values.platforms[0]}
              onChange={handlePlatformChange}
              multiSelect={true}
              onBlur={formik.handleBlur}
              placeholder="Select Channel"
              classes={`${
                formik.touched.platforms && formik.errors.platforms
                  ? "error-input"
                  : ""
              }`}
            />
          ) : (
            <SelectDropdown
              key={id}
              name="platforms"
              data={allPlatforms}
              selectedValue={formik.values.platforms}
              onChange={handlePlatformChange}
              multiSelect={true}
              onBlur={formik.handleBlur}
              placeholder="Select Channels"
              classes={`${
                formik.touched.platforms && formik.errors.platforms
                  ? "error-input"
                  : ""
              }`}
            />
          )}

          {formik.touched.platforms && formik.errors.platforms && (
            <Error error={formik.errors.platforms} />
          )}
        </div>

        {formik.values.platforms.length === 0 && (
          <div className="form-group mt-3">
            <label className="required-field">Link</label>
            <input type="text" placeholder="Enter Link" />
          </div>
        )}

        {formik.values.platforms.map((platform, index) => (
          <div key={index} className="form-group mt-3">
            <label htmlFor={`link-${platform}`} className="required-field">
              {platform} Account Link
            </label>
            <input
              type="text"
              id={`link-${platform}`}
              name={`links[${index}].channel_link`} // Change from link to channel_link
              className={`${
                formik.isSubmitting &&
                formik.touched.links &&
                formik.touched.links[index]?.channel_link &&
                formik.errors.links &&
                formik.errors.links[index]?.channel_link
                  ? "error-input"
                  : ""
              }`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.links[index]?.channel_link || ""} // Change from link to channel_link
              placeholder={`Enter ${platform} Link`}
            />
            {formik.isSubmitting &&
              formik.touched.links &&
              formik.touched.links[index]?.channel_link &&
              formik.errors.links &&
              formik.errors.links[index]?.channel_link && (
                <Error error={formik.errors.links[index]?.channel_link} />
              )}
          </div>
        ))}
        <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
          <button
            type="submit"
            className="btn btn-primary mt-3"
            style={{ width: "120px" }}
            disabled={
              initialData ? !formik.isValid : !formik.isValid || !formik.dirty
            }
          >
            {initialData ? "Update" : "Add"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddChannel;
