import * as XLSX from "xlsx";

export const parseFile = (fileData, callback) => {
  const reader = new FileReader();
  reader.readAsBinaryString(fileData);

  reader.onload = (e) => {
    const data = e.target.result;
    const workbook = XLSX.read(data, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const parsedData = XLSX.utils.sheet_to_json(sheet, { defval: null });

    // Call the callback function with the parsed data
    const sanitizedData = parsedData.map((row) =>
      Object.fromEntries(
        Object.entries(row).map(([key, value]) => [
          key,
          value === null ? "" : value?.toLocaleString("en-US"),
        ])
      )
    );

    callback(sanitizedData);
  };
};
