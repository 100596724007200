import React, { ChangeEvent, FocusEvent } from 'react';

interface TextAreaProps {
  label: string;
  id: string;
  name: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  placeholder: string;
  rows ?: number;
  height?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  label,
  id,
  name,
  value,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  height,
  rows
}) => {
  return (
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      <textarea
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        rows={rows}
        style={{minHeight : height}}
      />
    </div>
  );
};

export default TextArea;
