import { Field, Form, Formik } from "formik";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { confimationStyles } from "../../../../../../assets/styles/toast";
import { statusData } from "../../../../../../helpers/staticData";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import SelectDropdown from "../../../../../atoms/Dropdown";
import { createKeyLink, updateKeyLinkData } from "../../peopleService";

const validationSchema = Yup.object({
  url: Yup.string().url("Invaid URL").required("Url is required"),
  title: Yup.string().required("Title is required"),
  status: Yup.bool().required(),
});

const AddKeylinkForm = ({ initialData, onClose, id }) => {
  const { id: pid, added_id } = useParams();
  const [cookies] = useCookies(["t"]);
  const dispatch = useDispatch();

  const initialValues = {
    id: initialData ? initialData.key_link_id : pid,
    title: initialData ? initialData.title : "",
    url: initialData?.link || "",
    description: initialData?.description || "",
    status: typeof initialData?.status !== "boolean" ? "" : initialData.status,
  };

  const handleSubmit = async (values) => {
    if (initialData) {
      try {
        dispatch(setLoading(true));
        const res = await updateKeyLinkData(values, cookies.t);
        if (res.status === true) {
          dispatch(setLoading(false));
          toast.success("Keylink Updated SuccessFully", {
            duration: 1000,
            style: confimationStyles,
          });
          onClose();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        dispatch(setLoading(true));
        const res = await createKeyLink(
          { ...values, people_id: pid || added_id },
          cookies.t
        );
        if (res) {
          dispatch(setLoading(false));
          toast.success("Keylink Added SuccessFully", {
            duration: 1000,
            style: confimationStyles,
          });
          onClose();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
      key={id}
    >
      {({ dirty, isValid, setFieldValue, values, errors }) => (
        <Form>
          <div className="form-group mt-2">
            <label htmlFor="name" className="required-field">
              Name
            </label>
            <Field
              type="text"
              id="title"
              name="title"
              placeholder="Enter Title"
            />
          </div>

          <div className="form-group mt-3">
            <label htmlFor="url" className="required-field">
              URL
            </label>
            <Field type="text" id="url" name="url" placeholder="Enter URL" />
          </div>

          <div className="form-group mt-3">
            <label htmlFor="status" className="required-field">
              Status
            </label>
            <SelectDropdown
              placeholder="Select Status"
              onChange={(option) => setFieldValue("status", option)}
              data={statusData}
              selectedValue={initialData?.status}
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="url">Description</label>
            <Field
              type="text"
              id="description"
              name="description"
              placeholder="Enter Description"
              as="textarea"
              rows={4}
            />
          </div>

          {console.log("erroe", errors)}

          <div className="d-flex justify-content-end my-4">
            <button
              className="btn btn-primary px-4 w-100 "
              type="submit"
              disabled={initialData ? !isValid : !isValid || !dirty}
            >
              {initialData ? "Update" : "Add"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddKeylinkForm;
