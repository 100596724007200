import { useCallback, useEffect, useState } from "react";
import CustomDatagrid from "../../../../../molecules/CustomDatagrid";
import NoContentCard from "../../../../../molecules/NoContentCard";

import { useParams } from "react-router-dom";
import Modal from "../../../../../molecules/Modal/Normal";
import AddVideoForm from "./AddVideoForm";

// import {
//   editVideoStatus,
//   useFetchProductVideo,
// } from "../../../../services/addProductSetvice";

import { editVideoStatus } from "../../../../../../services/productHelper";

import moment from "moment";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { confimationStyles } from "../../../../../../assets/styles/toast";
import { htmlToText } from "../../../../../../helpers/htmlToText";
import { useDelete } from "../../../../../../hooks/useDelete";
import { getData } from "../../../../../../services";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import Add from "../../../../../Icons/Add";
import DeleteRed from "../../../../../Icons/DeleteRed";
import EditBlue from "../../../../../Icons/EditBlue";
import Investement from "../../../../../Icons/Investment";
import TypeDiv from "../../../../../atoms/TypeDiv";
import DeleteModal from "../../../../../molecules/Modal/DeleteModal";
import Pagination from "../../../../../molecules/Pagination";
import View from "./View";
import "./video.scss";

const VideoComponent = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [cookies] = useCookies(["t"]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [data, setData] = useState();

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const fetchVideo = async () => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "Product/getAllProductVideos",
      params: { product_id: id, page: currentPage, page_limit: itemsPerPage },
      token: cookies.t,
    });
    dispatch(setLoading(false));
    setData(res);
  };

  useEffect(() => {
    fetchVideo();
  }, [currentPage, itemsPerPage]);

  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalId: "",
    title: "",
    FormData: "",
    isViewModalOpen: "",
  });

  const openModal = (title, index, formData) => {
    setModalState((prev) => ({
      ...prev,
      title: title,
      modalId: uuidv4(),
      FormData: formData,
      isModalOpen: true,
    }));
  };

  const closeModal = () => {
    setModalState((prev) => ({ ...prev, isModalOpen: false }));
  };

  const handleEdit = (data) => {
    openModal("Edit Video", data.video_id, data);
  };

  const deleteData = useDelete();

  const handleDelete = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.video_id,
      name: data.name,
    }));
  };

  const handleDeletion = async () => {
    try {
      await deleteData("Product/deleteVideo", "Data", {
        video_id: modalState.modalId,
        user_role: "Customer",
      });
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
      fetchVideo();
    } catch (error) {
      console.log(error);
    }
  };

  const handleMasterVideoEdit = async (data) => {
    const dataToSend = {
      video_id: data.video_id,
      is_master_video: !data.is_master_video,
    };

    try {
      dispatch(setLoading(true));
      const res = await editVideoStatus(dataToSend, cookies.t);
      if (res.status) {
        dispatch(setLoading(false));
        toast.success("Video Status Updated", {
          duration: 1000,
          style: confimationStyles,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleView = (data) => {
    setModalState((prev) => ({
      ...prev,
      title: "View Video",
      modalId: uuidv4(),
      FormData: data,
      isViewModalOpen: true,
    }));
  };

  const columns = [
    {
      field: "Video Details",
      headerName: "Video Details",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div className="d-flex flex-column my-2">
            <span
              style={{ color: "#1A1D1F", fontWeight: 800, fontSize: "14px" }}
            >
              {params.row.name}
            </span>
            <div className="description">
              {htmlToText(params.row?.description).slice(0, 80)}
            </div>
          </div>
        );
      },
    },
    { field: "link", headerName: "Video Link", flex: 1 },
    {
      field: "date",
      headerName: "Date",
      flex: 0.9,
      renderCell: (params) => {
        return (
          <span>{moment.utc(params.row?.date).format("MMM D, YYYY")}</span>
        );
      },
    },
    {
      field: "featured",
      headerName: "Featured",
      flex: 1,
      renderCell: (params) => {
        return (
          <TypeDiv
            data={params.row?.featured ? "Yes" : "No"}
            color={params.row?.featured === true ? "#B5E4CA" : "#FFB7B7"}
          />
        );
      },
    },
  ];

  columns.push(
    //   {
    //     field: "checkbox",
    //     headerName: "Master Video",
    //     flex: 1,
    //     renderCell: (params) => {
    //       return (
    //         <label className="check-container">
    //           <input
    //             type="checkbox"
    //             style={{ marginLeft: "8px" }} // Adjust styling as needed
    //             className="checkbox"
    //             checked={params.row?.is_master_video}
    //             onChange={() => handleMasterVideoEdit(params.row)}
    //           />
    //           <span className="checkmark"></span>
    //         </label>
    //       );
    //     },
    //   },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.9,
      renderCell: (params) => {
        return (
          <>
            {/* <span type="button" onClick={() => handleView(params.row)}>
                <i className="fi fi-rr-eye actions-icon-custom"></i>
              </span> */}

            <span
              type="button"
              onClick={() => handleEdit(params.row)}
              style={{ marginRight: "10px" }}
            >
              {/* <i className="fi fi-rr-pencil actions-icon-custom"></i> */}
              <EditBlue className="edit-blue-icon" />
            </span>

            <span type="button" onClick={() => handleDelete(params.row)}>
              {/* <i className="fi fi-rr-trash actions-icon-custom ms-3"></i> */}
              <DeleteRed className="edit-blue-icon" />
            </span>
          </>
        );
      },
    }
  );

  return (
    <>
      {modalState.isModalOpen && (
        <Modal
          id={modalState.modalId}
          isActive={modalState.isModalOpen}
          onClose={closeModal}
          title={modalState.title}
          width="642px"
        >
          <AddVideoForm
            onClose={closeModal}
            id={modalState.modalId}
            initialData={modalState.FormData}
            onAddorUpdate={() => fetchVideo()}
          />
        </Modal>
      )}

      {data?.data.length > 0 ? (
        <>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="fw-bold mb-0">Videos</h4>

            <button
              className="btn btn-primary d-flex gap-2"
              onClick={() => openModal("Add Video")}
            >
              <Add /> Add Video
            </button>
          </div>
          <CustomDatagrid
            rows={data?.data || []}
            columns={columns}
            getRowId={(row) => row.video_id}
            checkRequired={false}
            height={() => "auto"}
          />
        </>
      ) : (
        <NoContentCard
          small={true}
          title="No Videos"
          subtitle="To add Video, tap the add button."
          icon={<Investement />}
          handleClick={() => {
            openModal("Add Video");
          }}
          tag="Add Video"
        />
      )}

      <DeleteModal
        name={modalState.name}
        id={modalState.modalId}
        isActive={modalState.isDeleteModalOpen}
        onClose={() =>
          setModalState((prevState) => ({
            ...prevState,
            isDeleteModalOpen: false,
          }))
        }
        title="Video"
        onClick={handleDeletion}
      />

      {modalState?.isViewModalOpen && (
        <Modal
          id={modalState.modalId}
          isActive={modalState.isViewModalOpen}
          onClose={() =>
            setModalState((prev) => ({
              ...prev,
              isViewModalOpen: false,
            }))
          }
          title={modalState.title}
          width="642px"
        >
          <View data={modalState.FormData} on />
        </Modal>
      )}
      {data?.total_record >= 10 && (
        <div className="mt-2">
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={data?.total_record}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        </div>
      )}
    </>
  );
};

export default VideoComponent;
