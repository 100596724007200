import React, { ReactNode } from "react";
import NoCountryImage from "../../../assets/images/NoContent.png";
import NoContentImage from "../../../assets/images/NotFound.png";
import NoDataSearch from "../../../assets/images/Search.png";
import Add from "../../Icons/Add";
import CustomButton from "../../atoms/CustomeButton";
import "./no_content.scss";

interface NoContentCardProps {
  title: string;
  subtitle?: string;
  tag?: string;
  handleClick?: () => void;
  onAction?: () => void;
  classes?: string;
  containerClass?: string;
  small?: boolean;
  icon?: any;
  disabled?: boolean;
  children?: ReactNode;
  color?: any;
  type: "Company" | "People" | "Geography" | "Search" | "";
}

const NoContentCard: React.FC<NoContentCardProps> = ({
  title,
  subtitle,
  tag,
  handleClick,
  onAction,
  classes,
  small = false,
  icon,
  disabled,
  children,
  color,
  type,
  containerClass,
}) => {
  const getImage = (type: string) => {
    switch (type) {
      case "Company":
        return NoContentImage;
      case "Geography":
        return NoCountryImage;
      case "Search":
        return NoDataSearch;
      case "None":
        return NoContentImage;
      default:
        return NoContentImage;
    }
  };

  return (
    <div>
      <div className={`no-content-card ${containerClass}`}>
        {small ? (
          <>
            <div
              className="no-content-circle mb-3"
              style={{ backgroundColor: color ? "#ffffff" : "" }}
            >
              <span style={{ marginTop: "0.8rem" }}>
                <i
                  className={`d-flex align-items-center `}
                  style={{ marginBottom: "10px" }}
                >
                  {icon}
                </i>
              </span>
            </div>
            <p className="no-content-title">{title}</p>
            {subtitle && <p className="no-content-subtitle">{subtitle}</p>}
            {handleClick && (
              <CustomButton
                text={tag}
                handleClick={handleClick}
                icon={<Add />}
                classes="mt-2"
                type="btn-primary"
                disabled={disabled}
              />
              // <button
              //   className="btn btn-primary"
              //   disabled={disabled}
              //   onClick={() => handleClick()}
              // >
              //   {/* {tag}{" "} */}
              // </button>
            )}
            {children}
          </>
        ) : (
          <>
            <img
              src={getImage(type)}
              alt="No Content"
              height="11.25rem"
              className="mb-3"
            />
            <p className="no-content-title">{title}</p>
            <p className="no-content-subtitle mt-0">{subtitle}</p>
            {handleClick && (
              // <button
              //   onClick={handleClick}
              //   className={`btn btn-primary px-2.67rem mt-3 ${classes}`}
              //   disabled={disabled}
              // >
              //   <i className={`me-1.33rem mt-0.125rem`}>
              //     <Add />
              //   </i>
              //   {tag}
              // </button>
              <CustomButton
                text={tag}
                handleClick={handleClick}
                icon={<Add />}
                classes={`px-2.67rem  ${classes}`}
                type="btn-primary"
                disabled={disabled}
              />
            )}

            {children}
          </>
        )}
      </div>
    </div>
  );
};

export default NoContentCard;
