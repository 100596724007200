import PropTypes from "prop-types"; // Fix the import
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import "./quillEditor.scss";

const QuillEditor = ({
  placeholderText = "Type Something...",
  label = "",
  height = "180px",
  hp = "70%",
  value,
  onChange,
  classes,
  type,
}) => {
  const [editorValue, setEditorValue] = useState(value || "");

  useEffect(() => {
    setEditorValue(value);
  }, [value]);

  const handleEditorChange = (value) => {
    setEditorValue(value);
    onChange(value);
  };

  const defaultToolbarOptions = {
    container: [
      [{ header: [1, 2, 3, 4, false] }],
      ["bold", "italic", "underline"], // toggled buttons              // custom button values
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ], // remove formatting button
    ],
  };

  const customToolbarOptions = {
    ...defaultToolbarOptions,
    container: [
      ...defaultToolbarOptions.container,
      ["link", "image"], // Add 'link' and 'image' options here
    ],
  };

  const modules = {
    toolbar: type === "custom" ? customToolbarOptions : defaultToolbarOptions,
  };

  return (
    <>
      {label && <label>{label}</label>}
      <div style={{ height: height, cursor: "text" }} className={classes}>
        <ReactQuill
          style={{ borderRadius: "12px", height: hp }}
          theme="snow" // You can choose a different theme if needed
          value={editorValue}
          onChange={handleEditorChange}
          placeholder={placeholderText}
          contentType="html"
          modules={modules}
        />
        {/* Additional UI components or buttons can be added as needed */}
      </div>
    </>
  );
};

QuillEditor.propTypes = {
  placeholderText: PropTypes.string,
  label: PropTypes.string,
};

export default QuillEditor;
