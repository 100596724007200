import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  confimationStyles,
  errorStyles,
  warningStyles,
} from "../../../../assets/styles/toast";
import { postData } from "../../../../services";
import { setLoading } from "../../../../store/slices/loaderSlice";
import TextArea from "../../../atoms/TextArea";
import Modal from "../../../molecules/Modal/Normal";
import style from "../forms.module.scss";

const RequestInfoForm = ({
  product_id,
  product_name,
  disabled = false,
}: any) => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["cuid", "t"]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isActive, setIsActive] = useState(false);
  const modalId = uuidv4(); // Generate a unique ID for each modal

  useEffect(() => {
    setName(product_name);
  }, [product_name]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleOpenModal = () => {
    setIsActive(true);
  };

  const handleCloseModal = () => {
    setIsActive(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));

    try {
      const res = await postData({
        endpoint: "RequestAccess/requestProductAccess",
        data: {
          product_id: product_id,
          comment: description,
          user_id: cookies.cuid,
        },
        token: cookies.t,
      });

      if (res?.data?.status) {
        dispatch(setLoading(false));
        toast.success("Request Sent Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
      } else {
        dispatch(setLoading(false));
        toast.error("Already Requested", {
          duration: 1000,
          style: errorStyles,
        });
      }

      setIsActive(false);
    } catch (error) {
      dispatch(setLoading(false));
      toast("Please refresh and try again", {
        duration: 1000,
        style: warningStyles,
      });
    }
  };

  return (
    <>
      <div className={style.inline_display}>
        <button
          className="btn btn-primary btn-sm"
          onClick={handleOpenModal}
          disabled={disabled}
        >
          {disabled ? "Already Requested" : "Request Access"}
        </button>
      </div>
      <Modal
        id={modalId}
        title="Request Access"
        onClose={handleCloseModal}
        width="430px"
        isActive={isActive}
      >
        <div>
          <form onSubmit={handleSubmit}>
            <div>
              <div className="form-group mt-2">
                <label htmlFor="Product Name">Product Name</label>
                {/* <SelectDropdown
                placeholder="Select Category"
                data={requestInfoData}
                onChange={() => {}}
              /> */}
                <input
                  id="name"
                  placeholder="Enter Product Name"
                  name="name"
                  value={name}
                />
              </div>
            </div>
            <div className="mt-3 mb-4">
              <TextArea
                id="description"
                name="description"
                placeholder="Enter Comment"
                value={description}
                onChange={handleDescriptionChange}
                label="Comment"
              ></TextArea>
            </div>
            <button
              type="submit"
              className="btn btn-primary w-100 mb-3"
              disabled={!name || !product_id}
            >
              {" "}
              Submit
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default RequestInfoForm;
