import moment from "moment";
import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import { patchData } from "../../../../services";
import { setLoading } from "../../../../store/slices/loaderSlice";
import TypeDiv from "../../../atoms/TypeDiv";
import "./styles.scss";

const LabelValueDisplay = ({ label, children, style }) => {
  return (
    <div className="view-info-container" style={style}>
      <div className="label">{label}</div>
      <div className="data">
        <div className="colon">:</div>
        {children}
      </div>
    </div>
  );
};

const ViewRequest = ({ data, onClose, type, onUpdate }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(data?.id);

  const handleUpdate = async () => {
    if (status !== data?.status) {
      dispatch(setLoading(true));
      try {
        const res = await patchData({
          endpoint: "RequestAccess/requestApproveOrReject",
          params: {
            request_id: data?.id,
            status: status,
          },
        });

        if (res) {
          toast.success("Status Updated Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          dispatch(setLoading(false));
          onUpdate();
          onClose();
        }
      } catch (error) {
        console.log("error", error);
        toast.error("An Error Occured", { duration: 1000, style: errorStyles });
        dispatch(setLoading(false));
      }
    }
  };
  return (
    <div className="request-info-container">
      <LabelValueDisplay label="Name">{data?.user_name}</LabelValueDisplay>
      <LabelValueDisplay label="Company Name">
        {data?.user_company}
      </LabelValueDisplay>
      <LabelValueDisplay label="Email">{data?.user_email}</LabelValueDisplay>

      <LabelValueDisplay label="Requested For">
        {data?.product_name}
      </LabelValueDisplay>
      <LabelValueDisplay label="Requested Date">
        {moment(data?.date).format("MMM D, YYYY hh:mm a")}
      </LabelValueDisplay>
      <LabelValueDisplay label="Comment">{data?.comment}</LabelValueDisplay>
      <LabelValueDisplay label="Status" style={{ marginBottom: "5px" }}>
        <TypeDiv
          data={data.status}
          color={
            data.status === "Pending"
              ? "#FFD88D"
              : data.status === "Approved"
              ? "#B5E4CA"
              : "#FFB7B7"
          }
        />
      </LabelValueDisplay>

      {/* <div className="d-flex justify-content-end">
        <button
          className="btn btn-primary w-30"
          type="submit"
          onClick={() => handleUpdate()}
        >
          Update
        </button>
      </div> */}
    </div>
  );
};

const dropdownData = [
  { value: "Approved", label: "Approved" },
  { value: "Denied", label: "Denied" },
];

const approvedDropdownData = [{ value: "Revoked", label: "Revoked" }];

export default ViewRequest;
