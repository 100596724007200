import { useEffect, useState } from "react";
import ArrowDown from "../../../../Icons/ArrowDown";
import HeadingDropDown from "../../../../atoms/HeadingDropDown";
import { DateSelector } from "../../../../molecules/MonthYearSelector";
import style from "../NewInvestorDatabase/index.module.scss";

const CreatedInvestorFilter = ({
  filterOpenCreated,
  setFilterOpenCreated,
  handleFilter,
  id,
  dropdownPosition,
  type,
  reset,
  headerText = "Created Date",
}) => {
  const [afterDateForValidation, setAfterDateForValidation] = useState();
  const [createdStartDate, setCreatedStartDate] = useState(null);
  const [createdEndDate, setCreatedEndDate] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleReset = () => {
    setCreatedStartDate(null);
    setAfterDateForValidation(null);
    setCreatedEndDate(null);
    handleFilter(null);
    setFilterOpenCreated(!filterOpenCreated);
    setIsConfirmed(false);
  };

  useEffect(() => {
    if (reset) {
      handleReset();
      setFilterOpenCreated(false);
    }
  }, [reset]);

  const handleConfirm = () => {
    if (createdStartDate || createdEndDate) {
      setIsConfirmed(true);
    }
    const data = {
      created_start_date: createdStartDate,
      created_end_date: createdEndDate,
    };
    handleFilter(data);
    setFilterOpenCreated(!filterOpenCreated);
  };

  return (
    <div className={style.multi_select_filter_wrapper}>
      <HeadingDropDown
        title={headerText}
        icon={<ArrowDown />}
        IsOpen={filterOpenCreated}
        // // type="right"
        setIsOpen={setFilterOpenCreated}
        active={isConfirmed}
        id={id}
        dropdownPosition={dropdownPosition}
        type={type}
      >
        <div>
          <h2 className={style.InvestorFormHeader}>Created Date</h2>
          <form className={style.formConatiner}>
            <div className={style.formInput}>
              <label htmlFor="country">Created After Date</label>
              <DateSelector
                placeholder="Created After Date"
                onChange={(date) => {
                  setAfterDateForValidation(date);
                  setCreatedStartDate(date);
                }}
                selectedDate={createdStartDate}
                disableFuture={true}
              />
            </div>

            <div className={style.formInput}>
              <label htmlFor="">Created Before Date</label>
              <DateSelector
                placeholder="Created Before Date"
                onChange={(date) => {
                  setCreatedEndDate(date);
                }}
                minDateData={afterDateForValidation}
                selectedDate={createdEndDate}
                disableFuture={true}
              />
            </div>
          </form>
          <div className={style.bottomContainer}>
            <span
              className="medium cursor-pointer btn-reset btn-outline-dark"
              onClick={() => handleReset()}
            >
              Reset
            </span>
            <button
              className={`btn btn-primary ${style.customBtn}`}
              onClick={() => handleConfirm()}
            >
              Confirm
            </button>
          </div>
        </div>
      </HeadingDropDown>
    </div>
  );
};
export default CreatedInvestorFilter;
