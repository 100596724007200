import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useState } from "react";

const GoogleMapCustom = (props) => {
  const [markerWindow, setMarkerWindow] = useState(false);

  const { InvestorList, setmarkerlatlan, markerlatlan } = props;

  const GMapSecretKey = "AIzaSyC6dai5b5apuGpf9pPzrICRCt4o039iYhI";

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GMapSecretKey,
  });

  const investorDataExtract = InvestorList?.map((item) => {
    return {
      address: item?.address,
      company_id: item?.company_id,
    };
  });

  const tempData = investorDataExtract?.map((item) =>
    item?.address?.map((tempItem) => {
      return {
        company_id: item?.company_id,
        address: tempItem?.address,
        city: tempItem?.city,
        state_region: tempItem?.state_region,
        country: tempItem?.country,
        lat: Number(tempItem?.latitude),
        lng: Number(tempItem?.longitude),
      };
    })
  );

  const handleMarkerpopUp = (data) => {
    setmarkerlatlan(data);
    setMarkerWindow(true);
  };

  const style = {
    margin: "auto",
    height: "100vh",
    overflow: "hidden",
  };

  return (
    <>
      {isLoaded ? (
        <div className={style.gmapStyleWrap}>
          <GoogleMap
            mapContainerStyle={style}
            center={
              tempData?.[0]?.[0] ? tempData?.[0]?.[0] : tempData?.[1]?.[0]
            }
            zoom={10}
            options={{
              styles: [
                {
                  featureType: "all",
                  elementType: "all",
                  stylers: [
                    { saturation: -30 }, // Ensure map is grayscale
                  ],
                },
              ],
            }}
            onLoad={(map) => {
              // Apply grayscale filter to map container
              const mapContainer = map?.getDiv();
              mapContainer.style.filter = "grayscale(10%)";
            }}
          >
            {tempData?.map((data) =>
              data?.map((item) => {
                return (
                  <Marker
                    position={item}
                    draggable={true}
                    onClick={() => {
                      handleMarkerpopUp(item);
                    }}
                    title={`${item?.address}`}
                  />
                );
              })
            )}
            {markerWindow && (
              <InfoWindow
                onCloseClick={() => {
                  setMarkerWindow(false);
                }}
                position={{
                  lat: Number(markerlatlan?.lat),
                  lng: Number(markerlatlan?.lng),
                }}
              >
                <>
                  <p className="p-dark mb-0">
                    {markerlatlan?.address
                      ? markerlatlan?.address
                      : markerlatlan?.city}
                  </p>
                </>
              </InfoWindow>
            )}
          </GoogleMap>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default GoogleMapCustom;
