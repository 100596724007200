import { useDispatch } from "react-redux";
import { getData } from "../../../../services";

import { useEffect, useState } from "react";
import { setLoading } from "../../../../store/slices/loaderSlice";

const Helper = () => {
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState();
  const [companyList, setCompanyList] = useState([
    { value: null, label: null },
  ]);
  const getFilters = async () => {
    try {
      dispatch(setLoading(true));
      const res = await getData({
        endpoint: "Blog/getMappedCategory",
        params: { type: "Resource" },
      });
      dispatch(setLoading(false));
      setFilterData(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFilters();
    getCompanies();
  }, []);

  const getCompanies = async () => {
    try {
      dispatch(setLoading(true));
      const res = await getData({
        endpoint: "Company/getCompanyList",
        params: {},
      });

      res.unshift({ value: "All Company", label: "All Company" });

      const modifiedRes = res.map(({ value, label }: any) => ({
        value,
        label,
      }));
      dispatch(setLoading(false));
      setCompanyList(modifiedRes);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    filterData,
    companyList,
  };
};

export default Helper;
